import {
  ITreatyDetails,
  ITreatyLayer,
  ITreatyLayerAndCategoryResponse,
  ITreatyLayerCategory,
} from '.';
import { ITreatyDetailsResponse } from '../..';
import { SEND_TO_BACKEND_DATE_FORMAT } from '../../../../../../constants';
import {
  formatDateTime,
  formatEnumToBackend,
} from '../../../../../../utils/formatting-utils';
import { initialTreatyValues } from './content';

export const mapToWidgetValues = (
  treatyData: ITreatyDetailsResponse,
  layersData: ITreatyLayerAndCategoryResponse[]
): ITreatyDetails => {
  let layers: Record<string, ITreatyLayer> = {};

  if (layersData && layersData.length > 0) {
    // Group layers data by treaty layer id
    const groupedLayers = layersData.reduce(
      (acc, item) => {
        const id = item.reinsurance_TreatyLayer_Id;
        if (!acc[id]) {
          acc[id] = [];
        }
        acc[id].push(item);
        return acc;
      },
      {} as { [key: string]: ITreatyLayerAndCategoryResponse[] }
    );

    // Sort layers by treaty layer order
    const layersArray = Object.values(groupedLayers)
      .map((layerData) => ({
        layerOrder: layerData[0].reinsurance_TreatyLayer_TreatyLayerOrder,
        layerData,
      }))
      .sort((a, b) => a.layerOrder - b.layerOrder);

    layers = layersArray.reduce(
      (acc, layer, index) => {
        const firstItem = layer.layerData[0];

        // Map categories and sort them by category name
        const sortedCategories: ITreatyLayerCategory[] = layer.layerData
          .slice()
          .sort((a, b) =>
            a.treatyLayerCategory_Category.localeCompare(
              b.treatyLayerCategory_Category
            )
          )
          .map((category) => ({
            category: category.treatyLayerCategory_Category,
            aggregateLimit:
              category.treatyLayerCategory_AggregateLimit?.toString(),
            commissionRate:
              category.treatyLayerCategory_CommissionRate?.toString(),
            liabilityLimit:
              category.treatyLayerCategory_LiabilityLimit?.toString(),
            maximumLayerLimit:
              category.treatyLayerCategory_MaximumLayerLimit?.toString(),
            minimumLayerLimit:
              category.treatyLayerCategory_MinimumLayerLimit?.toString(),
            priorityLimit:
              category.treatyLayerCategory_PriorityLimit?.toString(),
            retentionLimit:
              category.treatyLayerCategory_RetentionLimit?.toString(),
          }));

        // Map layers
        acc[`tab_${index}`] = {
          treatySubType: firstItem.reinsurance_TreatyLayer_TreatySubType.Code,
          paymentAmount:
            firstItem.reinsurance_TreatyLayer_PaymentAmount?.toString(),
          paymentDate: firstItem.reinsurance_TreatyLayer_PaymentDate,
          premiumRate: firstItem.treatyLayerCategory_PremiumRate?.toString(),
          quotaShare:
            firstItem.reinsurance_TreatyLayer_QuotaSharePercentage?.toString(),
          totalExpectedPremium:
            firstItem.treatyLayerCategory_TotalExpectedPremium?.toString(),
          totalTreatyPremium:
            firstItem.reinsurance_TreatyLayer_TotalTreatyPremium?.toString(),
          categories: sortedCategories,
        };
        return acc;
      },
      {} as { [key: string]: any }
    );
  } else {
    layers = initialTreatyValues.values.layers;
    layers.tab_0.treatySubType =
      treatyData.reinsurance_Treaties_TreatyType.toLowerCase() ===
      'proportional'
        ? 'QuotaShare'
        : 'ExcessOfLoss';
  }

  return {
    treatyName: treatyData.reinsurance_Treaties_TreatyName,
    treatyType: treatyData.reinsurance_Treaties_TreatyType,
    effectiveDate: treatyData.reinsurance_Treaties_EffectiveDate,
    expiryDate: treatyData.reinsurance_Treaties_ExpiryDate,
    currency: treatyData.reinsurance_Treaties_TreatyCurrency.Code,
    underwritingLimit:
      treatyData.reinsurance_Treaties_TreatyUnderwritingLimit.toString(),
    claimAdviseLimit:
      treatyData.reinsurance_Treaties_ClaimAdviseLimit.toString(),
    substandardLimit:
      treatyData.reinsurance_Treaties_SubstandardLimit.toString(),
    layers,
  };
};

export const getMutationVariables = (
  currentTreatyId: string,
  values: ITreatyDetails
) => {
  const result: Record<string, any> = {
    CurrentTreatyID: currentTreatyId,
    TreatyName: values.treatyName,
    TreatyType: formatEnumToBackend(values.treatyType),
    Currency: values.currency,
    UnderwritingLimit: Number(values.underwritingLimit),
    ClaimLimit: Number(values.claimAdviseLimit),
    SubstandardLimit: Number(values.substandardLimit),
    EffecitiveDate: formatDateTime(
      values.effectiveDate,
      SEND_TO_BACKEND_DATE_FORMAT
    ),
    ExpiryDate: formatDateTime(values.expiryDate, SEND_TO_BACKEND_DATE_FORMAT),
    LayerDetails: [],
  };

  Object.keys(values.layers).forEach((key: string, layerIndex: number) => {
    const layer = values.layers[key];

    layer.categories.forEach((category) => {
      result.LayerDetails.push({
        LayerName: key,
        LayerOrder: layerIndex,
        LayerType: layer.treatySubType,
        LayerPaymentAmount: Number(layer.paymentAmount),
        LayerPaymentDate: layer.paymentDate
          ? formatDateTime(layer.paymentDate, SEND_TO_BACKEND_DATE_FORMAT)
          : null,
        LayerQuotaSharePercentage: Number(layer.quotaShare),
        LayerTotalTreatyPremium: Number(layer.totalTreatyPremium),
        LayerCategoryTotalExpectedPremium: Number(layer.totalExpectedPremium),
        LayerCategoryPremiumRate: Number(layer.premiumRate),
        LayerCategory: formatEnumToBackend(category.category),
        LayerCategoryMinimumLayerLimit: Number(category.minimumLayerLimit),
        LayerCategoryMaximumLayerLimit: Number(category.maximumLayerLimit),
        LayerCategoryCommissionRate: Number(category.commissionRate),
        LayerCategoryRetentionLimit: Number(category.retentionLimit),
        LayerCategoryPriorityLimit: Number(category.priorityLimit),
        LayerCategoryLiabilityLimit: Number(category.liabilityLimit),
        LayerCategoryAggregateLimit: Number(category.aggregateLimit),
      });
    });
  });

  return result;
};
