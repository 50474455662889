import {
  EnhancedTableHeader,
  EnhancedTableHeaderType,
  EnhancedHeaderInputTypes,
} from '../../../../../../components/enhanced-table';

export const RVPageHeaders: Record<string, EnhancedTableHeader> = {
  paymentDate: {
    name: 'paymentDate',
    title: 'Payment Date',
    type: EnhancedTableHeaderType.Clickable,
    callback: null,
  },
  paymentMethod: {
    name: 'paymentMethod',
    title: 'Payment Method',
    type: EnhancedTableHeaderType.Text,
  },
  referenceNumber: {
    name: 'referenceNumber',
    title: 'Reference Number',
    type: EnhancedTableHeaderType.Text,
  },
  amountReceived: {
    name: 'amountReceived',
    title: 'Amount Received',
    type: EnhancedTableHeaderType.Currency,
  },
  createdBy: {
    name: 'createdBy',
    title: 'Created By',
    type: EnhancedTableHeaderType.Text,
  },
  createdOn: {
    name: 'createdOn',
    title: 'Created On',
    type: EnhancedTableHeaderType.Date,
  },
};

export const RVPopupHeaders: (
  currency: string,
  currencyList: Record<string, string>,
  errors: Record<string, string>,
  disabled: boolean
) => Record<string, EnhancedTableHeader> = (
  currency,
  currencyList,
  errors,
  disabled
) => {
  const currencySymbol = currencyList[currency];

  return {
    policyNum: {
      name: 'policyNum',
      title: 'Policy #',
      type: EnhancedTableHeaderType.Text,
    },
    billNum: {
      name: 'billNum',
      title: 'Bill #',
      type: EnhancedTableHeaderType.Text,
    },
    dueDate: {
      name: 'dueDate',
      title: 'Due Date',
      type: EnhancedTableHeaderType.Date,
    },
    amountDue: {
      name: 'amountDue',
      title: 'Amount Due',
      type: EnhancedTableHeaderType.RoundedCurrency,
    },
    amountOutstanding: {
      name: 'amountOutstanding',
      title: 'Amount Outstanding',
      type: EnhancedTableHeaderType.RoundedCurrency,
    },
    amountOutstandingCurrency: {
      name: 'amountOutstandingCurrency',
      title: currencySymbol
        ? `Amount Outstanding - ${currencySymbol}`
        : 'Amount Outstanding',
      type: EnhancedTableHeaderType.RoundedCurrency,
      currency: currencySymbol,
    },
    amount: {
      name: 'amount',
      title: 'Amount Received',
      type: EnhancedTableHeaderType.Input,
      inputType: EnhancedHeaderInputTypes.Currency,
      currency: currencySymbol,
      error: errors.tableAmountReceived,
      disabled: disabled,
    },
  };
};
