import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { MAIN_ONE_THEME } from '../../../constants';
import { ListingDataItemType } from '../../../models/listing';
import { ActionTarget, IListingTableFilter } from '.';
import { IAbstractRecord } from '../../../models';
import { IEnhancedMenuItem } from '.';
import { cloneDeep } from 'lodash';
import ListingToolbar from './ListingToolbar';
import EnhancedTableFilter from './ListingTableFilter';
import EnhancedButton from '../buttons/EnhancedButton';

const useStyles = makeStyles()(() => ({
  actions: {
    gridGap: '1em',
    display: 'grid',
    gridAutoFlow: 'column',
  },
  action: {
    '& span': {
      margin: '0.5em auto 0',
    },
  },
  separator: {
    width: '1px',
    height: '25px',
    opacity: 0.5,
    backgroundColor: MAIN_ONE_THEME.palette.secondary3.main,
    marginLeft: '5px',
    marginRight: '5px',
    display: 'inline-block',
    verticalAlign: 'text-bottom',
    alignSelf: 'center',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
  },
  secondaryActions: {
    boxSizing: 'border-box',
    display: 'inline-flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'flex-end',
    color: MAIN_ONE_THEME.palette.primary1.main,
    margin: '0 0 0 8px',
  },
  dropdown: {
    width: 'unset',
  },
  dropdownField: {
    lineHeight: 1.5,
    textAlign: 'left',
    color: MAIN_ONE_THEME.palette.primary1.main,
    display: 'flex',
  },
}));

export interface ListingTableToolbarProps {
  data: ListingDataItemType;
  selected: string[];
  actions?: IEnhancedMenuItem[];
  handleChange: (value: string) => void;
  cdnUrl: string;
  title?: string;
  inline?: boolean;
  inlineFilters?: Record<string, IListingTableFilter>;
  dropdownActionsNames: string[];
  context?: IAbstractRecord;
  onFilterClick?(filter: string, field: string, value: boolean): void;
  onFilterClear?(): void;
  forceClearSearchValue?: boolean;
}

const ListingTableToolbar: React.FC<ListingTableToolbarProps> = ({
  actions,
  data,
  selected,
  handleChange,
  cdnUrl,
  title,
  dropdownActionsNames,
  inlineFilters,
  onFilterClear = () => {},
  onFilterClick = () => {},
  forceClearSearchValue = false,
}) => {
  const { classes } = useStyles();
  // const [openDropdown, setOpenDropdown] = useState<boolean>(false);
  const [standAloneActions, setStandAloneActions] = useState<
    IEnhancedMenuItem[]
  >([]);
  const [dropdownActions, setDropdownActions] = useState<IEnhancedMenuItem[]>(
    []
  );

  const isValidAction = useCallback(
    (action: IEnhancedMenuItem) => {
      if (!action.displayActionPerRecord) {
        return true;
      }
      if (!data || !selected || selected.length === 0) {
        return false;
      }
      return selected.every(
        (key) =>
          data[key] &&
          data[key].AvailableActions &&
          data[key].AvailableActions.includes(action.name)
      );
    },
    [data, selected]
  );

  const addSelectedToAction = useCallback(
    (action: IEnhancedMenuItem): IEnhancedMenuItem => {
      // if (action.formType === ActionWidgetType.Confirmation) {
      //   return { ...action, numberOfItems: selected.length };
      // }
      return action;
    },
    []
  );

  useEffect(() => {
    if (actions) {
      let curStandAloneAct: IEnhancedMenuItem[] = [];
      const newDropdownActions: IEnhancedMenuItem[] = [];
      if (dropdownActionsNames && dropdownActionsNames.length > 0) {
        actions.forEach((act) => {
          if (!dropdownActionsNames.includes(act.name)) {
            curStandAloneAct.push(act);
          } else {
            const newAction = cloneDeep(act);
            // const isEntityAction = act.target === ActionTarget.Entity;
            newAction.disabled =
              // isEntityAction &&
              selected.length === 0 ||
              // !act.supportBulk &&
              selected.length > 1 ||
              !isValidAction(act);
            // newAction.context = isEntityAction ? buildContext() : undefined;
            newDropdownActions.push(
              // isEntityAction ?
              addSelectedToAction(newAction)
              // : newAction
            );
          }
        });
      } else {
        curStandAloneAct = actions;
      }
      setStandAloneActions(curStandAloneAct);
      setDropdownActions(newDropdownActions);
    } else {
      setStandAloneActions([]);
      setDropdownActions([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderActions = () => {
    const output: JSX.Element[] = [];
    if (standAloneActions && standAloneActions.length > 0) {
      // const outPutContext = buildContext();
      standAloneActions.forEach((action, index) => {
        if (index !== 0) {
          output.push(
            <span
              key={`${action.name}-separator`}
              className={classes.separator}
            />
          );
        }
        const isEntityAction = action.target === ActionTarget.Entity;
        output.push(
          <EnhancedButton
            key={action.name}
            disabled={
              (isEntityAction && selected.length === 0) ||
              (!action.supportBulk && selected.length > 1) ||
              !isValidAction(action)
            }
            isPrimary={action.isPrimary}
            onClick={() => action.onClick(selected)}
          >
            {action.title}
          </EnhancedButton>
        );
      });
    }
    return output;
  };

  const renderDropdownActions = () => {
    if (dropdownActions && dropdownActions.length > 0) {
      return (
        <div className={classes.secondaryActions}>
          {/* <ActionMenu
            buttonElem={<ArrowButton open={openDropdown} />}
            onOpenChange={(visible) => setOpenDropdown(visible)}
            onMenuItemClick={() => setOpenDropdown(false)}
            open={openDropdown}
            actions={dropdownActions}
            menuMinWidth={91}
            classes={{
              menu: classes.dropdown,
              menuItem: classes.dropdownField,
            }}
          /> */}
        </div>
      );
    }
    return <></>;
  };

  return (
    <ListingToolbar
      onChange={handleChange}
      cdnUrl={cdnUrl}
      title={title}
      classes={{ rightSection: classes.toolbar }}
      filterElem={
        inlineFilters && Object.keys(inlineFilters).length > 0 ? (
          <EnhancedTableFilter
            onFilterClick={onFilterClick}
            filters={inlineFilters}
            onClear={onFilterClear}
          />
        ) : undefined
      }
      forceClearSearchValue={forceClearSearchValue}
    >
      <div className={classes.actions}>{renderActions()}</div>
      {renderDropdownActions()}
    </ListingToolbar>
  );
};

export default ListingTableToolbar;
