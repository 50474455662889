import React, { useEffect, useMemo, useState } from 'react';
import GenericDrawer from '../../../../../../components/common/generic-drawer/GenericDrawer';
import RepeaterBoxFormField from '../../../../../../components/form-fields/RepeaterBoxFormField';
import { IReinsurerValues, IReinsurersListResponse } from '.';
import { getInputs } from './content';
import ToastErrorMessage from '../../../../../../components/ToastErrorMessage';
import { toast } from 'react-toastify';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  getTreatyReinsurersQuery,
  updateTreatyReinsurersMutation,
} from './queries';
import { useParams } from 'react-router-dom';
import { getError } from '../../../../../../utils/graph-utils';
import EnhancedButton from '../../../../../../components/form-fields/buttons/EnhancedButton';
import { makeStyles } from 'tss-react/mui';
import { IAbstractRecord } from '../../../../../../models';
import ToastSuccessMessage from '../../../../../../components/ToastSuccessMessage';

interface IUpdateReinsurersDrawer {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

const useStyle = makeStyles()(() => ({
  submitContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const UpdateReinsurersDrawer: React.FC<IUpdateReinsurersDrawer> = ({
  isOpen,
  onClose,
  onSuccess,
}) => {
  const { classes } = useStyle();
  const { id: currentTreatyId } = useParams<{ id: string }>();
  const [reinsurersList, setReinsurersList] = useState({});
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [values, setValues] = useState<IReinsurerValues[]>([
    {
      reinsurer: '',
      share: 0,
    },
  ]);
  const [errors, setErrors] = useState<IAbstractRecord[]>([]);
  const [touched, setTouched] = useState<Record<string, boolean>[]>([]);

  const [getTreatyReinsurers] = useLazyQuery(getTreatyReinsurersQuery);
  const [updateTreatyReinsurers] = useMutation(updateTreatyReinsurersMutation);

  const [totalShare, reinsurersIds] = useMemo(() => {
    const total = values.reduce(
      (acc, item) => acc + parseFloat(item.share?.toString() || '0'),
      0
    );
    const ids = values.map((item) => item.reinsurer);
    return [total, ids];
  }, [values]);

  const getReinsurers = async (search?: string) => {
    try {
      const { data } = await getTreatyReinsurers({
        variables: {
          TreatyID: currentTreatyId,
          SelectedCompany: '41',
          KeywordSearch: search,
          pagination: {
            pageNumber: 1,
            pageSize: 5,
          },
        },
      });

      const reinsurers: Record<string, string> = {};
      data?.Reinsurance?.queries?.ReinsurersList?.items.forEach(
        (item: Record<string, string>) => {
          reinsurers[item.salesforceManagement_BusinessPartner_Id] =
            item.salesforceManagement_BusinessPartner_FullName;
        }
      );

      const newValues: IReinsurerValues[] = [];
      const newErrors: IAbstractRecord[] = [];
      const newTouched: Record<string, boolean>[] = [];
      data?.Reinsurance?.queries?.GetTreatyReinsurers?.forEach(
        (item: IReinsurersListResponse) => {
          newValues.push({
            isLeader: item.reinsurance_TreatyReinsurers_IsLeader,
            reinsurer: item.treatyReinsurersBusinessPartner_Id,
            share: item.reinsurance_TreatyReinsurers_SharePercentage,
          });
          newErrors.push({ reinsurer: '', share: '' });
          newTouched.push({ reinsurer: true, share: true });
        }
      );

      // sort to sure the leader is always the first item
      newValues.sort((a) => (a.isLeader ? -1 : 1));

      setValues(newValues);
      setErrors(newErrors);
      setTouched(newTouched);
      setReinsurersList(reinsurers);
    } catch (error) {
      toast.error(<ToastErrorMessage>{getError(error)}</ToastErrorMessage>);
    }
  };

  useEffect(() => {
    getReinsurers();
  }, []);

  const handleSearch = async (value: string): Promise<void> => {
    await getReinsurers(value);
  };

  const validateField = (
    fieldName: keyof IReinsurerValues,
    index: number
  ): string => {
    const value = values[index][fieldName];
    let error = '';

    if (!value) {
      error = 'Required';
    } else {
      if (fieldName === 'reinsurer') {
        const reinsurer = value;
        const isReinsurerExist =
          reinsurersIds.filter((item) => item === reinsurer).length > 1;
        if (isReinsurerExist) {
          error = 'Reinsurer already selected';
        }
      } else if (fieldName === 'share') {
        if (totalShare !== 100) {
          error = 'Total Share should be equal to 100%';
        }
      }
    }
    return error;
  };

  const validateForm = (): boolean => {
    const newErrors = values.map(() => ({ reinsurer: '', share: '' }));
    const newTouched = values.map(() => ({ reinsurer: true, share: true }));
    let isValid = true;

    values.forEach((item, index) => {
      // Validate reinsurer
      let error = '';
      if (!item.reinsurer) {
        error = 'Required';
        isValid = false;
      } else {
        const isReinsurerExist =
          reinsurersIds.filter((id) => id === item.reinsurer).length > 1;
        if (isReinsurerExist) {
          error = 'Reinsurer already selected';
          isValid = false;
        }
      }
      newErrors[index].reinsurer = error;

      // Validate share
      error = '';
      if (!item.share) {
        error = 'Required';
        isValid = false;
      } else if (totalShare !== 100) {
        error = 'Total Share should be equal to 100%';
        isValid = false;
      }
      newErrors[index].share = error;
    });

    setErrors(newErrors);
    setTouched(newTouched);

    return isValid;
  };

  const handleBlur = (fieldName: keyof IReinsurerValues, index: number) => {
    const error = validateField(fieldName, index);

    setErrors((prevErrors) => {
      const newErrors = [...prevErrors];
      newErrors[index] = { ...newErrors[index], [fieldName]: error };
      return newErrors;
    });

    setTouched((prevTouched) => {
      const newTouched = [...prevTouched];
      newTouched[index] = { ...newTouched[index], [fieldName]: true };
      return newTouched;
    });
  };

  const handleSubmit = async () => {
    try {
      const isValid = validateForm();

      if (!isValid) {
        return;
      }

      setIsDisabled(true);
      const res = await updateTreatyReinsurers({
        variables: {
          CurrentTreatyID: currentTreatyId,
          ReinsurersList: values.map((item) => ({
            IsLeader: item.isLeader || false,
            ReinsurerID: item.reinsurer,
            ReinsurerShare: Number(item.share),
            ReinsurerName: (reinsurersList as any)[item.reinsurer],
          })),
        },
      });

      if (!res.errors) {
        toast.success(
          <ToastSuccessMessage>
            Reinsurers updated successfully
          </ToastSuccessMessage>
        );
        onClose();
        onSuccess();
      }
    } catch (error) {
      toast.error(<ToastErrorMessage>{getError(error)}</ToastErrorMessage>);
    } finally {
      setIsDisabled(false);
    }
  };

  return (
    <GenericDrawer
      title={'Update Reinsurers'}
      onClose={() => onClose()}
      isOpen={isOpen}
    >
      <>
        <RepeaterBoxFormField
          name={'reinsurersRepeater'}
          title={''}
          values={values}
          error={errors}
          disabled={isDisabled}
          inputs={getInputs(reinsurersList)}
          addButtonText="+Add Reinsurer"
          maxRepeaterLength={999}
          minRepeaterLength={1}
          onChange={(v: IReinsurerValues[]) => setValues(v)}
          onBlur={(subFieldName: keyof IReinsurerValues, index: number) => {
            handleBlur(subFieldName, index);
          }}
          onSearch={async (v) => {
            await handleSearch(v);
          }}
          touched={touched}
          onAddBox={() => {
            setErrors((prevErrors) => [
              ...prevErrors,
              { reinsurer: '', share: '' },
            ]);
            setTouched((prevTouched) => [
              ...prevTouched,
              { reinsurer: false, share: false },
            ]);
          }}
          onDeleteBox={(index: number) => {
            setErrors((prevErrors) => prevErrors.filter((_, i) => i !== index));
            setTouched((prevTouched) =>
              prevTouched.filter((_, i) => i !== index)
            );
          }}
        />
        <div className={classes.submitContainer}>
          <EnhancedButton
            isPrimary
            onClick={handleSubmit}
            disabled={isDisabled}
          >
            Submit
          </EnhancedButton>
        </div>
      </>
    </GenericDrawer>
  );
};

export default UpdateReinsurersDrawer;
