import { gql } from '@apollo/client';

export function getProposalDetailsMainInfo() {
  return gql`
    query getProposalDetailsMainInfo($id: String!) {
      Production {
        entities {
          proposal(id: $id) {
            id
            views {
              Production_all {
                properties {
                  Id
                  Type
                  LineID {
                    id
                    views {
                      PlanConfigManagement_all {
                        properties {
                          Name
                          ExternalCode
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}
