import { FormInputTypes } from '../../DynamicForm';
import {
  EnhancedTableHeader,
  EnhancedTableHeaderType,
  IEnhancedTableMenuItem,
} from '../../components/enhanced-table';
import { IListingFilterWidgetSection } from '../../components/widgets/custom-listing-filter';
import { IAbstractRecord } from '../../models';
import { ITabsLayout, LayoutType } from '../../page-layout';
import { ITenant } from '../../redux/tenant/types';

export const businessPartnerTabs: ITabsLayout = {
  typeLogicalId: LayoutType.tabslayout,
  leftColumn: {
    backgroundColor: '#FFFFFF',
    collapsable: false,
    width: 2,
    collapsed: false,
  },
  mainColumn: {
    backgroundColor: '#F7F7F7',
    collapsable: false,
    width: 10,
    collapsed: false,
  },
  rightColumn: {
    backgroundColor: '#FFFFFF',
    collapsable: false,
    width: 0,
    collapsed: false,
  },
  tabs: [
    {
      name: 'summary',
      title: 'Summary',
      iconUrl: '/icons/file-summary-primary.svg',
      widgets: [{}],
    },
    {
      name: 'commissions&Limits',
      title: 'Commissions & Limits',
      iconUrl: '/icons/file-summary-primary.svg',
      widgets: [{}],
    },
    {
      name: 'vignetteRange',
      title: 'Vignette Range',
      iconUrl: '/icons/folder-primary.svg',
      widgets: [{}],
    },
    {
      name: 'paymentTerms',
      title: 'Payment Terms',
      iconUrl: '/icons/file-summary-primary.svg',
      widgets: [{}],
    },
  ],
};

export const getHeaders = (
  navigate: (path: string) => void
): Record<string, EnhancedTableHeader> => ({
  line_ExternalCode: {
    name: 'line_ExternalCode',
    title: 'Line Code ',
    type: EnhancedTableHeaderType.Text,
  },
  line_Name: {
    name: 'line_Name',
    title: 'Line',
    type: EnhancedTableHeaderType.Clickable,
    callback: (payload: IAbstractRecord) => {
      navigate(
        '/plan/lines/' +
          payload.columns.salesforceManagement_BusinessPartnerLine_LineId
      );
    },
  },
  line_Status: {
    name: 'line_Status',
    title: 'Line Status',
    type: EnhancedTableHeaderType.Text,
  },
  salesforceManagement_BusinessPartnerLine_LineCommissionType: {
    name: 'salesforceManagement_BusinessPartnerLine_LineCommissionType',
    title: 'Commission Type',
    type: EnhancedTableHeaderType.Text,
  },
  salesforceManagement_BusinessPartnerLine_ValueCurrency: {
    name: 'salesforceManagement_BusinessPartnerLine_ValueCurrency',
    title: 'Commission',
    type: EnhancedTableHeaderType.Text,
  },
  salesforceManagement_BusinessPartnerLine_ApplicableOn: {
    name: 'salesforceManagement_BusinessPartnerLine_ApplicableOn',
    title: 'Applicable On',
    type: EnhancedTableHeaderType.Text,
  },
  salesforceManagement_BusinessPartnerLine_CreditLimit: {
    name: 'salesforceManagement_BusinessPartnerLine_CreditLimit',
    title: 'Credit Limit',
    type: EnhancedTableHeaderType.Text,
  },
  salesforceManagement_BusinessPartnerLine_CreditLimitCurrency: {
    name: 'salesforceManagement_BusinessPartnerLine_CreditLimitCurrency',
    title: 'Credit Limit Currency',
    type: EnhancedTableHeaderType.Text,
  },
  salesforceManagement_BusinessPartnerLine_GracePeriod: {
    name: 'salesforceManagement_BusinessPartnerLine_GracePeriod',
    title: 'Grace Period (in days)',
    type: EnhancedTableHeaderType.Text,
  },
  salesforceManagement_BusinessPartnerLine_Status: {
    name: 'salesforceManagement_BusinessPartnerLine_Status',
    title: '',
    type: EnhancedTableHeaderType.Image,
    hasConditionalImage: true,
    variableOneImageKey:
      'https://ufa-cdn.azureedge.net/ufa-app-portal/images/green-correct-img.png',
    variableTwoImageKey:
      'https://ufa-cdn.azureedge.net/ufa-app-portal/images/red-clean-img.png',
  },
});

export const getAssignedPLansHeaders = (
  navigate: (path: string) => void
): Record<string, EnhancedTableHeader> => ({
  line_ExternalCode: {
    name: 'line_ExternalCode',
    title: 'Line Code ',
    type: EnhancedTableHeaderType.Text,
  },
  line_Name: {
    name: 'line_Name',
    title: 'Line',
    type: EnhancedTableHeaderType.Clickable,
    callback: (payload: IAbstractRecord) => {
      navigate(
        '/plan/lines/' +
          payload.columns.salesforceManagement_BusinessPartnerPlan_LineID
      );
    },
  },
  line_Status: {
    name: 'line_Status',
    title: 'Line Status',
    type: EnhancedTableHeaderType.Text,
  },

  plan_ExternalCode: {
    name: 'plan_ExternalCode',
    title: 'Plan Code ',
    type: EnhancedTableHeaderType.Text,
  },
  plan_Name: {
    name: 'plan_Name',
    title: 'Plan',
    type: EnhancedTableHeaderType.Clickable,
    callback: (payload: IAbstractRecord) => {
      navigate(
        '/plan/plans/' +
          payload.columns.salesforceManagement_BusinessPartnerPlan_PlanID
      );
    },
  },
  plan_Status: {
    name: 'plan_Status',
    title: 'Plan Status',
    type: EnhancedTableHeaderType.Text,
  },

  salesforceManagement_BusinessPartnerPlan_PlanCommissionType: {
    name: 'salesforceManagement_BusinessPartnerPlan_PlanCommissionType',
    title: 'Commission Type',
    type: EnhancedTableHeaderType.Text,
  },
  salesforceManagement_BusinessPartnerPlan_PlanCommissionValue: {
    name: 'salesforceManagement_BusinessPartnerPlan_PlanCommissionValue',
    title: 'Commission',
    type: EnhancedTableHeaderType.Text,
  },
  salesforceManagement_BusinessPartnerPlan_ApplicableOn: {
    name: 'salesforceManagement_BusinessPartnerPlan_ApplicableOn',
    title: 'Applicable On',
    type: EnhancedTableHeaderType.Text,
  },
  salesforceManagement_BusinessPartnerPlan_ActiveFrom: {
    name: 'salesforceManagement_BusinessPartnerPlan_ActiveFrom',
    title: 'Active From',
    type: EnhancedTableHeaderType.Date,
  },
  salesforceManagement_BusinessPartnerPlan_ActiveTo: {
    name: 'salesforceManagement_BusinessPartnerPlan_ActiveTo',
    title: 'Active To',
    type: EnhancedTableHeaderType.Date,
  },
  salesforceManagement_BusinessPartnerPlan_Status: {
    name: 'salesforceManagement_BusinessPartnerPlan_Status',
    title: '',
    type: EnhancedTableHeaderType.Image,
    hasConditionalImage: true,
    variableOneImageKey:
      'https://ufa-cdn.azureedge.net/ufa-app-portal/images/green-correct-img.png',
    variableTwoImageKey:
      'https://ufa-cdn.azureedge.net/ufa-app-portal/images/red-clean-img.png',
  },
});

export const vignetteRangeheaders: Record<string, EnhancedTableHeader> = {
  salesforceManagement_BusinessPartnerVignetteRange_RangeYear: {
    name: 'salesforceManagement_BusinessPartnerVignetteRange_RangeYear',
    title: 'Vignette Range Year',
    type: EnhancedTableHeaderType.Text,
  },
  salesforceManagement_BusinessPartnerVignetteRange_RangeCode: {
    name: 'salesforceManagement_BusinessPartnerVignetteRange_RangeCode',
    title: 'Vignette Range Code',
    type: EnhancedTableHeaderType.Text,
  },
  salesforceManagement_BusinessPartnerVignetteRange_RangeFrom: {
    name: 'salesforceManagement_BusinessPartnerVignetteRange_RangeFrom',
    title: 'Vignette Range Fom',
    type: EnhancedTableHeaderType.Text,
  },
  salesforceManagement_BusinessPartnerVignetteRange_RangeTo: {
    name: 'salesforceManagement_BusinessPartnerVignetteRange_RangeTo',
    title: 'Vignette Range To',
    type: EnhancedTableHeaderType.Text,
  },
  salesforceManagement_BusinessPartnerVignetteRange_RangeStatus: {
    name: 'salesforceManagement_BusinessPartnerVignetteRange_RangeStatus',
    title: 'Vignette Range Status',
    type: EnhancedTableHeaderType.Text,
  },
};

export const assignedLinesActions = (
  setDefaultCommissionDrawerOpen: (v: boolean) => void,
  setLineCreditLimitDrawerOpen: (v: boolean) => void,
  activateAssignedLine: (assignedIds: string[]) => void,
  deactivateAssignedLine: (assignedIds: string[]) => void,
  businessPartnerDetailsInfo: IAbstractRecord,
  isAdmin: boolean
): IEnhancedTableMenuItem[] => [
  {
    title: 'Set Default Commission',
    onClick: () => {
      setDefaultCommissionDrawerOpen(true);
    },
    isEntity: false,
    isBulk: false,
    iconUrl: '',
    hidden: !(
      isAdmin &&
      businessPartnerDetailsInfo?.businessPartnerType !== 'Direct_Salesforce'
    ),
    conditionalDisable: (selected, selectedItems) => {
      return !(
        selectedItems[0] &&
        selectedItems.length === 1 &&
        businessPartnerDetailsInfo?.businessPartnerType !==
          'Direct_Salesforce' &&
        businessPartnerDetailsInfo?.status?.toLowerCase() !== 'inactive' &&
        selectedItems[0]?.salesforceManagement_BusinessPartnerLine_Status?.toLowerCase() ===
          'active'
      );
    },
  },
  {
    title: 'Update Credit Limit',
    onClick: () => {
      setLineCreditLimitDrawerOpen(true);
    },
    isEntity: false,
    isBulk: false,
    iconUrl: '',
    hidden: !(
      isAdmin &&
      businessPartnerDetailsInfo?.status !== 'inactive' &&
      businessPartnerDetailsInfo?.businessPartnerType !== 'Direct_Salesforce'
    ),
    conditionalDisable: (selected, selectedItems) => {
      return !(
        selectedItems[0] &&
        selectedItems.length >= 1 &&
        businessPartnerDetailsInfo?.status?.toLowerCase() !== 'inactive'
      );
    },
  },
  {
    title: 'Activate',
    onClick: (selectedIds: string[]) => {
      activateAssignedLine(selectedIds);
    },
    isEntity: false,
    isBulk: false,
    iconUrl: '',
    hidden: !isAdmin,
    conditionalDisable: (selected, selectedItems) => {
      return !(
        selectedItems[0] &&
        selectedItems.length === 1 &&
        selectedItems[0]?.salesforceManagement_BusinessPartnerLine_Status?.toLowerCase() ===
          'inactive' &&
        selectedItems[0]?.line_Status?.toLowerCase() === 'active' &&
        businessPartnerDetailsInfo?.status !== 'inactive'
      );
    },
  },
  {
    title: 'Deactivate',
    onClick: (selectedIds: string[]) => {
      deactivateAssignedLine(selectedIds);
    },
    isEntity: false,
    isBulk: false,
    iconUrl: '',
    hidden: !isAdmin,
    conditionalDisable: (selected, selectedItems) => {
      return !(
        selectedItems[0] &&
        selectedItems.length === 1 &&
        selectedItems[0]?.salesforceManagement_BusinessPartnerLine_Status?.toLowerCase() ===
          'active' &&
        selectedItems[0]?.line_Status?.toLowerCase() === 'active' &&
        businessPartnerDetailsInfo?.status !== 'inactive'
      );
    },
  },
];

export const assignedPlansActions = (
  setAddPlansDrawerOpen: (v: boolean) => void,
  setAssignedPlansCommissionDrawerOpen: (v: boolean) => void,
  activateAssignedPlan: () => void,
  deactivateAssignedPlan: () => void,
  businessPartnerDetailsInfo: IAbstractRecord,
  isAdmin: boolean
): IEnhancedTableMenuItem[] => [
  {
    title: 'Add Plan(s)',
    onClick: () => {
      setAddPlansDrawerOpen(true);
    },
    isEntity: false,
    isBulk: false,
    iconUrl: '',
    hidden: !isAdmin,
    disabled: false,
  },
  {
    title: 'Set Commission',
    onClick: () => {
      setAssignedPlansCommissionDrawerOpen(true);
    },
    isEntity: false,
    isBulk: false,
    iconUrl: '',
    hidden:
      !isAdmin ||
      businessPartnerDetailsInfo?.businessPartnerType === 'Direct_Salesforce',
    conditionalDisable: (selected, selectedItems) => {
      return !(
        selectedItems[0] &&
        selectedItems.length > 0 &&
        businessPartnerDetailsInfo?.businessPartnerType !==
          'Direct_Salesforce' &&
        selectedItems[0]?.salesforceManagement_BusinessPartnerPlan_Status?.toLowerCase() ===
          'active' &&
        selectedItems[0]?.salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID_Status?.toLowerCase() ===
          'active' &&
        selectedItems[0]?.line_Status?.toLowerCase() === 'active' &&
        selectedItems[0]?.plan_Status?.toLowerCase() === 'active' &&
        businessPartnerDetailsInfo?.status?.toLowerCase() !== 'inactive'
      );
    },
  },
  {
    title: 'Activate Plan(s)',
    onClick: () => {
      activateAssignedPlan();
    },
    isEntity: false,
    isBulk: false,
    iconUrl: '',
    hidden:
      !isAdmin ||
      businessPartnerDetailsInfo?.businessPartnerStatus === 'inactive',
    conditionalDisable: (selected, selectedItems) => {
      return !(
        selectedItems[0] &&
        selectedItems.length > 0 &&
        selectedItems[0]?.salesforceManagement_BusinessPartnerPlan_Status?.toLowerCase() ===
          'inactive' &&
        selectedItems[0]?.salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID_Status?.toLowerCase() ===
          'active' &&
        selectedItems[0]?.line_Status?.toLowerCase() === 'active' &&
        selectedItems[0]?.plan_Status?.toLowerCase() === 'active' &&
        businessPartnerDetailsInfo?.status?.toLowerCase() !== 'inactive'
      );
    },
  },
  {
    title: 'Deactivate',
    onClick: () => {
      deactivateAssignedPlan();
    },
    isEntity: false,
    isBulk: false,
    iconUrl: '',
    hidden:
      !isAdmin ||
      businessPartnerDetailsInfo?.businessPartnerStatus === 'inactive',
    conditionalDisable: (selected, selectedItems) => {
      return !(
        selectedItems[0] &&
        selectedItems.length > 0 &&
        selectedItems[0]?.salesforceManagement_BusinessPartnerPlan_Status?.toLowerCase() ===
          'active' &&
        selectedItems[0]?.salesforceManagement_BusinessPartnerPlan_BusinessPartnerLineID_Status?.toLowerCase() ===
          'active' &&
        selectedItems[0]?.line_Status?.toLowerCase() === 'active' &&
        selectedItems[0]?.plan_Status?.toLowerCase() === 'active' &&
        businessPartnerDetailsInfo?.status?.toLowerCase() !== 'inactive'
      );
    },
  },
];

export const vignetteRangeActions = (
  setActionClicked: (value: any) => void,
  setVignetteRangeDrawerOpen: (v: boolean) => void,
  businessPartnerDetailsInfo: IAbstractRecord,
  isAdmin: boolean,
  tenant: ITenant
): IEnhancedTableMenuItem[] => [
  {
    title: 'New',
    onClick: () => {
      setActionClicked('new');
      setVignetteRangeDrawerOpen(true);
    },
    isEntity: false,
    isBulk: false,
    iconUrl: tenant.cdnUrl + '/icons/add-primary-red.svg',
    hidden: !(isAdmin && businessPartnerDetailsInfo?.status !== 'inactive'),
    disabled: businessPartnerDetailsInfo?.status === 'inactive',
  },
  {
    title: 'Edit',
    onClick: () => {
      setActionClicked('edit');
      setVignetteRangeDrawerOpen(true);
    },
    isEntity: false,
    isBulk: false,
    iconUrl: tenant.cdnUrl + '/icons/edit-secondary.svg',
    hidden: !(isAdmin && businessPartnerDetailsInfo?.status !== 'inactive'),
    conditionalDisable: (selected, selectedItems) => {
      return selectedItems.length !== 1;
    },
  },
];

export function filterSectionsContent(
  lovs: Record<string, Record<string, string>>,
  filterValues: IAbstractRecord
): IListingFilterWidgetSection[] {
  const newFilterSections: IListingFilterWidgetSection[] = [
    {
      title: '',
      inputs: {
        line: {
          name: 'line',
          title: '',
          type: FormInputTypes.chips,
          value: filterValues?.line || [],
          placeholder: 'Filter By Line',
          triggerUpdate: true,
          selectOptions: { ...lovs.line },
        },
      },
    },
  ];
  return newFilterSections;
}

export const paymentTermsHeaders: Record<string, EnhancedTableHeader> = {
  policyNumber: {
    name: 'policyNumber',
    title: 'Policy #',
    type: EnhancedTableHeaderType.Text,
  },
  netPremium: {
    name: 'netPremium',
    title: 'Net Premium',
    type: EnhancedTableHeaderType.RoundedCurrency,
  },
  grossPremium: {
    name: 'grossPremium',
    title: 'Gross Premium',
    type: EnhancedTableHeaderType.RoundedCurrency,
  },
  totalPremium: {
    name: 'totalPremium',
    title: 'Total Premium',
    type: EnhancedTableHeaderType.RoundedCurrency,
  },
  amountReceived: {
    name: 'amountReceived',
    title: 'Amount Received',
    type: EnhancedTableHeaderType.RoundedCurrency,
  },
  commission: {
    name: 'commission',
    title: 'Commission',
    type: EnhancedTableHeaderType.RoundedCurrency,
  },
  taxOnCommission: {
    name: 'taxOnCommission',
    title: 'Tax on Commission',
    type: EnhancedTableHeaderType.RoundedCurrency,
  },
  amountPaid: {
    name: 'amountPaid',
    title: 'Amount Paid',
    type: EnhancedTableHeaderType.RoundedCurrency,
  },
  totalBalance: {
    name: 'totalBalance',
    title: 'Total Balance',
    type: EnhancedTableHeaderType.RoundedCurrency,
  },
};
