import React, { useEffect, useState } from 'react';
import { steps } from './content';
import { makeStyles } from 'tss-react/mui';
import { useLazyQuery, useMutation } from '@apollo/client';
import { activateProvider } from './queries';
import { useParams } from 'react-router-dom';
import Loader from '../../components/Loader';
import { toast } from 'react-toastify';
import ToastErrorMessage from '../../components/ToastErrorMessage';

import { IConfigurationProviderDetailsPageProps } from '.';

import {
  DEFAULT_ERROR_TEXT,
  MAIN_ONE_THEME,
  contentFontFamilyBold,
} from '../../constants';

import { useAppDispatch } from '../../redux/hooks';
import EnhancedButton, {
  EnhancedButtonStatus,
} from '../../components/EnhancedButton';
import ToastSuccessMessage from '../../components/ToastSuccessMessage';
import { getError } from '../../utils/graph-utils';
import { IConfirmation } from '../../redux/confirmation/types';
import { getProviderDetailsInfo } from './queries';
import { OpenConfirmationAction } from '../../redux/confirmation/actions';
import { entityToProviderDetails, getProviderStatus } from './utils';
import ShowForUser from '../../components/user/ShowForUser';
import { isEmpty } from '../../utils/validationUtils';
import ProviderDrawer from '../providers-drawer/ProviderDrawer';
import ProviderDeactivateDrawer from '../providers-deactivate-drawer/ProviderDeactivateDrawer';
import StaticLayout from '../../page-layout/static-layout/StaticLayout';
import EnhancedStepperSpecificDesign from '../../components/common/EnhancedStepperSpecificDesign';
import ProviderEntityInfoWidget from './widgets/ProviderEntityInfoWidget';
import ProviderDetailsWidget from './widgets/ProviderDetailsWidget';

const useStyles = makeStyles()(() => ({
  stepper: {
    backgroundColor: 'transparent',
    maxWidth: '90%',
    marginBottom: '20px',
    '& .MuiStepLabel-root .Mui-active, .MuiStepLabel-root ': {
      color: MAIN_ONE_THEME.palette.primary5.main,
      '& .MuiStepIcon-text': {
        fontSize: 0,
        lineHeight: '0px',
        fill: 'black',
      },
    },

    '.Mui-completed': {
      color: MAIN_ONE_THEME.palette.primary5.main,
      '& .MuiStepIcon-text': {
        fontSize: 0,
        lineHeight: '0px',
        fill: 'black',
      },
    },

    '@media only screen and (min-width: 600px)': {
      maxWidth: '1300px',
    },
  },
  section: {
    display: 'grid',
    'grid-template-columns': '19.5% 19.5% 19.5% 19.5% 19.5%',
    justifyContent: 'space-between',
    flexWrap: 'wrap',

    '& label': {
      '& span': {
        margin: '-2px 0',
      },
    },
  },
  sectionFullRow: {
    display: 'grid',
    'grid-template-columns': '100%',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  row: {
    flexWrap: 'wrap',
    margin: '15px 20px 10px 0px',
    alignItems: 'flex-start',
    display: 'grid',
    gap: '5px',
    '& label': {
      flexGrow: '1',
      flexBasis: '0',
      minWidth: '0',
    },
    'grid-template-columns': '19.5% 19.5% 19.5% 19% 16% 3% 3%',
  },
  thickSeperator: {
    height: 10,
    margin: '10px 0 10px 0',
    'grid-column-start': '1',
    'grid-column-end': '4',
  },
  field: {
    width: '96%',
    marginRight: '10px !important',
  },
  header: {
    fontFamily: contentFontFamilyBold,
    fontSize: MAIN_ONE_THEME.typography.regular.reg3.fontSize,
    color: MAIN_ONE_THEME.palette.primary2.main,
    '&:hover': {
      color: MAIN_ONE_THEME.palette.primary1.main,
    },
  },
  content: {
    padding: '0',
  },
  dirtyChip: {
    margin: '0 0 -15px 0',
    backgroundColor: MAIN_ONE_THEME.palette.primary2.main,
    '& span': {
      color: '#ffffff',
    },
  },
  actionButton: {
    display: 'inline-block',
    marginRight: '5px',
  },
}));

const ProviderDetailsPage: React.FC<
  IConfigurationProviderDetailsPageProps
> = () => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const params = useParams();
  const [activateButtonState, setActivateButtonState] =
    useState<EnhancedButtonStatus>();

  const entityId = params.id;

  const [booted, setBooted] = useState<boolean>(false);

  const [providerInfoDrawerOpen, setProviderInfoDrawerOpen] =
    useState<boolean>(false);

  const [deactivateDrawerOpen, setDeactivateDrawerOpen] =
    useState<boolean>(false);

  const [data, setData] = useState<Record<string, any>>();

  const [getProviderDetailsLazy] = useLazyQuery(getProviderDetailsInfo());

  const [activateAction] = useMutation(activateProvider(), {
    variables: {
      entityId: entityId,
    },
    errorPolicy: 'all',
  });

  const initialize = async () => {
    try {
      const result = await getProviderDetailsLazy({
        variables: { id: entityId },
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
      });

      const providerDetails = entityToProviderDetails(result?.data);

      setData(providerDetails);
      setBooted(true);
    } catch (error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  const renderMainChildren = () => {
    const isProviderActive = data?.status?.toLowerCase() === 'active';
    const status = data?.status?.toLowerCase();

    return (
      <>
        <div style={{ marginTop: '20px' }}>
          <EnhancedStepperSpecificDesign
            activeStep={getProviderStatus(status)}
            steps={steps}
            className={classes.stepper}
            showStepperButton
            buttonTitle={isProviderActive ? 'Deactivate' : 'Activate'}
            buttonState={isProviderActive ? activateButtonState : undefined}
            buttonOnClick={() => {
              if (isProviderActive) {
                setDeactivateDrawerOpen(true);
              } else {
                const confirmation: IConfirmation = {
                  open: true,
                  title: 'Activate',
                  message: `Are you sure you want to activate this provider?`,
                  callback: async () => {
                    setActivateButtonState('loading');
                    try {
                      const result = await activateAction();
                      if (isEmpty(result.errors)) {
                        setActivateButtonState('success');
                        toast.success(
                          <ToastSuccessMessage>
                            {'Provider successfully activated.'}
                          </ToastSuccessMessage>
                        );
                        initialize();
                      } else {
                        setActivateButtonState(undefined);
                        toast.error(
                          <ToastErrorMessage>
                            {getError(result)}
                          </ToastErrorMessage>
                        );
                      }
                    } catch (err) {
                      setActivateButtonState(undefined);
                      toast.error(
                        <ToastErrorMessage>{getError(err)}</ToastErrorMessage>
                      );
                    } finally {
                      setTimeout(() => {
                        setActivateButtonState(undefined);
                      }, 1000);
                    }
                  },
                  submitButtonText: 'Yes',
                  cancelButtonText: 'No',
                };
                dispatch(OpenConfirmationAction(confirmation));
              }
            }}
          />
          <ProviderDetailsWidget
            data={data}
            actions={
              <ShowForUser allowedRoles={['Insurance-Admin']}>
                {status != 'inactive' && (
                  <div>
                    <EnhancedButton
                      type="button"
                      backgroundColor={MAIN_ONE_THEME.palette.primary1.main}
                      color="#FFF"
                      onClick={() => setProviderInfoDrawerOpen(true)}
                      className={classes.actionButton}
                    >
                      Modify
                    </EnhancedButton>
                  </div>
                )}
              </ShowForUser>
            }
          />
        </div>
      </>
    );
  };

  const renderLeftSection = () => {
    return <ProviderEntityInfoWidget data={data} />;
  };

  return !booted ? (
    <Loader />
  ) : (
    <>
      <div>
        {providerInfoDrawerOpen && (
          <ProviderDrawer
            open={providerInfoDrawerOpen}
            onClose={() => setProviderInfoDrawerOpen(false)}
            onSuccess={() => {
              initialize();
            }}
            providerId={params.id}
            providerDetailsInfo={data as any}
          />
        )}
        {deactivateDrawerOpen && (
          <ProviderDeactivateDrawer
            open={deactivateDrawerOpen}
            onClose={() => setDeactivateDrawerOpen(false)}
            onSuccess={() => {
              initialize();
            }}
            providerId={params.id}
            effectiveDate={data.effectiveDate}
          />
        )}
      </div>
      <StaticLayout
        name={'Provider Details'}
        leftChildren={renderLeftSection()}
        mainChildren={renderMainChildren()}
      />
    </>
  );
};

export default ProviderDetailsPage;
