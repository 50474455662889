import { gql } from '@apollo/client';

export function listQuery() {
  return gql`
    query LIST_POLICYLISTS(
      $currentPage: Int!
      $currentPageSize: Int!
      $selectedLines: [String]
      $selectedSublines: [String]
      $selectedPlans: [String]
      $selectedBusinessPartners: [String]
      $SelectedStatuses: [Production_PolicyStatuses]
      $selectedStatusReasons: [String]
      $effectiveDateFrom: Date
      $effectiveDateTo: Date
      $FromCreatedOn: Date
      $ToCreatedOn: Date
      $issueDateFrom: Date
      $issueDateTo: Date
      $selectedInsuredIDs: [String]
      $type: Production_PolicyTypes!
    ) {
      Production {
        queries {
          getPolicyList(
            pagination: { pageNumber: $currentPage, pageSize: $currentPageSize }
            selectedStatuses: $SelectedStatuses
            selectedStatusReasons: $selectedStatusReasons
            selectedLines: $selectedLines
            selectedSublines: $selectedSublines
            selectedPlans: $selectedPlans
            selectedBusinessPartners: $selectedBusinessPartners
            FromCreatedOn: $FromCreatedOn
            ToCreatedOn: $ToCreatedOn
            FromPolicyEffectiveDate: $effectiveDateFrom
            ToPolicyEffectiveDate: $effectiveDateTo
            FromIssueOn: $issueDateFrom
            ToIssueOn: $issueDateTo
            selectedInsuredIDs: $selectedInsuredIDs
            type: $type
          ) {
            paging {
              pageSize
              pageNumber
              totalCount
            }
            items {
              production_Policy_Id
              production_Policy_PolicyName
              production_Policy_PolicyNumber
              production_Policy_InternalCode
              production_Policy_PolicyEffectiveDate
              production_Policy_PolicyExpiryDate
              production_Policy_PolicyIssueDate
              production_Policy_Status
              production_Policy_StatusReason {
                Code
                Title
              }
              production_Policy_MotorDetailsID {
                id
                views {
                  Production_all {
                    properties {
                      NameOnLicenseEn
                      NameOnLicenseAr
                    }
                  }
                }
              }
              production_Policy_PrimaryBPID {
                id
                views {
                  SalesforceManagement_all {
                    properties {
                      Code
                    }
                  }
                }
              }

              production_Policy_InsuredID {
                id
                views {
                  Production_all {
                    properties {
                      PersonCode
                      FullName
                    }
                  }
                }
              }
              production_Policy_createdOn
              production_Policy_modifiedOn
              line_Id
              line_Name
              line_ExternalCode
              production_Policy_LineID {
                id
                views {
                  PlanConfigManagement_all {
                    properties {
                      Name
                      ExternalCode
                    }
                  }
                }
              }
              production_Policy_SublineID {
                id
                views {
                  PlanConfigManagement_all {
                    properties {
                      Name
                      ExternalCode
                    }
                  }
                }
              }
              production_Policy_PlanID {
                id
                views {
                  PlanConfigManagement_all {
                    properties {
                      Name
                      ExternalCode
                    }
                  }
                }
              }
              production_Policy_BusinessUserID {
                id
                views {
                  SalesforceManagement_all {
                    properties {
                      Account
                    }
                  }
                }
              }
              production_Policy_PolicyBPID {
                id
              }
            }
          }
        }
      }
    }
  `;
}

export function filterQuery() {
  return gql`
    query getAssignedPolicingListWithoutPaginationLine(
      $selectedBusinessUserID: String!
      $selectedLineID: [String]
    ) {
      SalesforceManagement {
        queries {
          getAssignedBPPlanListWithoutPagination(
            selectedBusinessUserID: $selectedBusinessUserID
            selectedLineID: $selectedLineID
          ) {
            salesforceManagement_AssignedBPPlan_BusinessPartnerID {
              id
              views {
                SalesforceManagement_all {
                  properties {
                    Code
                    PersonID {
                      id
                      views {
                        SalesforceManagement_all {
                          properties {
                            FullName
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            salesforceManagement_AssignedBPPlan_PlanID {
              id
              views {
                PlanConfigManagement_all {
                  properties {
                    Name
                    ExternalCode
                    SublineID {
                      id
                      views {
                        PlanConfigManagement_all {
                          properties {
                            Name
                            ExternalCode
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          AllActivePersons {
            salesforceManagement_Person_Id
            salesforceManagement_Person_FullName
            salesforceManagement_Person_PersonCode
          }
        }
      }
      Production {
        lookups {
          policyStatusReasons {
            Id
            Title
          }
        }
      }
      Production_PolicyStatuses: __type(name: "Production_PolicyStatuses") {
        name
        enumValues {
          name
        }
      }
    }
  `;
}

export function lineFilter() {
  return gql`
    query getPoliciesLinesFilter($selectedBusinessUserID: String!) {
      SalesforceManagement {
        queries {
          getAssignedBPPlanListWithoutPagination(
            selectedBusinessUserID: $selectedBusinessUserID
          ) {
            salesforceManagement_AssignedBPPlan_LineID {
              id
              views {
                PlanConfigManagement_all {
                  properties {
                    Id
                    Name
                    ExternalCode
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}
