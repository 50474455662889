import React from 'react';
import EntityInfoWidget from '../../../../../components/widgets/entity-info/EntityInfoWidget';
import { IEntityInfoView } from '../../../../../components/widgets/entity-info/EntityInfoFields';
import { EnhancedDisplayType } from '../../../../../components/enhanced-form/EnhancedDisplay';

interface ILeftPanel {
  data: Record<string, string>;
}

const LeftPanel: React.FC<ILeftPanel> = ({ data }) => {
  const entityView: IEntityInfoView = {
    iconUrl: '',
    socialMedia: {
      facebook: '',
      twitter: '',
      linkedIn: '',
    },
    sections: [
      {
        title: '',
        name: 'BasicInformation',
        properties: {
          treatyId: {
            name: 'treatyId',
            title: 'Treaty ID',
            value: data?.reinsurance_Treaties_TreatyID,
            multiline: false,
          },
          createdBy: {
            name: 'createdBy',
            title: 'Created By',
            value: data?.reinsurance_Treaties_createdBy,
            multiline: false,
          },
          createdOn: {
            name: 'createdOn',
            title: 'Created On',
            value: data?.reinsurance_Treaties_createdOn,
            multiline: false,
            type: EnhancedDisplayType.Date,
          },
          modifiedBy: {
            name: 'modifiedBy',
            title: 'Modified By',
            value: data?.reinsurance_Treaties_modifiedBy,
            multiline: false,
          },
          modifiedOn: {
            name: 'modifiedOn',
            title: 'Modified On',
            value: data?.reinsurance_Treaties_modifiedOn,
            multiline: false,
            type: EnhancedDisplayType.Date,
          },
        },
      },
    ],
  };

  return (
    <EntityInfoWidget
      title={data?.reinsurance_Treaties_TreatyName}
      view={entityView}
      supportsClassification={true}
      background="rgb(193, 0, 0)"
      classification={{ classifications: {}, value: '' }}
      progress={100}
      supportsProgressBar={true}
    />
  );
};

export default LeftPanel;
