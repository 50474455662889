import {
  Autocomplete,
  Chip,
  CircularProgress,
  FormControl,
  TextField,
} from '@mui/material';
import { isEmpty } from 'lodash';
import React from 'react';
import EnhancedInputsWrapper from './EnhancedInputsWrapper';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';
import { MAIN_ONE_THEME, contentFontFamilyRegular } from '../../constants';
import { IEnhancedFormInputBaseProps } from '.';

export interface ISelectFormFieldProps extends IEnhancedFormInputBaseProps {
  value: string;
  selectOptions: Record<string, string>;
  loader?: boolean;
  freeSolo?: boolean;
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
  onFocus?: () => void;
  classes?: { input?: string; wrapper?: string };
}

const useStyles = makeStyles<{
  error?: string;
}>()((theme, { error }) => ({
  inputContainerStyles: {
    width: '100%',
  },
  inputWrapper: {
    position: 'relative',
  },
  inputStyles: {
    width: '100%',
    border: '1px solid #E3E3E3',
    borderRadius: '5px',
    outline: 'none',
    // maxHeight: '34px',
    '& .MuiInputBase-input': {
      '&::placeholder': {
        fontStyle: 'italic',
        fontFamily: contentFontFamilyRegular,
        color: '#33333333',
        opacity: 1,
        fontSize: '14px',
      },
    },
    '& .Mui-disabled': {
      cursor: 'not-allowed',
    },
    '& .MuiInputBase-root': {
      fontSize: `13px`,
      lineHeight: '15px',
      minHeight: '33px',
      width: '100%',
      padding: '0 25px 0 15px!important',
      margin: '0 auto',
      backgroundColor: `#f9f9f9`,
      outlineColor: error ? theme.palette.error.main : undefined,
      border: error
        ? `1px solid ${theme.palette.error.main}`
        : `0px solid #DFE3EB`,
      boxSizing: 'border-box',
      borderRadius: '5px',
      '&::before': {
        display: 'none',
      },
      '&::after': {
        display: 'none',
      },
    },
  },
  optionDesign: {
    fontSize: `${MAIN_ONE_THEME.typography.regular.reg2.fontSize}px`,
    lineHeight: '15px',
    color: MAIN_ONE_THEME.palette.primary1.main,
    fontFamily: contentFontFamilyRegular,
  },
  inputErrorStyles: {
    minHeight: '25px',
    color: '#f44336',
    textAlign: 'left',
    fontSize: '12px',
    marginTop: '5px',
  },
  inputRedBorder: {
    border: '1px solid #f44336',
  },
  chipStyle: {
    height: '25px',
  },
  loader: {
    position: 'absolute',
    top: 'calc(50% - 10px)',
    right: '3%',
    opacity: 1,
    height: '25px',
    width: '25px',
  },
  selectMaterial: {
    '& .MuiInput-input': {
      fontSize: '13px',
      lineHeight: '15px',
      color: `#07295A`,
    },
    '& .MuiInputBase-root:before': {
      border: 'none',
      backgroundColor: 'unset',
      borderRadius: '0',
      borderBottom: `1px solid #DFE3EB`,
    },

    '& .MuiInput-underline:hover:not(.Mui-disabled):before, & .MuiInputBase-root:after':
      {
        borderBottom: `1px solid #DFE3EB`,
      },
  },
  selectError: {
    '& .MuiInputBase-root:before': {
      borderBottom: `1px solid ${theme.palette.error.main}`,
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before, & .MuiInputBase-root:after':
      {
        borderBottom: `1px solid ${theme.palette.error.main}`,
      },
    '& .MuiInputBase-root:focus': {
      borderColor: theme.palette.error.main,
      outlineColor: theme.palette.error.main,
      boxShadow: 'none',
      borderRadius: 0,
    },
    '& .MuiInputBase-root': {
      borderColor: `${theme.palette.error.main}`,
      outlineColor: `${theme.palette.error.main}`,
    },
  },
}));

const SelectFormField: React.FC<ISelectFormFieldProps> = ({
  name,
  title,
  placeholder,
  className,
  style,
  classes = {},
  value,
  error,
  hideError,
  onChange,
  onBlur = () => {},
  onFocus = () => {},
  disabled,
  selectOptions = {},
  loader,
  inputOnly,
  freeSolo = false,
  material,
}) => {
  const { classes: inputClasses } = useStyles({ error });

  const renderInput = () => {
    return (
      <FormControl
        error={!!error}
        disabled={!!disabled}
        className={inputClasses.inputContainerStyles}
        size="small"
        variant="outlined"
      >
        <Autocomplete
          freeSolo={freeSolo}
          multiple={false}
          disabled={!!disabled}
          disablePortal={false}
          options={Object.keys(selectOptions)}
          onChange={(event, newValue) => {
            onChange?.(newValue);
          }}
          onFocus={() => onFocus()}
          onBlur={() => onBlur(value)}
          value={value}
          getOptionLabel={(option) => selectOptions?.[option] || ''}
          disableClearable={true}
          selectOnFocus
          renderOption={(props, option) => (
            <li {...props} key={option}>
              <span className={inputClasses.optionDesign}>
                {selectOptions[option]}
              </span>
            </li>
          )}
          renderTags={(value, getTagProps) =>
            Array.isArray(value) &&
            (value as readonly string[]).map((option: string, index: number) =>
              !isEmpty(selectOptions[option]) ? (
                <Chip
                  variant="filled"
                  label={selectOptions[option]}
                  style={{
                    height: 'unset',
                    color: '#fff',
                    backgroundColor: '#243746',
                    borderRadius: '0px',
                  }}
                  {...getTagProps({ index })}
                  key={index}
                />
              ) : (
                <Chip
                  variant="filled"
                  style={{
                    height: 'unset',
                    color: '#fff',
                    backgroundColor: '#243746',
                    borderRadius: '0px',
                  }}
                  label={option}
                  {...getTagProps({ index })}
                  key={index}
                />
              )
            )
          }
          renderInput={(params) => (
            <TextField
              {...params}
              name={name}
              placeholder={placeholder}
              className={clsx(
                classes.input,
                !material && inputClasses.inputStyles,
                material && inputClasses.selectMaterial,
                error && inputClasses.selectError
              )}
              variant="standard"
            />
          )}
        />
      </FormControl>
    );
  };

  return inputOnly ? (
    renderInput()
  ) : (
    <>
      <EnhancedInputsWrapper
        title={title}
        error={error}
        name={name}
        hideError={hideError}
        className={clsx(classes.wrapper, className)}
        style={style}
      >
        {renderInput()}
        {loader && (
          <CircularProgress
            className={inputClasses.loader}
            size={20}
            thickness={3}
          />
        )}
      </EnhancedInputsWrapper>
    </>
  );
};

export default SelectFormField;
