import React from 'react';
import clsx from 'clsx';
import { makeStyles } from 'tss-react/mui';
import Separator from './Separator';
import { IEnhancedCommonProps } from '..';
import { MAIN_ONE_THEME } from '../../constants';

interface ISectionShellProps extends IEnhancedCommonProps {
  header?: JSX.Element;
  classes?: { header?: string; container?: string };
  useSeparator?: boolean;
  hideHeaderBorder?: boolean;
  children?: React.ReactNode;
}

const useStyles = makeStyles<{ hideHeaderBorder: boolean }>()(
  (theme, { hideHeaderBorder }) => ({
    sectionContainer: {
      width: '100%',
      boxSizing: 'border-box',
    },
    sectionHeader: {
      width: '100%',
      minHeight: 55,
      overflow: 'hidden',
      boxSizing: 'border-box',
      padding: '0 0 0.5em 0',
      borderBottom: hideHeaderBorder
        ? 'none'
        : `1px ${MAIN_ONE_THEME.palette.secondary3.main} solid`,
      color: MAIN_ONE_THEME.palette.primary1.main,
      fontSize: MAIN_ONE_THEME.typography.medium.med1.fontSize,
      lineHeight: '18px',
    },
    container: {
      padding: '23px 0 0 0',
      overflow: 'hidden',
    },
    separator: {
      height: 10,
      margin: '26px 0 18px',
    },
  })
);

const SectionShell: React.FC<ISectionShellProps> = ({
  header,
  useSeparator,
  hideHeaderBorder = false,
  classes = {},
  className,
  style,
  children,
}) => {
  const { classes: sectionClasses } = useStyles({ hideHeaderBorder });

  return (
    <section
      className={clsx(sectionClasses.sectionContainer, className)}
      style={style}
    >
      {header && (
        <div className={clsx(sectionClasses.sectionHeader, classes.header)}>
          {header}
        </div>
      )}
      <div className={clsx(sectionClasses.container, classes.container)}>
        {children}
      </div>
      {useSeparator && <Separator className={sectionClasses.separator} />}
    </section>
  );
};

export default SectionShell;
