import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useEffect, useMemo, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { extractClauseOptions, IClauseOption } from '.';
import EnhancedTinyMCEEditor from '../../../../../components/enhanced-form/EnhancedTinyMCEEditor';
import Loader from '../../../../../components/Loader';
import {
  contentFontFamilyBold,
  MAIN_ONE_THEME,
} from '../../../../../constants';
import { getClauseList, issuePolicyGeneric } from './queries';
import _ from 'lodash';
import { isEmpty } from 'lodash';
import { IProposalDetailsSummary } from '../generic/index2';
import ToastSuccessMessage from '../../../../../components/ToastSuccessMessage';
import { toast } from 'react-toastify';
import ToastErrorMessage from '../../../../../components/ToastErrorMessage';
import { getError } from '../../../../../utils/graph-utils';
import { issuePolicyMedical } from '../medical/queries';
import {
  downloadFileFromUrl,
  generateDownloadLink,
} from '../../../../../utils/file-utils';
import { issuePolicyTravel } from '../travel/queries';
import SelectFormField from '../../../../../components/form-fields/SelectFormField';
import EnhancedButton from '../../../../../components/form-fields/buttons/EnhancedButton';
import { IAbstractRecord } from '../../../../../models';

const useStyles = makeStyles()(() => ({
  container: {
    width: '100%',
    minHeight: '529px',
  },
  text: {
    color: MAIN_ONE_THEME.palette.primary2.main,
    fontSize: '15px',
    marginBottom: '20px',
  },
  body: {
    width: '100%',
    backgroundColor: '#fff',
    boxSizing: 'border-box',
  },
  dialogTemplate: {
    maxWidth: '100%',
  },
  loader: {
    width: '80px',
    height: '80px',
  },
  loaderContainer: {
    textAlign: 'center',
  },
  main: {
    width: '100%',
    minHeight: '500px',
    padding: '35px 25px 25px',
  },
  title: {
    display: 'block',
    minWidth: 'min-content',
    width: '100%',
    margin: '0 auto',
    textAlign: 'center',
    // margin: '0 0 0.5em',
    fontFamily: contentFontFamilyBold,
    fontSize: `${MAIN_ONE_THEME.typography.regular.reg4.fontSize}px !important`,
  },
  textContainer: {
    width: '100%',
  },
  field: {
    width: '100%',
    margin: '0 0 20px',
  },
  plusBtnClauses: {
    backgroundColor: 'transparent',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    padding: '0',
    margin: '10px auto 0',
    display: 'block',
  },
  buttonsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'center',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '15px',
  },
}));

interface IPreviewProposalClauseModalProps {
  data: IProposalDetailsSummary;
  onClose?: () => void;
  onSuccess?: () => void;
}

interface IPageState {
  values: {
    clauseId: string;
    text: string;
  };
  errors: Record<string, string>;
  touched: Record<string, boolean>;
}

const PreviewProposalClauseModal: React.FC<
  IPreviewProposalClauseModalProps
> = ({ data, onClose = () => undefined, onSuccess }) => {
  const currentStatus = data?.Status?.toLocaleLowerCase();
  const lineId = data.LineId.Id;
  const lineExternalCode = data?.LineId?.ExternalCode?.toString();

  const { classes } = useStyles();
  const [loader, setLoader] = useState<boolean>(true);

  const [clauseListQuery] = useLazyQuery(getClauseList());

  const [clauses, setClauses] = useState<IClauseOption[]>([]);

  const [submitting, setSubmitting] = useState<boolean>(false);
  // const [downloading, setDownloading] = useState<boolean>(false);

  const [submitAction] = useMutation(
    ['19', '6'].includes(lineExternalCode)
      ? issuePolicyMedical()
      : ['48'].includes(lineExternalCode)
        ? issuePolicyTravel()
        : issuePolicyGeneric()
  );

  // const [downloadAction] = useMutation(downloadProposalDocument());

  const disabledForm = submitting;

  const [pageState, setPageState] = useState<IPageState>({
    values: {
      clauseId: '',
      text: data.ClauseText,
    },
    errors: {},
    touched: {},
  });

  const clausesList = useMemo(() => {
    const result: Record<string, string> = {};
    clauses.forEach((c) => {
      result[c.id] = `${c.externalCode} - ${c.name}`;
    });
    return result;
  }, [clauses]);

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialize = async () => {
    const data = await clauseListQuery({
      variables: {
        currentPage: 1,
        currentPageSize: 1000,
        selectedLineIds: lineId,
      },
      errorPolicy: 'all',
    });

    setClauses(extractClauseOptions(data));
    setLoader(false);
  };

  const onFieldUpdate = (
    fieldName: keyof IPageState['values'],
    value: string
  ) => {
    const newPageState = _.cloneDeep(pageState);
    newPageState.values[fieldName] = value;
    newPageState.errors[fieldName] = validateField(fieldName, value);
    setPageState(newPageState);
  };

  const onFieldBlur = (fieldName: string) => {
    const newPageState = _.cloneDeep(pageState);
    newPageState.touched[fieldName] = true;
    setPageState(newPageState);
  };

  const validateField = (
    fieldName: keyof IPageState['values'],
    value: string
  ) => {
    if (fieldName === 'text') {
      if (isEmpty(value)) {
        return 'Required';
      }
    }
    return '';
  };

  const validateForm = () => {
    const newPageState = _.cloneDeep(pageState);
    setPageState(newPageState);
    newPageState.errors = {
      clauseId: validateField('clauseId', pageState.values.clauseId),
      text: validateField('text', pageState.values.text),
    };

    newPageState.touched = {
      clauseId: true,
      text: true,
    };

    setPageState(newPageState);

    return Object.values(newPageState.errors).every((e) => isEmpty(e));
  };

  const submit = async () => {
    const isValid = validateForm();

    if (isValid) {
      if (!submitting) {
        setSubmitting(true);
        const dataToSubmit = {
          entityId: data.Id,
          clauseText: pageState.values.text,
        };
        const result = await submitAction({
          variables: { ...dataToSubmit },
          errorPolicy: 'all',
        });

        if (isEmpty(result.errors)) {
          // setDeactivateButtonState("success");
          toast.success(
            <ToastSuccessMessage>
              {'Policy successfully issued.'}
            </ToastSuccessMessage>
          );

          if (['19', '6'].includes(lineExternalCode)) {
            const filesInfo = getFileDetailsList(result.data);
            for (const fileInfo of filesInfo) {
              const downloadLink = generateDownloadLink(
                fileInfo.fileId,
                fileInfo.EntityTypeId,
                fileInfo.EntityId,
                fileInfo.EntityViewId,
                fileInfo.PropertyId
              );
              await downloadFileFromUrl(downloadLink, fileInfo.fileName);
            }
          }

          onSuccess();
        } else {
          // setRegenerateButtonState(undefined);
          setSubmitting(false);
          toast.error(
            <ToastErrorMessage>{getError(result)}</ToastErrorMessage>
          );
        }
      }
    }
  };

  // const downloadDocument = async () => {
  //   const isValid = validateForm();

  //   if (isValid) {
  //     if (!downloading) {
  //       setDownloading(true);
  //       const dataToSubmit = {
  //         entityId: data.Id,
  //         clauseText: pageState.values.text,
  //       };
  //       const result = await downloadAction({
  //         variables: { ...dataToSubmit },
  //         errorPolicy: "all",
  //       });
  //       if (isEmpty(result.errors)) {
  //         // setDeactivateButtonState("success");
  //         const fileInfo = getFileDetails(result.data);
  //         const downloadLink = generateDownloadLink(
  //           fileInfo.fileId,
  //           fileInfo.EntityTypeId,
  //           fileInfo.EntityId,
  //           fileInfo.EntityViewId,
  //           fileInfo.PropertyId
  //         );

  //         await downloadFileFromUrl(downloadLink, fileInfo.fileName);

  //         toast.success(
  //           <ToastSuccessMessage>
  //             {"Policy sample successfully downloaded."}
  //           </ToastSuccessMessage>
  //         );
  //         // onSuccess();
  //       } else {
  //         // setRegenerateButtonState(undefined);
  //         setDownloading(false);
  //         toast.error(
  //           <ToastErrorMessage>{getError(result)}</ToastErrorMessage>
  //         );
  //       }
  //     }
  //   }

  //   setDownloading(false);
  // };

  const currentClause = clauses.find((c) => c.id === pageState.values.clauseId);

  if (loader) {
    return (
      <div className={classes.loaderContainer}>
        <Loader className={classes.loader} />
      </div>
    );
  }

  return (
    <div>
      <div className={classes.textContainer}>
        <SelectFormField
          name="clauses"
          title="Insert Clause"
          value={pageState.values.clauseId || ''}
          onChange={(e) => {
            onFieldUpdate('clauseId', e);
          }}
          onBlur={() => {
            onFieldBlur('clauseId');
          }}
          disabled={
            disabledForm ||
            !(
              data.BusinessUserID.AllowClauseEdits && data.PlanID.ClauseEditable
            )
          }
          selectOptions={clausesList}
          error={pageState.errors.clauseId}
          // error={errors?.isRenewal?.message}
          // disabled={formDisabled}
        />
      </div>

      <EnhancedTinyMCEEditor
        name="text"
        title="Description*"
        value={pageState.values.text || ''}
        onChange={(_, v) => {
          onFieldUpdate('text', v);
        }}
        onBlur={() => {
          onFieldBlur('text');
        }}
        width={'100%'}
        htmlToAdd={currentClause?.description}
        error={pageState.touched.text ? pageState.errors.text : ''}
        // error={errors?.isRenewal?.message}
        // disabled={formDisabled}
        disabled={
          disabledForm ||
          !(data.BusinessUserID.AllowClauseEdits && data.PlanID.ClauseEditable)
        }
        className={classes.field}
        placeholder={''}
        addButton={true}
      />

      {
        <div className={classes.buttonsContainer}>
          {/* {currentStatus !== "new" && (
                  <EnhancedButton
                    className={classes.button}
                    state={downloading ? "loading" : undefined}
                    backgroundColor={MAIN_ONE_THEME.palette.primary1.main}
                    color="rgba(255, 255, 255, 1)"
                    disabled={disabledForm}
                    onClick={() => {
                      downloadDocument();
                    }}
                  >
                    Download Policy Sample
                  </EnhancedButton>
                )} */}

          <EnhancedButton disabled={disabledForm} onClick={() => onClose()}>
            Cancel
          </EnhancedButton>

          {currentStatus !== 'new' && (
            <EnhancedButton
              state={submitting ? 'loading' : undefined}
              disabled={disabledForm}
              isPrimary
              onClick={() => submit()}
            >
              Submit
            </EnhancedButton>
          )}
        </div>
      }
    </div>
  );
};

export default PreviewProposalClauseModal;

function getFileDetailsList(data: IAbstractRecord): FileDetail[] {
  const fileDetails: FileDetail[] = [];

  const items =
    data.production.entities.proposal.production.issuePolicyMedical.Items || [];
  for (const item of items) {
    const fileDetail: FileDetail = {
      EntityTypeId: item.EntityTypeId,
      EntityId: item.EntityId,
      EntityViewId: item.EntityViewId,
      PropertyId: item.PropertyId,
      fileId: item.File.id,
      fileName: item.File.fileName,
    };
    fileDetails.push(fileDetail);
  }

  return fileDetails;
}

type FileDetail = {
  EntityTypeId: string;
  EntityId: string;
  EntityViewId: string;
  PropertyId: string;
  fileId: string;
  fileName: string;
};
