import React from 'react';
import { IListingData } from '../../../models/listing';
import { IFilterModel, ITableSettings } from '.';
import { IAbstractRecord } from '../../../models';
import { makeStyles } from 'tss-react/mui';
import { contentFontFamilyBold, MAIN_ONE_THEME } from '../../../constants';
import { IEnhancedMenuItem } from '../table';
import ListingTableWithPagination from '../table/ListingTableWithPagination';
import ListingTable from '../table/ListingTable';
import useActionFilter from '../../../custom-hooks/useActionFilter';
import ActionMenu from '../menu/ActionMenu';
import SimpleActionBar from '../menu/SimpleActionBar';

export interface IListingWidgetProps {
  name: string;
  title: string;
  inlineTitle?: string;
  inline?: boolean;
  actions: IEnhancedMenuItem[];
  data: IListingData;
  tableSettings: ITableSettings;
  pageContext?: string;
  onPageChange?: (filterModel: IFilterModel) => Promise<void>;
  dropdownActionsNames?: string[];
  defaultOrderByColumn?: string;
  orderByAscendingByDefault?: boolean;
  secondOrderByColumn?: string;
  secondOrderByAscendingByDefault?: boolean;
  disableSelection?: boolean;
  context?: IAbstractRecord;
  usePagination?: boolean;
  loading?: boolean;
  pageSizeOptions?: number[];
  forceShowSelectColumn?: boolean;
}

const useStyles = makeStyles()(() => ({
  header: {
    margin: '0 0 1em',
    display: 'grid',
    gridTemplateAreas: `'title actions'`,
    gridGap: '1em',
  },
  title: {
    display: 'block',
    textAlign: 'left',
    fontSize: MAIN_ONE_THEME.typography.medium.med2.fontSize,
    fontFamily: contentFontFamilyBold,
    letterSpacing: 0,
    color: MAIN_ONE_THEME.palette.primary1.main,
    fontWeight: 'normal',
    margin: 0,
    gridArea: 'title',
    alignSelf: 'center',
  },
  actions: {
    display: 'flex',
    gridArea: 'actions',
    justifyContent: 'flex-end',
  },
  toggleGroup: {
    margin: '0 0.5em 0 0',
  },
  toggleButton: {
    textTransform: 'capitalize',
    '&.MuiToggleButton-root.Mui-selected': {
      color: '#FFFFFF',
      backgroundColor: MAIN_ONE_THEME.palette.primary2.main,
    },
  },
  toggle: {
    backgroundColor: '#FFFFFF',
    color: MAIN_ONE_THEME.palette.primary2.main,
    height: '32px',
  },
  secondaryActions: {
    boxSizing: 'border-box',
    display: 'inline-flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'flex-end',
    color: MAIN_ONE_THEME.palette.primary1.main,
    margin: '0 0 0 0.5em',
    backgroundColor: 'white',
  },
  dropdown: {
    width: 'unset',
  },
  dropdownField: {
    lineHeight: 1.5,
    textAlign: 'left',
    color: MAIN_ONE_THEME.palette.primary1.main,
    display: 'flex',
  },
}));

const ListingWidget: React.FC<IListingWidgetProps> = ({
  name,
  actions = [],
  data,
  inline,
  tableSettings,
  title,
  dropdownActionsNames,
  inlineTitle,
  defaultOrderByColumn,
  orderByAscendingByDefault = false,
  secondOrderByColumn,
  secondOrderByAscendingByDefault = false,
  disableSelection,
  context,
  usePagination,
  pageContext,
  loading,
  pageSizeOptions,
  onPageChange,
  forceShowSelectColumn,
}) => {
  const { classes } = useStyles();
  const [widgetActions, entityActions] = useActionFilter(actions);

  const handlePageChange = (
    page: number,
    searchKey = '',
    rowsPerPage = 0,
    order = '',
    orderBy = ''
  ) => {
    try {
      const filterModel: IFilterModel = {
        searchKey,
        orderBy: orderBy,
        descending: order === 'desc',
        pagination: {
          pageSize: rowsPerPage !== 0 ? rowsPerPage : data.pageSize,
          pageNumber: page,
        },
      };

      return new Promise<void>(() => {
        if (onPageChange) {
          void onPageChange(filterModel);
        }
      });
    } catch (error) {
      return new Promise<void>(() => {});
    }
  };

  const renderActionMenu = () => {
    if (
      widgetActions &&
      widgetActions.length > 0 &&
      dropdownActionsNames &&
      dropdownActionsNames.length > 0
    ) {
      const dropdownActions = widgetActions.filter((action) =>
        dropdownActionsNames.includes(action.name)
      );
      if (dropdownActions.length > 0) {
        return (
          <div className={classes.secondaryActions}>
            <ActionMenu actions={dropdownActions} title={'Actions'} />
          </div>
        );
      }
    }
    return <></>;
  };

  const renderTableWithPagination = () => (
    <ListingTableWithPagination
      name={name}
      pageContext={pageContext}
      headers={tableSettings.headers}
      rowSettings={tableSettings.rowSettings}
      data={data}
      dropdownActionsNames={dropdownActionsNames}
      actions={inline ? actions : entityActions}
      inline={inline}
      filterableNames={tableSettings.filterableNames}
      inlineTitle={inlineTitle}
      defaultOrderByColumn={defaultOrderByColumn}
      orderByAscendingByDefault={orderByAscendingByDefault}
      secondOrderByColumn={secondOrderByColumn}
      secondOrderByAscendingByDefault={secondOrderByAscendingByDefault}
      disableSelection={disableSelection}
      context={context}
      loader={loading}
      pageSizeOptions={pageSizeOptions}
      usePagination={usePagination}
      onPageChange={handlePageChange}
      // forceShowSelectColumn={forceShowSelectColumn}
    />
  );

  const renderTable = () => (
    <ListingTable
      name={name}
      headers={tableSettings.headers}
      rowSettings={tableSettings.rowSettings}
      data={data}
      dropdownActionsNames={dropdownActionsNames}
      actions={actions}
      inline={inline}
      filterableNames={tableSettings.filterableNames}
      splitCharacter={tableSettings.splitCharacter}
      inlineTitle={inlineTitle}
      defaultOrderByColumn={defaultOrderByColumn}
      orderByAscendingByDefault={orderByAscendingByDefault}
      secondOrderByColumn={secondOrderByColumn}
      secondOrderByAscendingByDefault={secondOrderByAscendingByDefault}
      disableSelection={disableSelection}
      context={context}
      loader={loading}
      pageSizeOptions={pageSizeOptions}
      forceShowSelectColumn={forceShowSelectColumn}
    />
  );

  return (
    <>
      {(title || (!inline && actions.length > 0)) && (
        <section className={classes.header}>
          <h1 className={classes.title}>{title}</h1>
          <div className={classes.actions}>
            {!inline && widgetActions && (
              <>
                <SimpleActionBar
                  items={widgetActions.filter(
                    (action) =>
                      !dropdownActionsNames ||
                      !dropdownActionsNames.includes(action.name)
                  )}
                />
                {renderActionMenu()}
              </>
            )}
          </div>
        </section>
      )}
      {tableSettings && usePagination
        ? renderTableWithPagination()
        : renderTable()}
    </>
  );
};

export default ListingWidget;
