import React, { useEffect, useState } from 'react';
import { DEFAULT_ERROR_TEXT } from '../../../../../constants';
import { IListingData } from '../../../../../models/listing';
import { headers } from './content';
import { useLazyQuery } from '@apollo/client';
import { businessPartnerQuery, getPolicyBills } from '../queries';
import { toast } from 'react-toastify';
import ToastErrorMessage from '../../../../../components/ToastErrorMessage';
import Loader from '../../../../../components/Loader';
import { mapToListingData } from './utils';
import PaymentTermsPopup from '../../../../../forms/payment-terms/PaymentTermsPopup';
import { IAbstractRecord } from '../../../../../models';
import { getPolicyDetailsQuery } from '../../../../../forms/payment-terms/queries';
import ListingTable from '../../../../../components/form-fields/table/ListingTable';
import { IEnhancedMenuItem } from '../../../../../components/form-fields/table';

interface IPolicyPaymentTermsSchedule {
  policyId: string;
}

const ProductionPolicyPaymentTermsSchedule: React.FC<
  IPolicyPaymentTermsSchedule
> = ({ policyId }) => {
  const [booted, setBooted] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [showTermsPopup, setShowTermsPopup] = useState<{
    open: boolean;
    policyNumber?: string;
    data?: IAbstractRecord;
  }>({
    open: false,
  });
  const [tableData, setTableData] = useState<IListingData>({
    pagedItems: {},
    pageSize: 5,
    pageNumber: 0,
    totalCount: 0,
  });

  const tableAction: IEnhancedMenuItem[] = [
    {
      name: 'changepaymentterms',
      title: 'Change Payment Terms',
      onClick: () => {
        setShowTermsPopup((oldState) => ({
          ...oldState,
          open: true,
        }));
      },
    },
  ];

  const [getPolicyBillsLazy] = useLazyQuery(getPolicyBills());
  const [getBusinessPartnerDetailsLazy] = useLazyQuery(
    businessPartnerQuery(),
    {}
  );
  const [getPolicyDetailsLazy] = useLazyQuery(getPolicyDetailsQuery());

  const loadData = async () => {
    setLoading(true);

    try {
      const result = await getPolicyBillsLazy({
        variables: {
          policyId: policyId,
        },
      });

      if (result.error) {
        toast.error(
          <ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>
        );
        return;
      }

      const data = result.data;

      const newTableData = mapToListingData(data);
      setTableData({ ...newTableData });
    } catch (error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setLoading(false);
      setBooted(true);
    }

    try {
      const policyDetailsResult = await getPolicyDetailsLazy({
        variables: {
          policyId,
        },
      });

      if (policyDetailsResult.error) {
        toast.error(
          <ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>
        );
        return;
      }

      setShowTermsPopup({
        open: false,
        policyNumber:
          policyDetailsResult.data?.Production?.entities?.policy?.views
            ?.Production_all?.properties?.PolicyNumber,
        data: policyDetailsResult.data,
      });
    } catch (error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  const initialize = async () => {
    await loadData();
  };

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleTooltipFetch(id: string): Promise<IAbstractRecord> {
    const popoverRows: IAbstractRecord = {};
    await getBusinessPartnerDetailsLazy({
      variables: {
        billID: id,
      },
    }).then((response) => {
      response?.data?.Accounting?.queries?.GetBusinessPartnersCommissions?.forEach(
        (obj: IAbstractRecord, i: number) => {
          popoverRows[i] = {
            BusinessPartner: obj.businessPartner_FullName,
            Share: Number(obj.policyBusinessPartner_CommissionPercentage) * 100,
            Commission: obj.policyBusinessPartner_PolicyCommissionAmount,
            Currency: obj.accounting_Bills_Currency?.Symbol,
          };
        }
      );
    });
    return popoverRows;
  }

  headers.accounting_Bills_TotalCommission.tooltipSettings.tooltipFetch =
    handleTooltipFetch;

  if (!booted) {
    return <Loader />;
  }

  return (
    <>
      <ListingTable
        inlineTitle="Payment Terms Schedule"
        name="paymentTermsSchedule"
        data={tableData}
        headers={headers}
        loader={loading}
        actions={tableAction}
        inline
        orderByAscendingByDefault
        disableSelection
      />
      {showTermsPopup.open && (
        <PaymentTermsPopup
          policyId={policyId}
          policyNumber={showTermsPopup.policyNumber}
          data={showTermsPopup.data}
          currencySymbol={Object.values(tableData.pagedItems)[0]?.Currency}
          open={showTermsPopup.open}
          onClose={() => {
            setShowTermsPopup((oldState) => ({
              ...oldState,
              open: false,
            }));
            initialize();
          }}
        />
      )}
    </>
  );
};

export default ProductionPolicyPaymentTermsSchedule;
