import { IInsuredData } from '../../form';

export const getInsuredLov = (
  row: IInsuredData,
  lovs: Record<string, Record<string, string>>,
  insuredValues: IInsuredData[]
) => {
  const result = { ...lovs.insuredRelations };
  delete result['PRINCIPAL'];
  if (row.relation !== 'SPOUSE') {
    if (insuredValues.some((a) => a.relation === 'SPOUSE')) {
      delete result['SPOUSE'];
    }
  }
  return result;
};

export const getDefaultPersonListIfEmpty = (
  list: Record<string, string>,
  insured: IInsuredData
) => {
  if (Object.keys(list || {}).length > 0 || !insured.fullName) {
    return list;
  }

  const newList: Record<string, string> = {
    [insured.id]: insured.fullName,
  };

  return newList;
};
