import {
  EnhancedTableHeader,
  EnhancedTableHeaderType,
} from '../../../../../../components/enhanced-table';

export const getHeaders = (
  currency?: string
): Record<string, EnhancedTableHeader> => ({
  certificateNumber: {
    name: 'certificateNumber',
    title: 'Certificate Number',
    type: EnhancedTableHeaderType.Link,
    urlTemplate: '/production/policy/{policyId}',
    openUrlInNewTab: false,
  },
  subline: {
    name: 'subline',
    title: 'Subline',
    type: EnhancedTableHeaderType.Text,
  },
  plan: {
    name: 'plan',
    title: 'Plan',
    type: EnhancedTableHeaderType.Text,
  },
  matter: {
    name: 'matter',
    title: 'Matter',
    type: EnhancedTableHeaderType.Text,
  },
  vesselName: {
    name: 'vesselName',
    title: 'Vessel Name',
    type: EnhancedTableHeaderType.Text,
  },
  vesselAge: {
    name: 'vesselAge',
    title: 'Vessel Age',
    type: EnhancedTableHeaderType.Text,
  },
  departureFrom: {
    name: 'departureFrom',
    title: 'Departure From',
    type: EnhancedTableHeaderType.Text,
  },
  destinationTo: {
    name: 'destinationTo',
    title: 'Destination To',
    type: EnhancedTableHeaderType.Text,
  },
  transportationVia: {
    name: 'transportationVia',
    title: 'Transportation Via',
    type: EnhancedTableHeaderType.Text,
  },
  lcNumber: {
    name: 'lcNumber',
    title: 'LC Number',
    type: EnhancedTableHeaderType.Text,
  },
  lcIssueDate: {
    name: 'lcIssueDate',
    title: 'LC Issue Date',
    type: EnhancedTableHeaderType.Date,
  },
  loading: {
    name: 'loading',
    title: 'Loading',
    type: EnhancedTableHeaderType.Text,
  },
  sumPerShipment: {
    name: 'sumPerShipment',
    title: 'Sum Per Shipment',
    type: EnhancedTableHeaderType.Currency,
    currency: currency,
  },
  sumInsured: {
    name: 'sumInsured',
    title: 'Sum Insured',
    type: EnhancedTableHeaderType.Currency,
    currency: currency,
  },
  netPremium: {
    name: 'netPremium',
    title: 'Net Premium',
    type: EnhancedTableHeaderType.Currency,
    currency: currency,
  },
  costAndCharges: {
    name: 'costAndCharges',
    title: 'Cost & Charges',
    type: EnhancedTableHeaderType.Currency,
    currency: currency,
  },
  taxes: {
    name: 'taxes',
    title: 'Taxes',
    type: EnhancedTableHeaderType.Currency,
    currency: currency,
  },
  totalPremium: {
    name: 'totalPremium',
    title: 'Total Premium',
    type: EnhancedTableHeaderType.Currency,
    currency: currency,
  },
  effectiveDate: {
    name: 'effectiveDate',
    title: 'Effective Date',
    type: EnhancedTableHeaderType.Date,
  },
  status: {
    name: 'status',
    title: 'Status',
    type: EnhancedTableHeaderType.Text,
  },
});

export const excelHeaders = (currencySymbol?: string) => ({
  certificateNumber: {
    label: 'Certificate Number',
    visible: true,
    isDate: false,
    isCurrency: false,
    currencySymbol,
  },
  subline: {
    label: 'Subline',
    visible: true,
    isDate: false,
    isCurrency: false,
    currencySymbol,
  },
  plan: {
    label: 'Plan',
    visible: true,
    isDate: false,
    isCurrency: false,
    currencySymbol,
  },
  matter: {
    label: 'Matter',
    visible: true,
    isDate: false,
    isCurrency: false,
    currencySymbol,
  },
  vesselName: {
    label: 'Vessel Name',
    visible: true,
    isDate: false,
    isCurrency: false,
    currencySymbol,
  },
  vesselAge: {
    label: 'Vessel Age',
    visible: true,
    isDate: false,
    isCurrency: false,
    currencySymbol,
  },
  departureFrom: {
    label: 'Departure From',
    visible: true,
    isDate: false,
    isCurrency: false,
    currencySymbol,
  },
  destinationTo: {
    label: 'Destination To',
    visible: true,
    isDate: false,
    isCurrency: false,
    currencySymbol,
  },
  transportationVia: {
    label: 'Transportation Via',
    visible: true,
    isDate: false,
    isCurrency: false,
    currencySymbol,
  },
  lcNumber: {
    label: 'LC Number',
    visible: true,
    isDate: false,
    isCurrency: false,
    currencySymbol,
  },
  lcIssueDate: {
    label: 'LC Issue Date',
    visible: true,
    isDate: true,
    isCurrency: false,
    currencySymbol,
  },
  loading: {
    label: 'Loading',
    visible: true,
    isDate: false,
    isCurrency: false,
    currencySymbol,
  },
  sumPerShipment: {
    label: `Sum Per Shipment - ${currencySymbol}`,
    visible: true,
    isDate: false,
    isCurrency: true,
    currencySymbol,
  },
  sumInsured: {
    label: `Sum Insured - ${currencySymbol}`,
    visible: true,
    isDate: false,
    isCurrency: true,
    currencySymbol,
  },
  netPremium: {
    label: `Net Premium - ${currencySymbol}`,
    visible: true,
    isDate: false,
    isCurrency: true,
    currencySymbol,
  },
  costAndCharges: {
    label: `Cost & Charges - ${currencySymbol}`,
    visible: true,
    isDate: false,
    isCurrency: true,
    currencySymbol,
  },
  taxes: {
    label: `Taxes - ${currencySymbol}`,
    visible: true,
    isDate: false,
    isCurrency: true,
    currencySymbol,
  },
  totalPremium: {
    label: `Total Premium - ${currencySymbol}`,
    visible: true,
    isDate: false,
    isCurrency: true,
    currencySymbol,
  },
  effectiveDate: {
    label: 'Effective Date',
    visible: true,
    isDate: true,
    isCurrency: false,
    currencySymbol,
  },
  status: {
    label: 'Status',
    visible: true,
    isDate: false,
    isCurrency: false,
    currencySymbol,
  },
});
