import clsx from 'clsx';
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { IContainerProps, IEnhancedCommonProps } from '..';
import { MAIN_ONE_THEME, contentFontFamilyRegular } from '../../constants';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';

export interface IEnhancedInputWrapperProps
  extends IEnhancedCommonProps,
    IContainerProps {
  name: string;
  title: string;
  error?: string;
  hasTooltip?: boolean;
  tooltipText?: string;
  hideError?: boolean;
  classes?: {
    label?: string;
  };
}

const useStyles = makeStyles()((theme) => ({
  labelWrapper: {
    display: 'block',
    textAlign: 'left',
    fontSize: `${MAIN_ONE_THEME.typography.regular.reg2.fontSize}px`,
    lineHeight: '15px',
  },
  label: {
    display: 'block',
    color: MAIN_ONE_THEME.palette.primary1.main,
    textAlign: 'left',
    margin: '0 0 13px',
    fontFamily: contentFontFamilyRegular,
  },
  description: {
    fontSize: MAIN_ONE_THEME.typography.small.sm2.fontSize,
    fontStyle: 'italic',
    marginLeft: '5px',
    color: MAIN_ONE_THEME.palette.primary1.main,
  },
  error: {
    textAlign: 'right',
    fontSize: MAIN_ONE_THEME.typography.small.sm2.fontSize,
    minHeight: '15px',
    display: 'block',
    color: MAIN_ONE_THEME.palette.error.main,
    margin: '5px 0 0 ',
  },
  childrenContainer: {
    position: 'relative',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    direction: 'ltr',
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  capsLockWarning: {
    color: '#D30D2B',
    fontSize: MAIN_ONE_THEME.typography.small.sm2.fontSize,
    display: 'block',
    marginTop: '-15px',
    textAlign: 'left',
  },
  tooltipIcon: {
    marginLeft: '5px',
    cursor: 'pointer',
    width: '12px',
    height: '12px',
  },
  tooltip: {
    backgroundColor: 'white',
    color: 'black',
    boxShadow: theme.shadows[1],
    fontSize: '12px',
    padding: '10px',
  },
  arrow: {
    color: 'white',
    '&::before': {
      boxShadow: theme.shadows[1],
    },
  },
}));

const EnhancedInputsWrapper: React.FC<IEnhancedInputWrapperProps> = ({
  className,
  error,
  style,
  name,
  title,
  hideError,
  children,
  hasTooltip = false,
  tooltipText,
  classes = {},
}) => {
  const { classes: labelClasses } = useStyles();

  return (
    <label
      htmlFor={name}
      className={clsx(labelClasses.labelWrapper, className)}
      style={style}
    >
      <div className={labelClasses.headerContainer}>
        <span className={clsx(labelClasses.label, classes.label)}>
          {title}
          {hasTooltip && tooltipText !== '' && (
            <Tooltip
              title={tooltipText || ''}
              placement="top"
              classes={{
                tooltip: labelClasses.tooltip,
                arrow: labelClasses.arrow,
              }}
              arrow
            >
              <InfoIcon className={labelClasses.tooltipIcon} />
            </Tooltip>
          )}
        </span>
      </div>
      <div className={labelClasses.childrenContainer}>{children}</div>
      {!hideError && (
        <span id={`errorMsg-${name}`} className={labelClasses.error}>
          {error}
        </span>
      )}
    </label>
  );
};

export default EnhancedInputsWrapper;
