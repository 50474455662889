import { gql } from '@apollo/client';

export const getTreatyDocumentsQuery = gql`
  query getTreatyDocuments($TreatyID: String!) {
    Reinsurance {
      queries {
        GetTreatyDocuments(TreatyID: $TreatyID) {
          reinsurance_TreatyDocuments_createdOn
          reinsurance_TreatyDocuments_DocumentName
          reinsurance_TreatyDocuments_DocumentType
          reinsurance_TreatyDocuments_Id
          reinsurance_TreatyDocuments_Version
          reinsurance_TreatyDocuments_Document {
            contentType
            fileName
            id
            length
            location
            path
          }
        }
      }
    }
  }
`;
