import React, { useState, useEffect } from 'react';
import { Toolbar } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import {
  contentFontFamilyBold,
  contentFontFamilyRegular,
  MAIN_ONE_THEME,
} from '../../../constants';
import clsx from 'clsx';

interface IListingToolbar {
  onChange: (value: string) => void;
  cdnUrl: string;
  notes?: boolean;
  title?: string;
  separator?: boolean;
  filterElem?: JSX.Element;
  classes?: { leftSection?: string; rightSection?: string };
  forceClearSearchValue?: boolean;
}

const useStyles = makeStyles<{ cdnUrl: string; separator: boolean }>()(
  (theme, { separator, cdnUrl }) => ({
    root: {
      padding: '1px 16px',
      minHeight: '50px!important',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    },
    searchbar: {
      color: MAIN_ONE_THEME.palette.primary2.main,
      height: '23px',
      minWidth: '150px',
      width: '50%',
      border: 'unset',
      outline: 'unset',
      backgroundColor: 'transparent',
      fontFamily: contentFontFamilyRegular,
      '&:focus': {
        '-webkit-input-placeholder': { color: 'transparent' },
      },
      marginRight: !separator ? 'calc(2em + 10px)' : '0',
    },
    titleWrapper: {
      marginTop: '3px',
      flexGrow: 1,
      flexShrink: 1,
      minWidth: '30%',
      display: 'flex',
      marginLeft: '5px',
    },
    title: {
      flexGrow: 1,
      flexShrink: 1,
      fontFamily: contentFontFamilyBold,
      fontSize: MAIN_ONE_THEME.typography.regular.reg3.fontSize,
      color: MAIN_ONE_THEME.palette.primary1.main,
      alignSelf: 'center',
    },
    separator: {
      width: '1px',
      height: '25px',
      opacity: 0.5,
      backgroundColor: MAIN_ONE_THEME.palette.secondary3.main,
      marginLeft: 'calc(1em + 5px)',
      marginRight: 'calc(1em + 5px)',
      display: 'inline-block',
      verticalAlign: 'text-bottom',
      alignSelf: 'center',
    },
    filterContainer: {
      display: 'flex',
      paddingLeft: '40px',
      background: `url(${cdnUrl}/icons/filter-thick-primary-new.svg)`,
      backgroundSize: '16px 16px',
      backgroundPosition: 'top 4px left 0',
      backgroundRepeat: 'no-repeat',
      boxSizing: 'border-box',
    },
  })
);

const ListingToolbar: React.FC<React.PropsWithChildren<IListingToolbar>> = ({
  onChange,
  cdnUrl,
  classes = {},
  filterElem,
  children,
  title,
  separator = false,
  forceClearSearchValue = false,
}) => {
  const { classes: toolbarClasses } = useStyles({
    cdnUrl,
    separator,
  });
  const [searchValue, setSearchValue] = useState<string>('');

  useEffect(() => {
    if (forceClearSearchValue) {
      setSearchValue('');
    }
  }, [forceClearSearchValue]);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
    onChange(event.target.value ? event.target.value.trim() : '');
  };

  const renderFilterInput = () => (
    <div
      className={toolbarClasses.filterContainer}
      style={{ width: filterElem ? 'max-content' : 'min-content' }}
    >
      {filterElem ? (
        <>
          {filterElem}
          <span className={toolbarClasses.separator} />
        </>
      ) : (
        <></>
      )}
      <input
        type="text"
        className={toolbarClasses.searchbar}
        placeholder={'Search'}
        onChange={handleChange}
        onFocus={(e) => {
          e.target.placeholder = '';
        }}
        onBlur={(e) => {
          e.target.placeholder = 'Search';
        }}
        value={searchValue}
      />
    </div>
  );

  return (
    <Toolbar className={toolbarClasses.root}>
      <>
        <div className={clsx(toolbarClasses.titleWrapper, classes.leftSection)}>
          {title && <div className={toolbarClasses.title}>{title}</div>}
          {renderFilterInput()}
        </div>
        <div className={classes.rightSection} style={{ display: 'flex' }}>
          {separator && <span className={toolbarClasses.separator} />}
          {children}
        </div>
      </>
    </Toolbar>
  );
};

export default ListingToolbar;
