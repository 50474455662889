import { ITreatyLovs } from '.';
import { IAbstractRecord } from '../../../../models';
import {
  enumListAsRecordObject,
  lookupListAsRecordObject,
} from '../../../../utils/graph-utils';

export function toLookupsData(data: IAbstractRecord) {
  const lovs: ITreatyLovs = {
    currency: {},
    treatyType: {},
    treatySubType: {
      proportional: {},
      non_proportional: {},
    },
    category: {},
  };

  lovs.currency = lookupListAsRecordObject(
    data?.Core?.lookups?.currencies,
    false,
    'Code',
    'Symbol'
  );

  lovs.treatyType = enumListAsRecordObject(
    data?.Reinsurance_TreatyType?.enumValues
  );

  lovs.category = enumListAsRecordObject(
    data?.Reinsurance_SublineCategoryList?.enumValues
  );

  data?.Reinsurance?.lookups?.treatySubType?.forEach(
    (item: IAbstractRecord) => {
      if (item?.TreatyType?.toLowerCase() === 'proportional') {
        lovs.treatySubType.proportional[item.Code] = item.Title;
      } else if (item?.TreatyType?.toLowerCase() === 'non-proportional') {
        lovs.treatySubType.non_proportional[item.Code] = item.Title;
      }
    }
  );

  return lovs;
}
