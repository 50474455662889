import React, { useEffect, useState } from 'react';
import DynamicForm from '../../DynamicForm/DynamicForm';
import {
  DynamicFormInputType,
  IFormSelectDynamicProps,
} from '../../DynamicForm';
import { toast } from 'react-toastify';
import { EnhancedButtonStatus } from '../../components/common/EnhancedButton';
import { normaliseDynamicValues } from '../../utils/dynamic-utils';
import ToastErrorMessage from '../../components/ToastErrorMessage';
import { inputs } from './content';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  checkClauseExists,
  createClause,
  getClauseEnums,
  getClauseInfo,
  updateClause,
} from './queries';
import { LookupToList, graphqlEntityToClauseInfo } from './utils';
import Loader from '../../components/Loader';
import { cloneDeep } from 'lodash';
import ToastSuccessMessage from '../../components/ToastSuccessMessage';
import { isEmpty } from '../../utils/validationUtils';
import { DEFAULT_ERROR_TEXT } from '../../constants';
import { getError } from '../../utils/graph-utils';

const ClausePopUp: React.FC<IClausePopUpProps> = ({
  clauseId,
  lineId,
  lineName,
  isLineFieldEnabled = false,
  onSuccess,
  onClose,
}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [inputsForm, setInputsForm] =
    useState<Record<string, DynamicFormInputType>>(inputs);

  const [clauseEnumsQuery] = useLazyQuery(getClauseEnums());

  const [clauseInfoQuery] = useLazyQuery(getClauseInfo());

  const [clauseAction] = useMutation(
    clauseId ? updateClause() : createClause()
  );

  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();

  const [checkClauseExistsLazy] = useLazyQuery(checkClauseExists(), {});

  const initialize = async () => {
    setLoading(true);
    const inputs = cloneDeep(inputsForm);

    const clauseEnums = await loadClauseEnums();
    const newClauseEnums = LookupToList(clauseEnums);

    (inputs.lineName as IFormSelectDynamicProps).selectOptions =
      newClauseEnums['lines'];

    (inputs.clauseStatus as IFormSelectDynamicProps).selectOptions =
      newClauseEnums['PlanConfigManagement_ClauseStatuses'];

    if (isLineFieldEnabled) {
      inputs.lineName.disabled = false;
    } else {
      inputs.lineName.disabled = true;
    }

    inputs.clauseName.value = '';
    inputs.clauseExternalCode.value = '';
    inputs.clauseDescription.value = '';
    inputs.clauseStatus.value = 'INACTIVE';

    inputs.lineName.value = lineId || '';

    if (clauseId) {
      const data = await loadClauseInfo();
      if (data.data) {
        const clauseEntity = graphqlEntityToClauseInfo(
          data.data,
          lineId,
          lineName
        );
        if (clauseEntity) {
          inputs.lineName.value = clauseEntity.lineId;
          inputs.clauseName.value = clauseEntity.clauseName;
          inputs.clauseExternalCode.value = clauseEntity.clauseExternalCode;
          inputs.clauseDescription.value = clauseEntity.clauseDescription;
          inputs.clauseStatus.value = clauseEntity.clauseStatus;
        }
      }
    }

    setInputsForm(inputs);
    setLoading(false);
  };

  const loadClauseEnums = async () => {
    const result = await clauseEnumsQuery({
      fetchPolicy: 'no-cache',
    });

    return result.data;
  };

  const loadClauseInfo = async () => {
    const result = await clauseInfoQuery({
      variables: { id: clauseId },
    });
    return result;
  };

  useEffect(() => {
    initialize();
  }, []);

  const submitForm = async (values: Record<string, any>) => {
    const [data] = normaliseDynamicValues(inputs, values);
    setFormDisabled(true);
    setSubmitButtonState('loading');

    try {
      const variables = {
        clauseInputs: {
          lineID: lineId || values.lineName,
          externalCode: data.clauseExternalCode,
          name: data.clauseName,
          description: data.clauseDescription,
          status: data.clauseStatus,
        },
      };

      const response = await clauseAction({
        variables: clauseId ? { ...variables, entityId: clauseId } : variables,
        errorPolicy: 'all',
      });

      if (isEmpty(response.errors)) {
        toast.success(
          <ToastSuccessMessage>
            {clauseId
              ? 'Clause successfully updated'
              : 'Clause successfully created'}
          </ToastSuccessMessage>
        );

        setTimeout(() => {
          setSubmitButtonState('success');
          onSuccess();
          onClose();
        }, 500);
      } else {
        setSubmitButtonState(undefined);
        toast.error(
          <ToastErrorMessage>{getError(response)}</ToastErrorMessage>
        );
      }
    } catch (error) {
      setSubmitButtonState(undefined);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setFormDisabled(false);
    }
  };

  const validateClauseNameExistsOnLineChange = async (
    selectedLineId?: string,
    clauseName?: string
  ) => {
    if (!isEmpty(selectedLineId) && !isEmpty(clauseName)) {
      const result = await checkClauseExistsLazy({
        variables: {
          lineId: selectedLineId,
          name: clauseName,
          clauseId: clauseId,
          externalCode: '',
        },
      });

      if (
        result.data.PlanConfigManagement?.queries?.checkClauseExists.length > 0
      ) {
        return 'Clause Name already exists under the same Line';
      }
    }

    return '';
  };

  const validateClauseExternalCodeExistsOnLineChange = async (
    selectedLineId?: string,
    clauseExternalCode?: string
  ) => {
    if (!isEmpty(selectedLineId) && !isEmpty(clauseExternalCode)) {
      const result = await checkClauseExistsLazy({
        variables: {
          lineId: selectedLineId,
          externalCode: clauseExternalCode,
          clauseId: clauseId || '',
          name: '',
        },
      });

      if (
        result?.data?.PlanConfigManagement?.queries?.checkClauseExists.length >
        0
      ) {
        return 'Clause External Code already exists under the same Line';
      }
    }

    return '';
  };

  const onChange = async (
    fieldName: string,
    value: any,
    values: Record<string, any>,
    errors: Record<string, string>,
    touched: Record<string, boolean>
  ) => {
    if (fieldName === 'lineName') {
      values.lineName = value;
      const [clauseExternalCodeError, clauseNameError] = await Promise.all([
        onCustomValidate('clauseExternalCode', values),
        onCustomValidate('clauseName', values),
      ]);
      errors.clauseExternalCode = clauseExternalCodeError;
      errors.clauseName = clauseNameError;

      touched.clauseExternalCode = true;
      touched.clauseName = true;
    }
  };

  const onCustomValidate = async (
    fieldName: string,
    values: Record<string, any>
  ) => {
    if (fieldName === 'clauseExternalCode') {
      return await validateClauseExternalCodeExistsOnLineChange(
        values.lineName,
        values[fieldName]
      );
    }

    if (fieldName === 'clauseName') {
      return await validateClauseNameExistsOnLineChange(
        values.lineName,
        values[fieldName]
      );
    }

    return '';
  };

  const onCustomBlur = async (
    fieldName: string,
    values: Record<string, any>,
    errors: Record<string, string>
  ) => {
    if (fieldName === 'clauseExternalCode' || fieldName === 'clauseName') {
      if (!errors[fieldName]) {
        errors[fieldName] = await onCustomValidate(fieldName, values);
      }
    }
    return { values, errors };
  };

  const onCustomValidateForm = async (
    values: Record<string, any>,
    errors: Record<string, string>
  ) => {
    if (!errors.clauseExternalCode) {
      errors.clauseExternalCode = await onCustomValidate(
        'clauseExternalCode',
        values
      );
    }

    if (!errors.clauseName) {
      errors.clauseName = await onCustomValidate('clauseName', values);
    }

    return errors;
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <DynamicForm
      inputs={inputsForm}
      onChange={onChange}
      onSubmit={(values) => submitForm(values)}
      buttonText={'Submit'}
      onCustomValidate={onCustomValidateForm}
      onCustomBlur={onCustomBlur}
      submitButtonState={submitButtonState}
      disableForm={formDisabled || submitButtonState === 'loading'}
      title=""
      popUpStyling={true}
    />
  );
};

export default ClausePopUp;
