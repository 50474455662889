import React, { useState } from 'react';
import StaticLayout from '../../../../page-layout/static-layout/StaticLayout';
import ListingFilterWidget from '../../../../components/widgets/custom-listing-filter/ListingFilterWidget';
import { IListingFilterWidgetSection } from '../../../../components/widgets/custom-listing-filter';
import { toast } from 'react-toastify';
import ToastErrorMessage from '../../../../components/ToastErrorMessage';
import { DEFAULT_ERROR_TEXT, MAIN_ONE_THEME } from '../../../../constants';
import ListingWidget from '../../../../components/form-fields/listing-widget/ListingWidget';
import { getFilter, setFilter } from '../../../../utils/filter-utils';
import { IFilterModel } from '../../../../components/form-fields/listing-widget';
import { IListingData } from '../../../../models/listing';
import { filterSectionsContent, headers } from './content';
import { IAbstractRecord } from '../../../../models';
import { useLazyQuery } from '@apollo/client';
import { getTreaties, getTreatyAuditTrailsQuery } from './queries';
import {
  convertAuditTrailsToActivities,
  mapToListingData,
  toLookupsData,
} from './utils';
import { IEnhancedMenuItem } from '../../../../components/form-fields/table';
import { isEmpty } from 'lodash';
import { capitalizeFirstCharacter } from '../../../../utils/formatting-utils';
import NewTreatyDrawer from '../new-treaty-drawer/NewTreatyDrawer';
import CustomActivities from '../../../../activities/CustomActivities';
import { IEnhanceTableHeaderClickable } from '../../../../components/enhanced-table';
import { getError } from '../../../../utils/graph-utils';
import { IActivityEntityBase } from '../../../../activities';

const PAGE_CONTEXT = 'Treaties';
const FILTER_SESSION_KEY = 'treatiesFilter';

const TreatiesListingPage = () => {
  const [isTreatyDrawerOpen, setIsTreatyDrawerOpen] = useState(false);
  const [filterSections, setFilterSections] =
    useState<IListingFilterWidgetSection[]>();
  const initialfilterValues =
    Object.keys(getFilter(FILTER_SESSION_KEY)).length > 0
      ? getFilter(FILTER_SESSION_KEY)
      : {
          treatyType: [],
          leaderReinsurer: [],
          currency: [],
          effectiveDate: null,
          status: null,
        };
  const [filterValues, setFilterValues] = useState<IFilterModel>({
    namedFilters: initialfilterValues,
    pagination: {
      pageSize: 10,
      pageNumber: 1,
    },
  });
  const [tableData, setTableData] = useState<
    IListingData & {
      lovs: Record<string, Record<string, string>>;
    }
  >({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 1,
    totalCount: 0,
    lovs: {},
  });
  const [auditTrailInfo, setAuditTrailInfo] = useState<{
    title: string;
    items: IActivityEntityBase[];
  }>();

  const [getTreatiesLazy, { loading: treatiesLoading }] =
    useLazyQuery(getTreaties);
  const [getTreatyAuditTrails, { loading: auditTrailsLoading }] = useLazyQuery(
    getTreatyAuditTrailsQuery
  );

  const viewHistoryClick = async (id: string, title: string) => {
    try {
      const result = await getTreatyAuditTrails({
        variables: {
          TreatyID: id,
        },
      });

      if (result.data) {
        setAuditTrailInfo({
          items: convertAuditTrailsToActivities(result.data),
          title,
        });
      }
    } catch (error) {
      toast.error(<ToastErrorMessage>{getError(error)}</ToastErrorMessage>);
    }
  };

  function handlePageChange(
    page: number,
    filterModel = filterValues
  ): Promise<void> {
    return new Promise<void>(() =>
      getListingData({
        ...filterModel,
        pagination: {
          ...filterModel.pagination,
          pageNumber: page,
        },
      })
    );
  }

  const onFilterUpdate = async (filters: IAbstractRecord) => {
    const newFilters = {
      ...filterValues,
      namedFilters: filters,
    };
    setFilter(filters, FILTER_SESSION_KEY);
    setFilterValues(newFilters);
    getListingData(newFilters);
  };

  const getValueOrNull = (value: unknown) => (!isEmpty(value) ? value : null);

  const getVariables = (filter = filterValues) => {
    return {
      SelectedCompany: '41',
      TreatyType: getValueOrNull(filter.namedFilters.treatyType),
      LeaderReinsurer: getValueOrNull(filter.namedFilters.leaderReinsurer),
      Currency: getValueOrNull(filter.namedFilters.currency),
      EffectiveFromDate: getValueOrNull(filter.namedFilters.effectiveDate?.[0]),
      EffectiveToDate: getValueOrNull(filter.namedFilters.effectiveDate?.[1]),
      Status: !isEmpty(filter.namedFilters.status)
        ? capitalizeFirstCharacter(filter.namedFilters.status.toLowerCase())
        : null,
      KeywordSearch: filter.searchKey,
      OrderByField: filter.orderBy,
      DescendingField: filter.descending,
      pagination: {
        pageSize: filter.pagination.pageSize,
        pageNumber: filter.pagination.pageNumber,
      },
    };
  };

  const getListingData = (filter = filterValues) => {
    if (!filter) {
      return {};
    }
    const variables = getVariables(filter);
    return getTreatiesLazy({
      variables: variables,
    })
      .then(({ data }) => {
        if (data) {
          const lovs = toLookupsData(data);

          if (!Object.keys(lovs.treatyType).length) {
            lovs.treatyType = filter.namedFilters.treatyType;
          }

          if (!Object.keys(lovs.leaderReinsurer).length) {
            lovs.leaderReinsurer = filter.namedFilters.leaderReinsurer;
          }

          if (!Object.keys(lovs.currencyTitles).length) {
            lovs.currencyTitles = filter.namedFilters.currencyTitles;
          }

          if (!Object.keys(lovs.currencySymbols).length) {
            lovs.currencySymbols = filter.namedFilters.currencySymbols;
          }

          if (!Object.keys(lovs.status).length) {
            lovs.status = filter.namedFilters.status;
          }

          const newFilterSections = filterSectionsContent(
            lovs,
            filter.namedFilters
          );
          setFilterSections(newFilterSections);
          setFilterValues(filter);

          const tableData = mapToListingData(
            data?.Reinsurance?.queries?.TreatiesList,
            lovs.treatyType
          );

          setTableData({
            ...tableData,
            pageNumber: tableData.pageNumber,
            pageSize: tableData.pageSize,
            totalCount: tableData.totalCount,
            lovs,
          });
        }
      })
      .catch(() => {
        toast.error(
          <ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>
        );
      });
  };

  (headers.viewHistory as IEnhanceTableHeaderClickable).callback = (
    payload: IAbstractRecord
  ) => {
    const id = payload.columns.treatyId;
    viewHistoryClick(id, payload.columns.Reinsurance_Treaties_TreatyName);
  };

  const actions: IEnhancedMenuItem[] = [
    {
      name: 'newTreaty',
      title: '+ New',
      isPrimary: false,
      onClick: () => {
        setIsTreatyDrawerOpen(true);
      },
    },
  ];

  const renderFilter = () => {
    return (
      filterSections && (
        <ListingFilterWidget
          name={''}
          filters={filterSections}
          onApplyFilter={(v) => {
            onFilterUpdate(v);
          }}
        ></ListingFilterWidget>
      )
    );
  };

  const renderMainChildren = () => {
    return (
      <>
        <ListingWidget
          name="treaties"
          title="Treaties"
          orderByAscendingByDefault
          loading={treatiesLoading}
          data={tableData}
          tableSettings={{
            headers: headers,
          }}
          actions={actions}
          pageContext={PAGE_CONTEXT}
          onPageChange={(filterModel) => {
            const newFilterModel = {
              ...filterValues,
              ...filterModel,
            };
            const page = filterModel.pagination.pageNumber;
            return handlePageChange(page, newFilterModel);
          }}
          disableSelection
          usePagination={true}
        />
        {isTreatyDrawerOpen && (
          <NewTreatyDrawer
            isOpen={isTreatyDrawerOpen}
            onClose={() => {
              setIsTreatyDrawerOpen(false);
            }}
            lovs={tableData.lovs}
          />
        )}
      </>
    );
  };

  return (
    <StaticLayout
      name="Treaties"
      leftChildren={renderFilter()}
      mainChildren={renderMainChildren()}
      rightChildren={
        <CustomActivities
          loader={auditTrailsLoading}
          title={auditTrailInfo?.title || ''}
          items={auditTrailInfo?.items || []}
        />
      }
      onRightCollapseClick={() => {
        if (auditTrailInfo) {
          setAuditTrailInfo(null);
        }
      }}
      config={{
        leftColumn: {
          backgroundColor: '#FFFFFF',
          collapsable: false,
          collapsed: false,
          width: 2,
        },
        mainColumn: {
          backgroundColor: MAIN_ONE_THEME.palette.secondary4.main,
          collapsable: false,
          collapsed: false,
          width: auditTrailInfo ? 7 : 10,
        },
        rightColumn: {
          backgroundColor: MAIN_ONE_THEME.palette.secondary4.main,
          collapsable: true,
          collapsed: !auditTrailInfo,
          width: auditTrailInfo ? 3 : 0,
        },
      }}
    />
  );
};

export default TreatiesListingPage;
