// import { ITheme } from "./redux/tenant/types";

export const HEADER_HEIGHT = 60;

export const EMPTY_FIELD_PLACEHOLDER = '- -';
export const DEFAULT_ERROR_TEXT = 'A Problem Occurred';

export const primary = {
  light: '#CCCCCC',
  main: '#AAAAAA',
  dark: '#777777',
};
export const primary1 = {
  main: '#000',
};
export const primary2 = {
  main: '#000',
  lighter: '#5A5A5A',
};
export const primary3 = {
  main: '#000',
};
export const primary4 = {
  main: '#000',
};
export const primary5 = {
  main: '#000',
};
const secondary = {
  light: '#CCCCCC',
  main: '#AAAAAA',
  dark: '#777777',
};
const secondary1 = {
  main: '#5A5A5A',
};
const secondary2 = {
  main: '#CECECE',
};
const secondary3 = {
  main: '#E5E5E5',
  light: '#DBDBDB',
};
const secondary4 = {
  main: '#f9f9f9',
  light: 'rgba(247, 247, 247, 0.59)',
  lighter: 'rgba(247, 247, 247, 0.49)',
};
const secondary5 = {
  main: '#f7f7f7',
};
const error = {
  main: '#D30D2B',
};
const info = {
  light: '#CCCCCC',
  main: '#AAAAAA',
  dark: '#777777',
};
const sm1 = {
  fontSize: 10,
};
const sm2 = {
  fontSize: 11,
};
const sm3 = {
  fontSize: 12,
};
const reg1 = {
  fontSize: 13,
};
const reg2 = {
  fontSize: 14,
};
const reg3 = {
  fontSize: 16,
};
const reg4 = {
  fontSize: 18,
};
const med1 = {
  fontSize: 21,
};
const med2 = {
  fontSize: 22,
};
const big1 = {
  fontSize: 30,
};
const big2 = {
  fontSize: 32,
};

export const MAIN_ONE_THEME = {
  palette: {
    primary,
    primary1,
    primary2,
    primary3,
    primary4,
    primary5,
    secondary,
    secondary1,
    secondary2,
    secondary3,
    secondary4,
    secondary5,
    error,
    info,
  },
  typography: {
    small: {
      sm1,
      sm2,
      sm3,
    },
    regular: {
      reg1,
      reg2,
      reg3,
      reg4,
    },
    medium: {
      med1,
      med2,
    },
    big: {
      big1,
      big2,
    },
  },
};

export const mainFontFamilyRegular = 'HelveticaNeue-Regular';
export const mainFontFamilyMedium = 'HelveticaNeue-Medium';
export const mainFontFamilyBold = 'HelveticaNeue-Bold';

export const contentFontFamilyRegular = 'SourceSansPro-Regular';
export const contentFontFamilyBold = 'SourceSansPro-Bold';
export const contentFontFamilyRegularImportant =
  'SourceSansPro-Regular !important';
export const contentFontFamilyBoldImportant = 'SourceSansPro-Bold !important';

export const DISPLAY_DATE_FORMAT = 'DD/MM/YYYY';

export const SEND_TO_BACKEND_DATE_FORMAT = 'YYYY-MM-DD';
export const SEND_TO_BACKEND_DATE_FORMAT_WITH_SLASH = 'YYYY/MM/DD';

export const TinyMCEApiKey = '1zpxcry8s9a6chrmtwzhr9g4fpr8m1fywjo4eq76vdj33per';
