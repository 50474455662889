import React, { useState } from 'react';
import StaticLayout from '../../../../page-layout/static-layout/StaticLayout';
import ListingFilterWidget from '../../../../components/widgets/custom-listing-filter/ListingFilterWidget';
import { IListingFilterWidgetSection } from '../../../../components/widgets/custom-listing-filter';
import { toast } from 'react-toastify';
import ToastErrorMessage from '../../../../components/ToastErrorMessage';
import { DEFAULT_ERROR_TEXT } from '../../../../constants';
import ListingWidget from '../../../../components/form-fields/listing-widget/ListingWidget';
import { getFilter, setFilter } from '../../../../utils/filter-utils';
import { IFilterModel } from '../../../../components/form-fields/listing-widget';
import { IListingData } from '../../../../models/listing';
import { filterSectionsContent, headers } from './content';
import { IAbstractRecord } from '../../../../models';
import { useLazyQuery } from '@apollo/client';
import { getReinsurers } from './queries';
import { mapToListingData, toLookupsData } from './utils';
import { isEmpty } from 'lodash';
import { capitalizeFirstCharacter } from '../../../../utils/formatting-utils';

const PAGE_CONTEXT = 'Reinsurers';
const FILTER_SESSION_KEY = 'reinsurersFilter';

const ReinsurersListingPage = () => {
  const [loadingState, setLoadingState] = useState<boolean>(false);
  const [filterSections, setFilterSections] =
    useState<IListingFilterWidgetSection[]>();
  const initialFilterValues =
    Object.keys(getFilter(FILTER_SESSION_KEY)).length > 0
      ? getFilter(FILTER_SESSION_KEY)
      : {
          reinsurerType: [],
          isLocal: null,
          status: [],
        };
  const [filterValues, setFilterValues] = useState<IFilterModel>({
    namedFilters: initialFilterValues,
    pagination: {
      pageSize: 10,
      pageNumber: 1,
    },
  });
  const [tableData, setTableData] = useState<
    IListingData & {
      lovs: Record<string, Record<string, string>>;
    }
  >({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 1,
    totalCount: 0,
    lovs: {},
  });

  const [getReinsurersLazy] = useLazyQuery(getReinsurers);

  function handlePageChange(
    page: number,
    filterModel = filterValues
  ): Promise<void> {
    return new Promise<void>(() =>
      getListingData({
        ...filterModel,
        pagination: {
          ...filterModel.pagination,
          pageNumber: page,
        },
      })
    );
  }

  const onFilterUpdate = async (filters: IAbstractRecord) => {
    const newFilters = {
      ...filterValues,
      namedFilters: filters,
    };
    setFilter(filters, FILTER_SESSION_KEY);
    setFilterValues(newFilters);
    getListingData(newFilters);
  };

  const getListingData = (filter = filterValues) => {
    if (!filter) {
      setLoadingState(false);
      return {};
    }

    setLoadingState(true);
    return getReinsurersLazy({
      variables: {
        SelectedCompany: '41',
        SelectedReinsurerType: filter.namedFilters.reinsurerType,
        IsLocal: !isEmpty(filter.namedFilters.isLocal)
          ? filter.namedFilters.isLocal === 'true'
          : null,
        Status: filter.namedFilters.status.map((item: string) =>
          capitalizeFirstCharacter(item.toLowerCase())
        ),
        KeywordSearch: !isEmpty(filter.searchKey) ? filter.searchKey : null,
        OrderByField: filter.orderBy,
        DescendingField: filter.descending,
        pagination: {
          pageSize: filter.pagination.pageSize || 10,
          pageNumber: filter.pagination.pageNumber || 1,
        },
      },
    })
      .then(({ data }) => {
        if (data) {
          const lovs = toLookupsData(data);

          if (!Object.keys(lovs.reinsurerType).length) {
            lovs.reinsurerType = filter.namedFilters.reinsurerType;
          }

          if (!Object.keys(lovs.status).length) {
            lovs.status = filter.namedFilters.status;
          }

          const newFilterSections = filterSectionsContent(
            lovs,
            filter.namedFilters
          );
          setFilterSections(newFilterSections);
          setFilterValues(filter);

          const tableData = mapToListingData(
            data?.Reinsurance?.queries?.ReinsurersList,
            lovs
          );

          setTableData({
            ...tableData,
            pageNumber: tableData.pageNumber,
            pageSize: tableData.pageSize,
            totalCount: tableData.totalCount,
            lovs,
          });
        }
      })
      .catch(() => {
        toast.error(
          <ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>
        );
      })
      .finally(() => {
        setLoadingState(false);
      });
  };

  const renderFilter = () => {
    return (
      filterSections && (
        <ListingFilterWidget
          name={''}
          filters={filterSections}
          onApplyFilter={(v) => {
            onFilterUpdate(v);
          }}
        ></ListingFilterWidget>
      )
    );
  };

  const renderMainChildren = () => {
    return (
      <ListingWidget
        name="reinsurers"
        title="Reinsurers"
        orderByAscendingByDefault
        loading={loadingState}
        data={tableData}
        tableSettings={{
          headers,
        }}
        actions={[]}
        pageContext={PAGE_CONTEXT}
        onPageChange={(filterModel) => {
          const newFilterModel = {
            ...filterValues,
            ...filterModel,
          };
          const page = filterModel.pagination.pageNumber;
          return handlePageChange(page, newFilterModel);
        }}
        disableSelection
        usePagination={true}
      />
    );
  };

  return (
    <StaticLayout
      name="Reinsurers"
      leftChildren={renderFilter()}
      mainChildren={renderMainChildren()}
    />
  );
};

export default ReinsurersListingPage;
