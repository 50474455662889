import {
  EnhancedTableHeader,
  EnhancedTableHeaderType,
} from '../../../../../../../components/enhanced-table';

export const getHeaders = (
  currency?: string
): Record<string, EnhancedTableHeader> => ({
  insured: {
    name: 'insured',
    title: 'Insured',
    type: EnhancedTableHeaderType.Text,
  },
  relation: {
    name: 'relation',
    title: 'Relation',
    type: EnhancedTableHeaderType.Text,
  },
  age: {
    name: 'age',
    title: 'Age',
    type: EnhancedTableHeaderType.Text,
  },
  sumInsured: {
    name: 'sumInsured',
    title: 'Sum Insured',
    type: EnhancedTableHeaderType.Currency,
    currency: currency,
  },
  netPremium: {
    name: 'netPremium',
    title: 'Net Premium',
    type: EnhancedTableHeaderType.Currency,
    currency: currency,
  },
  cost: {
    name: 'cost',
    title: 'Cost',
    type: EnhancedTableHeaderType.Currency,
    currency: currency,
  },
  charges: {
    name: 'charges',
    title: 'Charges',
    type: EnhancedTableHeaderType.Currency,
    currency: currency,
  },
  tpaFees: {
    name: 'tpaFees',
    title: 'TPA Fees',
    type: EnhancedTableHeaderType.Currency,
    currency: currency,
  },
  grossPremium: {
    name: 'grossPremium',
    title: 'Gross Premium',
    type: EnhancedTableHeaderType.Currency,
    currency: currency,
  },
  fixedStamp: {
    name: 'fixedStamp',
    title: 'Fixed Stamp',
    type: EnhancedTableHeaderType.Currency,
    currency: currency,
  },
  propStamp: {
    name: 'propStamp',
    title: 'Prop Stamp',
    type: EnhancedTableHeaderType.Currency,
    currency: currency,
  },
  munTax: {
    name: 'munTax',
    title: 'Mun Tax',
    type: EnhancedTableHeaderType.Currency,
    currency: currency,
  },
  totalPremium: {
    name: 'totalPremium',
    title: 'Total Premium',
    type: EnhancedTableHeaderType.Currency,
    currency: currency,
  },
});

export const excelHeaders = (currencySymbol?: string) => ({
  insured: {
    label: 'Insured',
    visible: true,
    isDate: false,
    isCurrency: false,
    currencySymbol,
  },
  relation: {
    label: 'Relation',
    visible: true,
    isDate: false,
    isCurrency: false,
    currencySymbol,
  },
  age: {
    label: 'Age',
    visible: true,
    isDate: false,
    isCurrency: false,
    currencySymbol,
  },
  cover: {
    label: 'Cover',
    visible: true,
    isDate: false,
    isCurrency: false,
    currencySymbol,
  },
  class: {
    label: 'Class',
    visible: true,
    isDate: false,
    isCurrency: false,
    currencySymbol,
  },
  nssf: {
    label: 'Co-NSSF',
    visible: true,
    isDate: false,
    isCurrency: false,
    currencySymbol,
  },
  level: {
    label: 'Level',
    visible: true,
    isDate: false,
    isCurrency: false,
    currencySymbol,
  },
  sumInsured: {
    label: `SumInsured - ${currencySymbol}`,
    visible: true,
    isDate: false,
    isCurrency: false,
  },
  additiveSumInsured: {
    label: 'Additive SumInsured',
    visible: true,
    isDate: false,
    isCurrency: false,
    currencySymbol,
  },
  netPremium: {
    label: `Net Premium - ${currencySymbol}`,
    visible: true,
    isCurrency: false,
    isDate: false,
  },
  cost: {
    label: `Cost - ${currencySymbol}`,
    visible: true,
    isDate: false,
    isCurrency: false,
  },
  charges: {
    label: `Charges - ${currencySymbol}`,
    visible: true,
    isDate: false,
    isCurrency: false,
  },
  tpaFees: {
    label: `TPA Fees - ${currencySymbol}`,
    visible: true,
    isDate: false,
    isCurrency: false,
  },
  grossPremium: {
    label: `Gross Premium - ${currencySymbol}`,
    visible: true,
    isDate: false,
    isCurrency: false,
  },
  fixedStamp: {
    label: `Fixed Stamp - ${currencySymbol}`,
    visible: true,
    isDate: false,
    isCurrency: false,
  },
  propStamp: {
    label: `Prop Stamp - ${currencySymbol}`,
    visible: true,
    isDate: false,
    isCurrency: false,
  },
  munTax: {
    label: `Mun Tax - ${currencySymbol}`,
    visible: true,
    isDate: false,
    isCurrency: false,
  },
  totalPremium: {
    label: `Total Premium - ${currencySymbol}`,
    visible: true,
    isDate: false,
    isCurrency: false,
  },
});
