import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { makeStyles } from 'tss-react/mui';
import { IEnhancedCommonProps } from '../..';
import { Paper } from '@mui/material';
import EnhancedLoader from './EnhancedLoader';
import {
  contentFontFamilyBold,
  contentFontFamilyRegular,
  MAIN_ONE_THEME,
} from '../../../constants';

export interface IDialogFormTemplateProps extends IEnhancedCommonProps {
  title?: string;
  inlineTitle?: string;
  description?: string;
  footer?: JSX.Element;
  children?: ReactNode;
  onClose: () => void;
  contentClass?: string;
  classes?: {
    title?: string;
    inlineTitle?: string;
    description?: string;
    paperContent?: string;
  };
}

const useStyles = makeStyles()(() => ({
  templateContainer: {
    width: '90%',
    margin: 'auto',
    paddingBottom: '1px',
  },
  header: {
    margin: '0 auto 40px',
  },
  title: {
    textAlign: 'center',
    font: contentFontFamilyBold,
    fontSize: '22px',
    lineHeight: '27px',
    color: MAIN_ONE_THEME.palette.primary1.main,
    margin: '0 auto',
  },
  inlineTitle: {
    alignSelf: 'center',
    margin: '0 0 0 10px',
  },
  description: {
    textAlign: 'center',
    fontFamily: contentFontFamilyRegular,
    fontSize: '14px',
    lineHeight: '16px',
    color: MAIN_ONE_THEME.palette.primary1.main,
    margin: '15px auto 0',
  },
  paperRoot: {
    width: '100%',
    minHeight: '600px',
  },
  paperHeader: {
    textAlign: 'right',
    height: 65,
    padding: 10,
    boxSizing: 'border-box',
  },
  paperHeaderWithTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    height: 65,
    padding: 10,
    boxSizing: 'border-box',
  },
  paperDismiss: {
    background: 'rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box',
    border: `1px solid #DFE3EB`,
    borderRadius: '5px',
    height: 35,
    width: 43,
    cursor: 'pointer',
    color: '#07295A',
    fontSize: '21px',
    fontFamily: 'HelveticaNeue-Regular',
  },
  paperContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    padding: '25px 50px 50px',
  },
  paperFigure: {
    width: '40%',
  },
  paperChildren: {
    width: '60%',
  },
  body: {
    width: '100%',
  },
  loaderContainer: {
    textAlign: 'center',
  },
  loader: {
    width: '80px',
    height: '80px',
  },
}));

const DialogFormTemplate: React.FC<IDialogFormTemplateProps> = ({
  title,
  inlineTitle,
  description,
  footer,
  loader,
  children,
  onClose,
  className,
  style,
  contentClass,
  classes = {},
}) => {
  const { classes: templateClasses } = useStyles();
  return (
    <section
      className={clsx(templateClasses.templateContainer, className)}
      style={style}
    >
      {(title || description) && (
        <header className={templateClasses.header}>
          {title && <h4 className={templateClasses.title}>{title}</h4>}
          {description && (
            <p
              className={clsx(templateClasses.description, classes.description)}
            >
              {description}
            </p>
          )}
        </header>
      )}
      <Paper
        classes={{ root: templateClasses.paperRoot }}
        className={contentClass}
      >
        {inlineTitle ? (
          <div className={templateClasses.paperHeaderWithTitle}>
            <div
              className={clsx(
                templateClasses.title,
                templateClasses.inlineTitle,
                classes.inlineTitle
              )}
            >
              {inlineTitle}
            </div>
            <button
              className={templateClasses.paperDismiss}
              onClick={onClose}
              type="button"
              name={'Close'}
            >
              X
            </button>
          </div>
        ) : (
          <div className={templateClasses.paperHeader}>
            <button
              className={templateClasses.paperDismiss}
              onClick={onClose}
              type="button"
              name={'Close'}
            >
              X
            </button>
          </div>
        )}

        <div
          className={clsx(templateClasses.paperContent, classes.paperContent)}
        >
          <div className={templateClasses.body}>
            {loader ? (
              <div className={templateClasses.loaderContainer}>
                <EnhancedLoader className={templateClasses.loader} />
              </div>
            ) : (
              children
            )}
          </div>
        </div>
      </Paper>
      {footer}
    </section>
  );
};

export default DialogFormTemplate;
