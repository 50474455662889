import { gql } from '@apollo/client';

export function getProposalsEnums() {
  return gql`
    query getProposalsEnums {
      Core {
        lookups {
          currencies {
            Id
            Title
          }
        }
      }
    }
  `;
}

export function updatePlanAndAdditionalDetails() {
  return gql`
    mutation updatePlanDetails(
      $entityId: String!
      $planDetailInputs: PlanConfigManagement_PlanDetailInputs!
      $planAdditionalDetailInputs: PlanConfigManagement_PlanAdditionalDetailInputs!
    ) {
      planConfigManagement {
        entities {
          plan {
            planConfigManagement {
              updatePlanAndAdditionalDetails(
                entityId: $entityId
                planDetailInputs: $planDetailInputs
                planAdditionalDetailInputs: $planAdditionalDetailInputs
              ) {
                id
                views {
                  PlanConfigManagement_all {
                    properties {
                      Name
                      ExternalCode
                      NameArabic
                      NameFrench
                      Abbreviation
                      EffectiveFrom
                      EffectiveTo
                      PlanStatus
                      SublineID {
                        id
                        views {
                          PlanConfigManagement_all {
                            properties {
                              Name
                              ExternalCode
                              __typename
                            }
                            __typename
                          }
                          __typename
                        }
                        __typename
                      }
                      LineID {
                        id
                        views {
                          PlanConfigManagement_all {
                            properties {
                              Name
                              ExternalCode
                              __typename
                            }
                            __typename
                          }
                          __typename
                        }
                        __typename
                      }
                      createdOn
                      createdBy
                      modifiedOn
                      modifiedBy
                      __typename
                    }
                    __typename
                  }
                  __typename
                }
                __typename
              }
              __typename
            }
            __typename
          }
          __typename
        }
        __typename
      }
    }
  `;
}

export function fetchedAssignedBPList() {
  return gql`
    query fetchedAssignedBPList(
      $selectedBusinessUserID: String!
      $searchKeyword: String
    ) {
      SalesforceManagement {
        queries {
          getAssignedBPListWithoutPagination(
            selectedBusinessUserID: $selectedBusinessUserID
            status: ACTIVE
            keywordSearch: $searchKeyword
          ) {
            salesforceManagement_AssignedBP_Id
            businessPartner_Id
            businessPartner_PersonID {
              id
              views {
                SalesforceManagement_all {
                  properties {
                    FullName
                  }
                }
              }
            }
            salesforceManagement_AssignedBP_BusinessUserID {
              id
            }
            salesforceManagement_AssignedBP_BusinessPartnerID {
              id
              views {
                SalesforceManagement_all {
                  properties {
                    Code
                    PersonID {
                      id
                      views {
                        SalesforceManagement_all {
                          properties {
                            FullName
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function fetchedAssignedBPListWithPagination() {
  return gql`
    query fetchedAssignedBPList(
      $selectedBusinessUserID: String!
      $searchKeyword: String
      $pagination: PaginationInput!
    ) {
      SalesforceManagement {
        queries {
          getAssignedBPList(
            selectedBusinessUserID: $selectedBusinessUserID
            status: ACTIVE
            keywordSearch: $searchKeyword
            pagination: $pagination
          ) {
            paging {
              pageNumber
              pageSize
              totalCount
            }
            items {
              salesforceManagement_AssignedBP_Id
              businessPartner_Id
              businessPartner_PersonID {
                id
                views {
                  SalesforceManagement_all {
                    properties {
                      FullName
                    }
                  }
                }
              }
              salesforceManagement_AssignedBP_BusinessUserID {
                id
              }
              salesforceManagement_AssignedBP_BusinessPartnerID {
                id
                views {
                  SalesforceManagement_all {
                    properties {
                      Code
                      PersonID {
                        id
                        views {
                          SalesforceManagement_all {
                            properties {
                              FullName
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function getActiveAssignedBPPlanList() {
  return gql`
    query getActiveAssignedBPPlanList(
      $selectedBusinessUserID: String!
      $selectedBusinessPartnerID: String
      $selectedLineID: String
      $selectedPlanID: String
      $selectedSublineID: String
    ) {
      SalesforceManagement {
        queries {
          getActiveAssignedBPPlanList(
            pagination: { pageNumber: 1, pageSize: 100 }
            selectedBusinessUserID: $selectedBusinessUserID
            selectedBusinessPartnerID: $selectedBusinessPartnerID
            selectedLineID: $selectedLineID
            selectedPlanID: $selectedPlanID
            selectedSublineID: $selectedSublineID
          ) {
            items {
              salesforceManagement_AssignedBPPlan_Id
              person_Id
              person_FullName
              plan_Id
              plan_EffectiveFrom
              plan_EffectiveTo
              plan_PlanStatus
              subline_Id
              subline_Status
              businessPartnerPlan_Id
              businessPartnerPlan_ActiveFrom
              businessPartnerPlan_ActiveTo
              line_Id
              line_Status
              businessPartner_Id
              salesforceManagement_AssignedBPPlan_BusinessUserID {
                id
              }
              salesforceManagement_AssignedBPPlan_AssignedBusinessPartnerID {
                id
              }
              salesforceManagement_AssignedBPPlan_LineID {
                id
                views {
                  PlanConfigManagement_all {
                    properties {
                      ExternalCode
                      Name
                    }
                  }
                }
              }
              salesforceManagement_AssignedBPPlan_PlanID {
                id
                views {
                  PlanConfigManagement_all {
                    properties {
                      ExternalCode
                      Name
                      PlanCurrency {
                        Code
                      }
                      SublineID {
                        id
                        views {
                          PlanConfigManagement_all {
                            properties {
                              ExternalCode
                              Name
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function createProposal() {
  return gql`
    mutation createProposal(
      $lineID: String!
      $sublineID: String!
      $planID: String!
      $primaryBP: String!
      $primaryPercentage: Decimal!
      $secondaryBP: String
      $secondaryPercentage: Decimal
      $additionalBP: String
      $additionalPercentage: Decimal
      $policyCurrency: String!
      $selectedAssignedBPPlan: String!
      $isGroup: Boolean!
    ) {
      production {
        actions {
          createProposal(
            lineID: $lineID
            sublineID: $sublineID
            planID: $planID
            primaryBP: $primaryBP
            primaryPercentage: $primaryPercentage
            secondaryBP: $secondaryBP
            secondaryPercentage: $secondaryPercentage
            additionalBP: $additionalBP
            additionalPercentage: $additionalPercentage
            policyCurrency: $policyCurrency
            selectedAssignedBPPlan: $selectedAssignedBPPlan
            isGroup: $isGroup
          ) {
            id
          }
        }
      }
    }
  `;
}
