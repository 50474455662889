import React, { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';
import { MAIN_ONE_THEME } from '../../../../../constants';
import EnhancedStepperSpecificDesign from '../../../../../components/common/EnhancedStepperSpecificDesign';

interface IStepper {
  status: string;
  isButtonDisabled: boolean;
}

const useStyles = makeStyles()(() => ({
  stepperContainer: {
    margin: '20px 0',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    '&  .MuiGrid-container': {
      width: '80%',
      maxWidth: '1300px',
    },
  },
  stepper: {
    backgroundColor: 'transparent',
    maxWidth: '90%',
    marginBottom: '20px',
    '& .MuiStepLabel-root .Mui-active, .MuiStepLabel-root ': {
      color: MAIN_ONE_THEME.palette.primary5.main,
      '& .MuiStepIcon-text': {
        fontSize: 0,
        lineHeight: '0px',
        fill: 'black',
      },
    },

    '.Mui-completed': {
      color: MAIN_ONE_THEME.palette.primary5.main,
      '& .MuiStepIcon-text': {
        fontSize: 0,
        lineHeight: '0px',
        fill: 'black',
      },
    },

    '@media only screen and (min-width: 600px)': {
      maxWidth: '1300px',
    },
  },
}));

const Stepper: React.FC<IStepper> = ({ status, isButtonDisabled }) => {
  const { classes } = useStyles();

  const steps: string[] = ['New', 'Active', 'Expired/Closed'];

  const activeStep = useMemo(() => {
    const statusLower = status?.toLowerCase();
    switch (statusLower) {
      case 'new':
        return 'New';
      case 'active':
        return 'Active';
      case 'expired':
        return 'Expired/Closed';
      case 'closed':
        return 'Expired/Closed';
      default:
        return 'New';
    }
  }, [status]);

  return (
    <div className={classes.stepperContainer}>
      <EnhancedStepperSpecificDesign
        activeStep={activeStep}
        steps={steps}
        className={classes.stepper}
        showStepperButton
        buttonTitle="Activate"
        buttonOnClick={() => console.log('Activate')}
        buttonDisabled={activeStep !== 'New' || isButtonDisabled}
      />
    </div>
  );
};

export default Stepper;
