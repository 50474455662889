import React, { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { excelHeaders, getHeaders } from '.';
import { IEnhancedTableMenuItem } from '../../../../../../components/enhanced-table';
import { IListingData } from '../../../../../../models/listing';
import EnhancedTable from '../../../../../../components/enhanced-table/EnhancedTable';
import { downloadExcelFile } from '../../../../../../components/custom/download-json-excel-service';
import ListingTableWithPagination from '../../../../../../components/form-fields/table/ListingTableWithPagination';
import { IEnhancedMenuItem } from '../../../../../../components/form-fields/table';
import { getMarineGroupPolicyList } from '../../queries';
import { useLazyQuery } from '@apollo/client';
import ToastErrorMessage from '../../../../../../components/ToastErrorMessage';
import { toast } from 'react-toastify';
import { DEFAULT_ERROR_TEXT } from '../../../../../../constants';
import { mapToMarinePolicyListData } from '../../group-utils';

const useStyles = makeStyles()(() => ({
  paperClass: {
    marginTop: '1em',
  },
}));

interface IMarineGroupCertificateTableWidgetProps {
  className?: string;
  entityId: string;
  currenySymbol: string;
  onUpdateSuccess?: () => void;
}

export const MarineGroupCertificateTableWidget: React.FC<
  IMarineGroupCertificateTableWidgetProps
> = ({ entityId, currenySymbol }) => {
  const { classes } = useStyles();

  const [loading, setLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<IListingData>({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 1,
    totalCount: 0,
  });

  const [getData] = useLazyQuery(getMarineGroupPolicyList());

  const tableAction: IEnhancedMenuItem[] = [
    {
      title: 'Download',
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onClick: () => {
        downloadExcelFile({
          data: [],
          filename: 'Medical Premium Details',
          headers: excelHeaders(currenySymbol),
        });
      },
      disabled: false,
      name: 'download',
    },
  ];

  const loadData = async (
    currentPage: number,
    pageSize = tableData.pageSize
  ) => {
    setLoading(true);

    try {
      const result = await getData({
        variables: {
          id: entityId,
          currentPage: currentPage,
          currentPageSize: pageSize,
          type: 'CERTIFICATE',
        },
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
      });

      if (result.error) {
        toast.error(
          <ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>
        );
        return;
      }

      const data = result.data;

      const newTableData = mapToMarinePolicyListData(data);
      setTableData({
        ...newTableData,
      });
    } catch (error) {
      console.error('Error loading data:', error);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = async (
    page: number,
    searchKey?: string,
    rowsPerPage?: number,
    order?: string,
    orderBy?: string
  ) => {
    await loadData(page, rowsPerPage || tableData.pageSize);
  };

  return (
    <ListingTableWithPagination
      disableSelection
      actions={tableAction}
      name={'table'}
      inlineTitle={'Certificates'}
      inline={true}
      headers={getHeaders(currenySymbol)}
      data={tableData}
      onPageChange={handlePageChange}
    />
  );
};
