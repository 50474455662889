import { gql } from '@apollo/client';

export const getTreaties = gql`
  query TreatiesList(
    $TreatyType: [String]
    $LeaderReinsurer: [String]
    $Currency: [String]
    $EffectiveToDate: Date
    $EffectiveFromDate: Date
    $Status: String
    $KeywordSearch: String
    $OrderByField: String
    $DescendingField: Boolean
    $SelectedCompany: String!
    $pagination: PaginationInput!
  ) {
    Reinsurance {
      queries {
        TreatiesList(
          TreatyType: $TreatyType
          LeaderReinsurer: $LeaderReinsurer
          Currency: $Currency
          EffectiveToDate: $EffectiveToDate
          EffectiveFromDate: $EffectiveFromDate
          Status: $Status
          KeywordSearch: $KeywordSearch
          OrderByField: $OrderByField
          DescendingField: $DescendingField
          pagination: $pagination
        ) {
          items {
            reinsurance_Treaties_Id
            reinsurance_Treaties_TreatyID
            reinsurance_Treaties_TreatyID
            reinsurance_Treaties_TreatyName
            reinsurance_Treaties_TreatyType
            reinsurance_Treaties_EffectiveDate
            reinsurance_Treaties_ExpiryDate
            reinsurance_Treaties_TreatyCurrency {
              Id
              Code
              Symbol
              Title
            }
            mainReinsurerDetails_FullName
            reinsurance_Treaties_TreatyStatus
          }
          paging {
            totalCount
            pageNumber
            pageSize
          }
        }
        GetMainReinsurers(SelectedCompany: $SelectedCompany) {
          mainReinsurerDetails_Id
          mainReinsurerDetails_FullName
        }
      }
    }
    Reinsurance_TreatyType: __type(name: "Reinsurance_TreatyType") {
      enumValues {
        name
      }
    }
    Reinsurance_TreatyStatusList: __type(name: "Reinsurance_TreatyStatusList") {
      enumValues {
        name
      }
    }
    Core {
      lookups {
        currencies {
          Id
          Code
          Symbol
          Title
        }
      }
    }
  }
`;

export const getTreatyAuditTrailsQuery = gql`
  query getTreatyAuditTrails($TreatyID: String!) {
    Reinsurance {
      queries {
        GetTreatyAuditTrails(TreatyID: $TreatyID) {
          reinsurance_AuditTrail_Id
          reinsurance_AuditTrail_createdOn
          reinsurance_AuditTrail_Message
        }
      }
    }
  }
`;
