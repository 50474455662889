import {
  DynamicFormInputType,
  FormInputTypes,
} from '../../../../../../DynamicForm';

export const inputs: Record<string, DynamicFormInputType> = {
  documentName: {
    name: 'documentName',
    title: 'Document Name',
    type: FormInputTypes.text,
    value: '',
    required: true,
  },
  document: {
    name: 'document',
    title: 'Document',
    type: FormInputTypes.fileuploader,
    value: '',
    required: true,
    disabled: false,
  },
};
