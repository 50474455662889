import IReceivablesPayablesTableData, {
  IReceivablesPayablesResponseData,
} from '.';
import { IAbstractRecord } from '../../../models';
import { IListingData } from '../../../models/listing';
import { lookupListAsRecordObject } from '../../../utils/graph-utils';

export function mapToListingData(data: IAbstractRecord): IListingData {
  const list: Record<string, IReceivablesPayablesTableData> = {};
  data.Accounting.queries.PolicyBusinessPartnerList?.items?.forEach(
    (obj: IReceivablesPayablesResponseData) => {
      const businessPartnerId = obj.salesforceManagement_BusinessPartner_Id;

      if (list[businessPartnerId]) {
        if (
          !list[businessPartnerId].policyId.includes(
            obj.policyBusinessPartnerDetails_PolicyID.id
          )
        ) {
          list[businessPartnerId].totalPoliciesIssued += 1;
          // list[businessPartnerId].totalBalanceBasicCurrency +=
          //   obj.receivableBills_OutstandingBalanceBasicSystemCurrency -
          //   obj.payableBills_OutstandingBalanceBasicSystemCurrency;
          // list[businessPartnerId].totalBalanceSecondaryCurrency +=
          //   obj.receivableBills_OutstandingBalanceSecondarySystemCurrency -
          //   obj.payableBills_OutstandingBalanceSecondarySystemCurrency;
          list[businessPartnerId].policyId.push(
            obj.policyBusinessPartnerDetails_PolicyID.id
          );
        }
      } else {
        list[businessPartnerId] = {
          SalesforceManagement_BusinessPartner_Id: businessPartnerId,
          SalesforceManagement_BusinessPartner_FullName:
            obj.salesforceManagement_BusinessPartner_FullName,
          policyId: [obj.policyBusinessPartnerDetails_PolicyID.id],
          totalPoliciesIssued: 1,
          // totalBalanceBasicCurrency:
          //   obj.receivableBills_OutstandingBalanceBasicSystemCurrency -
          //   obj.payableBills_OutstandingBalanceBasicSystemCurrency,
          // totalBalanceSecondaryCurrency:
          //   obj.receivableBills_OutstandingBalanceSecondarySystemCurrency -
          //   obj.payableBills_OutstandingBalanceSecondarySystemCurrency,
        };
      }
    }
  );

  return {
    pagedItems: list,
    pageSize:
      data.Accounting.queries.PolicyBusinessPartnerList?.paging.pageSize,
    pageNumber:
      data.Accounting.queries.PolicyBusinessPartnerList?.paging.pageNumber,
    totalCount:
      data.Accounting.queries.PolicyBusinessPartnerList?.paging.totalCount,
  };
}

export function toLookupsData(
  companiesData: Record<string, any>,
  businessPartnerData: Record<string, any>
) {
  const lovs: Record<string, Record<string, string>> = {
    company: {},
    businessPartner: {},
  };

  lovs.company = lookupListAsRecordObject(
    companiesData?.SalesforceManagement?.lookups?.relatedCompanies
  );

  businessPartnerData?.SalesforceManagement?.queries?.GetAllBusinessPartners?.items?.forEach(
    (element: any) => {
      if (element.salesforceManagement_BusinessPartner_Id) {
        lovs.businessPartner[element.salesforceManagement_BusinessPartner_Id] =
          element.salesforceManagement_BusinessPartner_FullName;
      }
    }
  );

  return lovs;
}
