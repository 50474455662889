export function extractExternalCode(json: any): string {
  return (
    json?.data?.Production?.entities?.proposal?.views?.Production_all
      ?.properties?.LineID?.views?.PlanConfigManagement_all?.properties
      ?.ExternalCode || ''
  );
}

export function extractProposalType(json: any): string {
  return (
    json?.data?.Production?.entities?.proposal?.views?.Production_all
      ?.properties?.Type || ''
  );
}
