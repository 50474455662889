import { gql } from '@apollo/client';

export function ListAgenciesByAgent() {
  return gql`
    query ListAgenciesByAgent(
      $agentId: String!
      $status: Insurance_AgencyStatuses
    ) {
      Insurance {
        queries {
          ListAgenciesByAgent(AgentID: $agentId, Status: $status) {
            insurance_Agency_Id
            insurance_Agency_AgencyName
          }
        }
      }
    }
  `;
}

export function createActionAsUnderwriter() {
  return gql`
    mutation createActionAsUnderwriter(
      $agencyId: String!
      $agentId: String!
      $lineOfBusiness: String!
    ) {
      insurance {
        actions {
          createApplicationAsUnderwriter(
            agencyId: $agencyId
            agentId: $agentId
            lineOfBusiness: $lineOfBusiness
          ) {
            id
          }
        }
      }
    }
  `;
}

export function createActionAsAgent() {
  return gql`
    mutation createActionAsAgent(
      $agencyId: String
      $agentId: String
      $lineOfBusiness: String!
    ) {
      insurance {
        actions {
          createApplicationAsAgent(
            lineOfBusiness: $lineOfBusiness
            agencyId: $agencyId
            agentId: $agentId
          ) {
            id
          }
        }
      }
    }
  `;
}
