import React, { useEffect, useMemo, useState } from 'react';
import GenericDrawer from '../../components/common/generic-drawer/GenericDrawer';
import { EnhancedButtonStatus } from '../../components/common/EnhancedButton';
import {
  getNumberBeforeHyphen,
  getSections,
  IProposalDrawerValues,
  proposalDrawerInitialValues,
} from './content';
import { useLazyQuery, useMutation } from '@apollo/client';
import Loader from '../../components/Loader';
import { IProposalDrawerProps } from '.';
import SectionDynamicForm from '../../DynamicForm/SectionDynamicForm';
import {
  createProposal,
  fetchedAssignedBPList,
  fetchedAssignedBPListWithPagination,
  getActiveAssignedBPPlanList,
  getProposalsEnums,
} from './queries';
// import { checkPlanExists } from "../plan-drawer/queries";
import { useAppSelector } from '../../redux/hooks';
import {
  extractAssignedBP,
  extractAssignedBPWithPagination,
  extractLines,
  extractPlanAssignBPPlan_Id,
  extractPlans,
  extractSublines,
  LookupToList,
} from './utils';
import { isEmpty, isGuid } from '../../utils/validationUtils';
import { isArray } from 'lodash';
import { toast } from 'react-toastify';
import ToastSuccessMessage from '../../components/ToastSuccessMessage';
import ToastErrorMessage from '../../components/ToastErrorMessage';
import { getError } from '../../utils/graph-utils';
import { useNavigate } from 'react-router-dom';

interface ISubmitData {
  primaryBP: string[];
  primaryPercentage: string;
  secondaryBP: string[];
  secondaryPercentage: string;
  additionalBP: string[];
  additionalPercentage: string;
  line: string;
  subline: string;
  plan: string;
  policyCurrency: string;
  isGroup: string;
}

const ProposalDrawer: React.FC<IProposalDrawerProps> = ({
  open,
  onSuccess,
  onClose,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const user = useAppSelector((state) => state.user);
  const businessUserId = user.info.businessUser_Id;
  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();

  const [planCurrencies, setPlanCurrencies] = useState<Record<string, string>>(
    {}
  );

  const [lists, setLists] = useState<Record<string, Record<string, string>>>({
    primaryBP: {},
    addSecondaryBP: {},
    additionalBP: {},
    line: {},
    plan: {},
    assignedPlan: {},
    policyCurrency: {},
  });

  const [fetchedAssignedPrimaryBPLazy] = useLazyQuery(fetchedAssignedBPList());
  const [fetchedAssignedPrimaryBPWithPaginationLazy] = useLazyQuery(
    fetchedAssignedBPListWithPagination()
  );

  const [getActiveAssignedBPPlanListLazy] = useLazyQuery(
    getActiveAssignedBPPlanList()
  );

  const [getCurrencyList] = useLazyQuery(getProposalsEnums());

  const [createProposalAction] = useMutation(createProposal());

  const [values, setValues] = useState<IProposalDrawerValues>();

  const initialize = async () => {
    const newList = { ...lists };
    const result = await getCurrencyList();
    const currencyList = LookupToList(result.data);
    newList.policyCurrency = currencyList?.policyCurrency || {};
    newList.isGroup = {
      Yes: 'Yes',
      No: 'No',
    };
    setValues(proposalDrawerInitialValues);
    setLists(newList);
    setLoading(false);
  };

  useEffect(() => {
    initialize();
  }, []);

  const handlePrimaryBPSearch = async (inputValue: string) => {
    if (inputValue?.length > 2) {
      const newData = await fetchedAssignedPrimaryBPLazy({
        variables: {
          selectedBusinessUserID: businessUserId,
          searchKeyword: inputValue,
        },
      });

      return extractAssignedBP(newData.data);
    }

    return {} as Record<string, string>;
  };

  const handleBusinessPartnerSelection = async (selectedOption: string) => {
    if (!isEmpty(selectedOption)) {
      const newData = await getActiveAssignedBPPlanListLazy({
        variables: {
          currentPage: 1,
          currentPageSize: 10,
          selectedBusinessUserID: businessUserId,
          selectedBusinessPartnerID: selectedOption,
        },
        errorPolicy: 'all',
      });
      return extractLines(newData.data);
    }
    return {} as Record<string, string>;
  };

  const handleLineSelection = async (selectedOption: string) => {
    if (!isEmpty(selectedOption)) {
      const newData = await getActiveAssignedBPPlanListLazy({
        variables: {
          currentPage: 1,
          currentPageSize: 10,
          selectedBusinessUserID: businessUserId,
          selectedBusinessPartnerID: values.primaryBP,
          selectedLineID: selectedOption,
        },
        errorPolicy: 'all',
      });
      return extractSublines(newData.data);
    }
    return {} as Record<string, string>;
  };

  const handleSublineSelection = async (selectedOption: string) => {
    if (!isEmpty(selectedOption)) {
      const newData = await getActiveAssignedBPPlanListLazy({
        variables: {
          currentPage: 1,
          currentPageSize: 10,
          selectedBusinessUserID: businessUserId,
          selectedBusinessPartnerID: values.primaryBP,
          selectedLineID: isArray(values.line) ? values.line[0] : values.line,
          selectedSublineID: selectedOption,
        },
        errorPolicy: 'all',
      });
      const newPlanList = extractPlans(newData.data);

      setPlanCurrencies(newPlanList.planCurrencies);
      return [newPlanList.lines, extractPlanAssignBPPlan_Id(newData.data)];
    }
    return [{}, {}] as Record<string, string>[];
  };

  const sections = useMemo(() => {
    if (values) {
      return getSections(
        values,
        lists,
        user.info.businessUser_AllowGroupPolicy
      );
    }
    return getSections(
      proposalDrawerInitialValues,
      lists,
      user.info.businessUser_AllowGroupPolicy
    );
  }, [values, lists, user.info.businessUser_AllowGroupPolicy]);

  const onCustomChange = async (
    fieldName: string,
    value: any,
    allValues: Record<string, any>
  ) => {
    let newValue = value;
    const newLists = { ...lists };

    if (fieldName === 'primaryBP') {
      if (isGuid(value)) {
        newLists['line'] = await handleBusinessPartnerSelection(value);
        allValues['line'] = '';

        allValues['subline'] = '';
        newLists['subline'] = {};

        allValues['plan'] = '';
        newLists['plan'] = {};
      } else {
        const result = await handlePrimaryBPSearch(value);
        newLists['primaryBP'] = result;
      }
    }

    if (fieldName === 'secondaryBP') {
      if (isGuid(value)) {
        // newLists["line"]  = await handleBusinessPartnerSelection(value[0]);
      } else {
        const result = await handlePrimaryBPSearch(value);
        newLists['secondaryBP'] = result;
      }
    }

    if (fieldName === 'additionalBP') {
      if (isGuid(value)) {
        // newLists["line"]  = await handleBusinessPartnerSelection(value[0]);
      } else {
        const result = await handlePrimaryBPSearch(value);
        newLists['additionalBP'] = result;
      }
    }

    if (fieldName === 'addSecondaryBP' || fieldName === 'addAdditionalBP') {
      newValue = true;
    }

    if (fieldName === 'line') {
      const result = await handleLineSelection(value);
      newLists['subline'] = result;
      allValues['subline'] = '';

      allValues['plan'] = '';
      newLists['plan'] = {};
    }

    if (fieldName === 'subline') {
      const result = await handleSublineSelection(value);
      newLists['plan'] = result[0];
      newLists['assignedPlan'] = result[1];
      allValues['plan'] = '';
    }

    if (fieldName === 'plan') {
      allValues['policyCurrency'] = planCurrencies[value] || '';
    }

    (allValues as any)[fieldName] = newValue;
    setValues(allValues as any);
    setLists(newLists);
  };

  const onSubmit = async (data: ISubmitData) => {
    setSubmitButtonState('loading'), setFormDisabled(true);

    const result = await createProposalAction({
      variables: {
        lineID: data.line,
        sublineID: data.subline,
        planID: data.plan,
        primaryBP: data.primaryBP,
        primaryPercentage: Number(data.primaryPercentage) / 100,
        secondaryBP: data.secondaryBP || null,
        secondaryPercentage: Number(data.secondaryPercentage) / 100,
        additionalBP: data.additionalBP || null,
        additionalPercentage: Number(data.additionalPercentage) / 100,
        policyCurrency: data.policyCurrency,
        selectedAssignedBPPlan: lists['assignedPlan'][data.plan],
        isGroup: ['4', '50', '51', '3'].includes(
          getNumberBeforeHyphen(lists?.line[data?.line])?.toString()
        )
          ? data.isGroup === 'Yes'
          : false,
      },
      errorPolicy: 'all',
    });

    if (isEmpty(result.errors)) {
      toast.success(
        <ToastSuccessMessage>Proposal successfully created</ToastSuccessMessage>
      );
      const proposalId = result.data.production.actions.createProposal.id;
      setTimeout(() => {
        setSubmitButtonState('success');
        if (proposalId) {
          navigate(`/production/proposal/` + proposalId);
        } else {
          onSuccess();
          onClose();
          setFormDisabled(false);
        }
      }, 500);
    } else {
      setSubmitButtonState(undefined);
      setFormDisabled(false);
      toast.error(<ToastErrorMessage>{getError(result)}</ToastErrorMessage>);
    }
  };

  return (
    <GenericDrawer
      title={'New Proposal'}
      onClose={() => onClose()}
      isOpen={open}
    >
      {loading && open ? (
        <Loader />
      ) : (
        <>
          <SectionDynamicForm
            onSubmit={(v) => {
              onSubmit(v as ISubmitData);
            }}
            onChange={onCustomChange}
            onAutocompleteSearch={(
              fieldName: string,
              value: string,
              pagination
            ) => {
              if (
                ['primaryBP', 'secondaryBP', 'additionalBP'].includes(fieldName)
              ) {
                return fetchedAssignedPrimaryBPWithPaginationLazy({
                  variables: {
                    selectedBusinessUserID: businessUserId,
                    searchKeyword: value,
                    pagination,
                  },
                }).then((result) => {
                  return {
                    options: extractAssignedBPWithPagination(result.data),
                    totalCount:
                      result.data.SalesforceManagement.queries.getAssignedBPList
                        .paging.totalCount,
                  };
                });
              }

              return new Promise<{
                options: Record<string, string>;
                totalCount: number;
              }>((resolve) => {
                resolve({ options: {}, totalCount: 0 });
              });
            }}
            buttonText={'Submit'}
            submitButtonState={submitButtonState}
            disableForm={formDisabled}
            sections={sections}
            hasDoprdownSpecificBehavior={true}
          />
        </>
      )}
    </GenericDrawer>
  );
};

export default ProposalDrawer;
