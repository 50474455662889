import { IAbstractRecord } from '../../../models';

export interface IListingTableFilter {
  title: string;
  name: string;
  selectedValues: string[];
  options: string[];
}

export interface IEnhancedMenuItem {
  name: string;
  title: string;
  disabled?: boolean;
  target?: ActionTarget;
  iconUri?: string;
  isPrimary?: boolean;
  supportBulk?: boolean;
  displayActionPerRecord?: boolean;
  onClick: (payload?: IAbstractRecord) => void;
}

export enum ActionTarget {
  Entity = 'Entity',
}

export interface IFilterModel {
  searchKey?: string;
  namedFilters?: IAbstractRecord;
  pagination?: IAbstractRecord;
  userId?: string;
  userRoles?: string[];
}

export interface IEnhancedTableFilter {
  type: 'daterange' | 'select' | 'date';
  name: string;
  placeholder?: string;
  clientFilter?: boolean;
}
export interface IEnhancedTableDateFilter extends IEnhancedTableFilter {
  value: string | null;
  type: 'date';
}
export interface IEnhancedTableDateRangeFilter extends IEnhancedTableFilter {
  minDate?: Date | undefined;
  maxDate?: Date | undefined;
  value: Date[];
  type: 'daterange';
}

export interface IEnhancedTableSelectFilter extends IEnhancedTableFilter {
  selectedValues: string[];
  options: Record<string, string>;
  type: 'select';
}

export type EnhancedTableFilterType =
  | IEnhancedTableDateRangeFilter
  | IEnhancedTableSelectFilter
  | IEnhancedTableDateFilter;
