import { isEmpty, isValidNumber } from '../../../../../utils/validationUtils';
import {
  IPropertyCoverDetails,
  IProposalBankerDetails,
  IProposalCostCharges,
  IProposalCover,
  IProposalPageFormState,
  IProposalRiskDetails,
} from './form';
import { IProposalDetailsSummary } from './index2';

export const validateCostWidgetField = (
  fieldName: keyof IProposalCostCharges,
  value: any,
  cover: IProposalCostCharges
): string => {
  if (fieldName === 'policyCost') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  if (fieldName === 'tpaFeesType') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  if (fieldName === 'chargesPercentage') {
    if (isEmpty(value)) {
      return 'Required';
    } else {
      if (!isValidNumber(value)) {
        return 'Invalid Number';
      }

      const numberValue = Number(value);

      if (numberValue < 0 || numberValue > 100) {
        return 'Value should be between 0 and 100';
      }
    }
  }

  if (fieldName === 'tpaFeesPercentage') {
    if (cover.tpaFeesType === 'PERCENTAGE') {
      if (isEmpty(value)) {
        return 'Required';
      } else {
        if (!isValidNumber(value)) {
          return 'Invalid Number';
        }

        const numberValue = Number(value);

        if (numberValue < 0 || numberValue > 100) {
          return 'Value should be between 0 and 100';
        }
      }
    }
  }

  if (fieldName === 'tpaFeesAmount') {
    if (cover.tpaFeesType === 'AMOUNT') {
      if (isEmpty(value)) {
        return 'Required';
      } else {
        if (!isValidNumber(value)) {
          return 'Invalid Number';
        }

        const numberValue = Number(value);

        if (numberValue < 0) {
          return 'Value should be greater than 0';
        }
      }
    }
  }

  return '';
};

export const validateCoverRepeaterField = (
  fieldName: keyof IProposalCover,
  value: any,
  cover: IProposalCover
): string => {
  if (fieldName === 'policyCover') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  if (fieldName === 'coverPremiumType') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  if (fieldName === 'currency') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  if (fieldName === 'coverPremiumPercentage') {
    if (cover.coverPremiumType === 'PERCENTAGE') {
      if (isEmpty(value)) {
        return 'Required';
      } else {
        if (!isValidNumber(value)) {
          return 'Invalid Number';
        }

        const numberValue = Number(value);

        if (numberValue < 0 || numberValue > 100) {
          return 'Value should be between 0 and 100';
        }
      }
    }
  }

  if (fieldName === 'coverPremiumValue') {
    if (cover.coverPremiumType === 'VALUE') {
      if (isEmpty(value)) {
        return 'Required';
      } else {
        if (!isValidNumber(value)) {
          return 'Invalid Number';
        }

        const numberValue = Number(value);

        if (numberValue < 0) {
          return 'Value should be greater than 0';
        }
      }
    }
  }

  if (fieldName === 'coverSumInsured') {
    if (isEmpty(value)) {
      return 'Required';
    }

    if (!isValidNumber(value)) {
      return 'Invalid Number';
    }

    const numberValue = Number(value);

    if (numberValue < 0) {
      return 'Value should be greater than 0';
    }
  }

  if (fieldName === 'excessOnClaimType') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  if (fieldName === 'excessOnClaimPercentage') {
    if (cover.excessOnClaimType === 'PERCENTAGE') {
      if (isEmpty(value)) {
        return 'Required';
      } else {
        if (!isValidNumber(value)) {
          return 'Invalid Number';
        }

        const numberValue = Number(value);

        if (numberValue < 0 || numberValue > 100) {
          return 'Value should be between 0 and 100';
        }
      }
    }
  }

  if (fieldName === 'excessOnClaimAmount') {
    if (cover.excessOnClaimType === 'VALUE') {
      if (isEmpty(value)) {
        return 'Required';
      } else {
        if (!isValidNumber(value)) {
          return 'Invalid Number';
        }

        const numberValue = Number(value);

        if (numberValue < 0) {
          return 'Value should be greater than 0';
        }
      }
    }
  }

  if (fieldName === 'excessOnClaimDays') {
    if (cover.excessOnClaimType === 'DAYS') {
      if (isEmpty(value)) {
        return 'Required';
      } else {
        if (!isValidNumber(value)) {
          return 'Invalid Number';
        }

        const numberValue = Number(value);

        if (numberValue < 0) {
          return 'Value should be greater than 0';
        }
      }
    }
  }

  return '';
};

export const validateBankerRepeaterField = (
  fieldName: keyof IProposalBankerDetails,
  value: any,
  banker: IProposalBankerDetails
): string => {
  if (fieldName === 'branchName') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  if (fieldName === 'isMain') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  if (fieldName === 'isAgency') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  if (fieldName === 'numberOfEmployees') {
    if (isEmpty(value)) {
      return 'Required';
    }

    if (!isValidNumber(value)) {
      return 'Invalid Number';
    }

    const numberValue = Number(value);

    if (numberValue < 0) {
      return 'Value should be greater than 0';
    }
  }

  if (fieldName === 'underwritingYear') {
    if (isEmpty(value)) {
      return 'Required';
    }

    if (!isValidNumber(value)) {
      return 'Invalid Number';
    }
  }

  if (fieldName === 'infidelity') {
    if (isEmpty(value)) {
      return 'Required';
    }

    if (!isValidNumber(value)) {
      return 'Invalid Number';
    }

    const numberValue = Number(value);

    if (numberValue < 0) {
      return 'Value should be greater than or equal to 0';
    }
  }

  if (fieldName === 'burglary') {
    if (isEmpty(value)) {
      return 'Required';
    }

    if (!isValidNumber(value)) {
      return 'Invalid Number';
    }

    const numberValue = Number(value);

    if (numberValue < 0) {
      return 'Value should be greater than or equal to 0';
    }
  }

  if (fieldName === 'holdUp') {
    if (isEmpty(value)) {
      return 'Required';
    }

    if (!isValidNumber(value)) {
      return 'Invalid Number';
    }

    const numberValue = Number(value);

    if (numberValue < 0) {
      return 'Value should be greater than or equal to 0';
    }
  }

  if (fieldName === 'inSafe') {
    if (isEmpty(value)) {
      return 'Required';
    }

    if (!isValidNumber(value)) {
      return 'Invalid Number';
    }

    const numberValue = Number(value);

    if (numberValue < 0) {
      return 'Value should be greater than or equal to 0';
    }
  }

  if (fieldName === 'inTransit') {
    if (isEmpty(value)) {
      return 'Required';
    }

    if (!isValidNumber(value)) {
      return 'Invalid Number';
    }

    const numberValue = Number(value);

    if (numberValue < 0) {
      return 'Value should be greater than or equal to 0';
    }
  }

  if (fieldName === 'forgedChecks') {
    if (isEmpty(value)) {
      return 'Required';
    }

    if (!isValidNumber(value)) {
      return 'Invalid Number';
    }

    const numberValue = Number(value);

    if (numberValue < 0) {
      return 'Value should be greater than or equal to 0';
    }
  }

  if (fieldName === 'counterfeitCurrencies') {
    if (isEmpty(value)) {
      return 'Required';
    }

    if (!isValidNumber(value)) {
      return 'Invalid Number';
    }

    const numberValue = Number(value);

    if (numberValue < 0) {
      return 'Value should be greater than or equal to 0';
    }
  }

  if (fieldName === 'clause6') {
    if (isEmpty(value)) {
      return 'Required';
    }

    if (!isValidNumber(value)) {
      return 'Invalid Number';
    }

    const numberValue = Number(value);

    if (numberValue < 0) {
      return 'Value should be greater than or equal to 0';
    }
  }

  if (fieldName === 'falsification') {
    if (isEmpty(value)) {
      return 'Required';
    }

    if (!isValidNumber(value)) {
      return 'Invalid Number';
    }

    const numberValue = Number(value);

    if (numberValue < 0) {
      return 'Value should be greater than or equal to 0';
    }
  }

  return '';
};

export const validateMarineDetailsField = (
  fieldName: keyof IProposalPageFormState['values']['marineDetails'],
  value: any
): string => {
  if (fieldName === 'matter') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  if (fieldName === 'description') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  if (fieldName === 'vesselName') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  if (fieldName === 'from') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  if (fieldName === 'to') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  // if (fieldName === 'via') {
  //   if (isEmpty(value)) {
  //     return 'Required';
  //   }
  // }

  if (fieldName === 'survey') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  if (fieldName === 'cargoContract') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  if (fieldName === 'sumPerShipment') {
    if (value && !isValidNumber(value)) {
      return 'Invalid Number';
    }
    const numberValue = Number(value);

    if (numberValue < 0) {
      return 'Value should be greater than 0';
    }
  }

  if (fieldName === 'claimsPayableBy') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  if (fieldName === 'loading') {
    if (isEmpty(value)) {
      return 'Required';
    } else if (!isValidNumber(value)) {
      return 'Invalid Number';
    }
  }

  if (fieldName === 'vesselAge') {
    if (value && !isValidNumber(value)) {
      return 'Invalid Number';
    }
    const numberValue = Number(value);

    if (numberValue < 0) {
      return 'Value should be greater than 0';
    }
  }

  return '';
};

export const validateMarineHullDetailsField = (
  fieldName: keyof IProposalPageFormState['values']['marineHullDetails'],
  value: any
): string => {
  if (fieldName === 'vesselName') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  if (fieldName === 'connectionPort') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  if (fieldName === 'territorialWaters') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  if (fieldName === 'usage') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  if (fieldName === 'builder') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  if (fieldName === 'constructionYear') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  if (fieldName === 'registeryPort') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  if (fieldName === 'engineType') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  if (fieldName === 'engineHorsePower') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  if (fieldName === 'engineYearBuilt') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  return '';
};

export const validateRiskDetailsField = (
  fieldName: keyof IProposalRiskDetails,
  value: any
): string => {
  if (fieldName === 'riskDescription') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  if (fieldName === 'geoLocation') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  return '';
};

export const validateRiskCoverDetailsField = (
  fieldName: keyof IPropertyCoverDetails,
  value: string | number
): string => {
  if (fieldName === 'planCoverID') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  if (fieldName === 'riskRate') {
    if (value && (!isValidNumber(value) || Number(value) < 0)) {
      return 'Invalid Number';
    }
  }

  if (fieldName === 'sumInsured') {
    if (value && (!isValidNumber(value) || Number(value) < 0)) {
      return 'Invalid Number';
    }
  }

  if (fieldName === 'premium') {
    if (value && (!isValidNumber(value) || Number(value) < 0)) {
      return 'Invalid Number';
    }
  }
  return '';
};

export const validatePolicyPayerField = (
  fieldName: string,
  value: any
): string => {
  if (isEmpty(value)) {
    return 'Required';
  }

  if (fieldName === 'payerId') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  if (fieldName === 'payerAddress') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  return '';
};

export const validatePolicyOwnerField = (
  fieldName: string,
  value: any
): string => {
  if (isEmpty(value)) {
    return 'Required';
  }

  if (fieldName === 'ownerId') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  if (fieldName === 'ownerAddress') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  return '';
};

export const validatePolicyInsuredField = (
  fieldName: string,
  value: any,
  lineExternalCode: string
): string => {
  if (fieldName === 'id') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  if (fieldName === 'order') {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  if (
    ![
      '5',
      '9',
      '10',
      '13',
      '21',
      '35',
      '11',
      '15',
      '16',
      '25',
      '27',
      '29',
      '30',
      '31',
      '37',
      '38',
      '39',
      '42',
      '46',
    ].includes(lineExternalCode) &&
    fieldName === 'address'
  ) {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  return '';
};

export const validatePolicyBeneficiaryField = (
  fieldName: string,
  value: any
): string => {
  fieldName;
  value;
  // if (isEmpty(value)) {
  //   return "Required";
  // }

  // if (fieldName === "id") {
  //   if (isEmpty(value)) {
  //     return "Required";
  //   }
  // }

  // if (fieldName === "percentage") {
  //   if (isEmpty(value)) {
  //     return "Required";
  //   }
  // }

  // if (fieldName === "address") {
  //   if (isEmpty(value)) {
  //     return "Required";
  //   }
  // }

  return '';
};

export const validateDescriptionOfRiskDetailsField = (
  fieldName: string,
  value: any,
  lineExternalCode: string
): string => {
  if (
    [
      '11',
      '15',
      '16',
      '25',
      '27',
      '29',
      '30',
      '31',
      '37',
      '38',
      '39',
      '42',
      '46',
    ].includes(lineExternalCode) &&
    fieldName === 'geoLocation'
  ) {
    if (isEmpty(value)) {
      return 'Required';
    }
  }

  return '';
};

export const validateProposalPageForm = (
  data: IProposalDetailsSummary,
  values: IProposalPageFormState['values']
) => {
  const _lineExternalCode: string = data?.LineId?.ExternalCode?.toString();
  const { policyPersons, covers, costs, bankersDetails, riskDetails } = values;

  const errors: IProposalPageFormState['errors'] = {
    policyPersons: {
      payer: {
        payerId: validatePolicyPayerField(
          'payerId',
          policyPersons.payer.payerId
        ),
        payerAddress: validatePolicyPayerField(
          'payerAddress',
          policyPersons.payer.payerAddress
        ),
        ownerIsDifferent: validatePolicyPayerField(
          'ownerIsDifferent',
          policyPersons.payer.ownerIsDifferent
        ),
      },
      owner: {
        ownerId: policyPersons.payer.ownerIsDifferent
          ? validatePolicyOwnerField('ownerId', policyPersons.owner.ownerId)
          : '',
        ownerAddress: policyPersons.payer.ownerIsDifferent
          ? validatePolicyOwnerField(
              'ownerAddress',
              policyPersons.owner.ownerAddress
            )
          : '',
      },
      insured: policyPersons.insured.map((insured) => ({
        id: validatePolicyInsuredField('id', insured.id, _lineExternalCode),
        order: validatePolicyInsuredField(
          'order',
          insured.order,
          _lineExternalCode
        ),
        address: validatePolicyInsuredField(
          'address',
          insured.address,
          _lineExternalCode
        ),
      })),
      beneficiaries: policyPersons?.beneficiaries?.map((ben) => ({
        id: validatePolicyBeneficiaryField('id', ben.id),
        percentage: validatePolicyBeneficiaryField(
          'percentage',
          ben.percentage
        ),
        address: validatePolicyBeneficiaryField('address', ben.address),
      })),
    },
    covers: covers.map((c) => ({
      isMain: validateCoverRepeaterField('isMain', c.isMain, c),
      isMandatory: validateCoverRepeaterField('isMandatory', c.isMandatory, c),
      coverPremiumType: validateCoverRepeaterField(
        'coverPremiumType',
        c.coverPremiumType,
        c
      ),
      coverPremiumPercentage: validateCoverRepeaterField(
        'coverPremiumPercentage',
        c.coverPremiumPercentage,
        c
      ),
      coverPremiumValue: validateCoverRepeaterField(
        'coverPremiumValue',
        c.coverPremiumValue,
        c
      ),
      currency: validateCoverRepeaterField('currency', c.currency, c),
      coverSumInsured: validateCoverRepeaterField(
        'coverSumInsured',
        c.coverSumInsured,
        c
      ),
      sumInsuredIsAdditive: validateCoverRepeaterField(
        'sumInsuredIsAdditive',
        c.sumInsuredIsAdditive,
        c
      ),
      excessOnClaimType: validateCoverRepeaterField(
        'excessOnClaimType',
        c.excessOnClaimType,
        c
      ),
      excessOnClaimAmount: validateCoverRepeaterField(
        'excessOnClaimAmount',
        c.excessOnClaimAmount,
        c
      ),
      excessOnClaimPercentage: validateCoverRepeaterField(
        'excessOnClaimPercentage',
        c.excessOnClaimPercentage,
        c
      ),
      excessOnClaimDays: validateCoverRepeaterField(
        'excessOnClaimDays',
        c.excessOnClaimDays,
        c
      ),
      policyCover: validateCoverRepeaterField(
        'policyCover',
        c.excessOnClaimDays,
        c
      ),
    })),
    costs: {
      policyCost: validateCostWidgetField(
        'policyCost',
        costs.policyCost,
        costs
      ),
      chargesPercentage: validateCostWidgetField(
        'chargesPercentage',
        costs.chargesPercentage,
        costs
      ),
      tpaFeesType: validateCostWidgetField(
        'tpaFeesType',
        costs.tpaFeesType,
        costs
      ),
      tpaFeesAmount: validateCostWidgetField(
        'tpaFeesAmount',
        costs.tpaFeesAmount,
        costs
      ),
      tpaFeesPercentage: validateCostWidgetField(
        'tpaFeesPercentage',
        costs.tpaFeesPercentage,
        costs
      ),
      tpaFeesApplicableOn: validateCostWidgetField(
        'tpaFeesApplicableOn',
        costs.tpaFeesApplicableOn,
        costs
      ),
    },
    bankersDetails:
      _lineExternalCode === '12'
        ? bankersDetails.map((b) => ({
            branchName: validateBankerRepeaterField(
              'branchName',
              b.branchName,
              b
            ),
            isMain: validateBankerRepeaterField('isMain', b.isMain, b),
            isAgency: validateBankerRepeaterField('isAgency', b.isAgency, b),
            numberOfEmployees: validateBankerRepeaterField(
              'numberOfEmployees',
              b.numberOfEmployees,
              b
            ),
            underwritingYear: validateBankerRepeaterField(
              'underwritingYear',
              b.underwritingYear,
              b
            ),
            infidelity: validateBankerRepeaterField(
              'infidelity',
              b.infidelity,
              b
            ),
            burglary: validateBankerRepeaterField('burglary', b.burglary, b),
            holdUp: validateBankerRepeaterField('holdUp', b.holdUp, b),
            inSafe: validateBankerRepeaterField('inSafe', b.inSafe, b),
            inTransit: validateBankerRepeaterField('inTransit', b.inTransit, b),
            forgedChecks: validateBankerRepeaterField(
              'forgedChecks',
              b.forgedChecks,
              b
            ),
            counterfeitCurrencies: validateBankerRepeaterField(
              'counterfeitCurrencies',
              b.counterfeitCurrencies,
              b
            ),
            clause6: validateBankerRepeaterField('clause6', b.clause6, b),
            falsification: validateBankerRepeaterField(
              'falsification',
              b.falsification,
              b
            ),
          }))
        : undefined,
    marineDetails:
      _lineExternalCode === '3'
        ? {
            matter: validateMarineDetailsField(
              'matter',
              values.marineDetails.matter
            ),
            description: validateMarineDetailsField(
              'description',
              values.marineDetails.description
            ),
            vesselName: validateMarineDetailsField(
              'vesselName',
              values.marineDetails.vesselName
            ),
            from: validateMarineDetailsField('from', values.marineDetails.from),
            to: validateMarineDetailsField('to', values.marineDetails.to),
            via: validateMarineDetailsField('via', values.marineDetails.via),
            survey: validateMarineDetailsField(
              'survey',
              values.marineDetails.survey
            ),
            cargoContract: validateMarineDetailsField(
              'cargoContract',
              values.marineDetails.cargoContract
            ),
            sumPerShipment: validateMarineDetailsField(
              'sumPerShipment',
              values.marineDetails.sumPerShipment
            ),
            vesselAge: validateMarineDetailsField(
              'vesselAge',
              values.marineDetails.vesselAge
            ),
            claimsPayableBy: validateMarineDetailsField(
              'claimsPayableBy',
              values.marineDetails.claimsPayableBy
            ),
            loading: validateMarineDetailsField(
              'loading',
              values.marineDetails.loading
            ),
          }
        : undefined,
    marineHullDetails:
      _lineExternalCode === '33' || _lineExternalCode === '20'
        ? {
            vesselName: validateMarineHullDetailsField(
              'vesselName',
              values.marineHullDetails.vesselName
            ),
            connectionPort: validateMarineHullDetailsField(
              'connectionPort',
              values.marineHullDetails.connectionPort
            ),
            territorialWaters: validateMarineHullDetailsField(
              'territorialWaters',
              values.marineHullDetails.territorialWaters
            ),
            usage: validateMarineHullDetailsField(
              'usage',
              values.marineHullDetails.usage
            ),
            builder: validateMarineHullDetailsField(
              'builder',
              values.marineHullDetails.builder
            ),
            constructionYear: validateMarineHullDetailsField(
              'constructionYear',
              values.marineHullDetails.constructionYear
            ),
            registeryPort: validateMarineHullDetailsField(
              'registeryPort',
              values.marineHullDetails.registeryPort
            ),
            engineType: validateMarineHullDetailsField(
              'engineType',
              values.marineHullDetails.engineType
            ),
            engineYearBuilt: validateMarineHullDetailsField(
              'engineYearBuilt',
              values.marineHullDetails.engineYearBuilt
            ),
            engineHorsePower: validateMarineHullDetailsField(
              'engineHorsePower',
              values.marineHullDetails.engineHorsePower
            ),
          }
        : undefined,
    riskDetails: ['2', '8', '23', '26'].includes(_lineExternalCode)
      ? riskDetails.map((r) => ({
          riskDescription: validateRiskDetailsField(
            'riskDescription',
            r.riskDescription
          ),
          geoLocation: validateRiskDetailsField('geoLocation', r.geoLocation),
          planCovers: r.planCovers.map((p) => ({
            premium: validateRiskCoverDetailsField('premium', p.premium),
            planCoverID: validateRiskCoverDetailsField(
              'planCoverID',
              p.planCoverID
            ),
            riskRate: validateRiskCoverDetailsField('riskRate', p.riskRate),
            sumInsured: validateRiskCoverDetailsField(
              'sumInsured',
              p.sumInsured
            ),
          })),
        }))
      : undefined,

    descriptionOfRiskDetails: [
      '5',
      '9',
      '10',
      '13',
      '21',
      '35',
      '11',
      '15',
      '16',
      '25',
      '27',
      '29',
      '30',
      '31',
      '37',
      '38',
      '39',
      '42',
      '46',
    ].includes(_lineExternalCode)
      ? {
          geoLocation: validateDescriptionOfRiskDetailsField(
            'geoLocation',
            values.descriptionOfRiskDetails.geoLocation,
            _lineExternalCode
          ),
        }
      : undefined,
  };

  const touched: IProposalPageFormState['touched'] = {
    policyPersons: {
      payer: {
        payerId: true,
        payerAddress: true,
        ownerIsDifferent: true,
      },
      owner: {
        ownerId: true,
        ownerAddress: true,
      },
      insured: policyPersons.insured.map(() => ({
        id: true,
        order: true,
        address: true,
        limitOfCover: true,
      })),
      beneficiaries: policyPersons?.beneficiaries?.map(() => ({
        id: true,
        order: true,
        address: true,
      })),
    },
    covers: covers.map(() => ({
      isMain: true,
      isMandatory: true,
      coverPremiumType: true,
      coverPremiumPercentage: true,
      coverPremiumValue: true,
      currency: true,
      coverSumInsured: true,
      sumInsuredIsAdditive: true,
      excessOnClaimType: true,
      excessOnClaimAmount: true,
      excessOnClaimPercentage: true,
      excessOnClaimDays: true,
      policyCover: true,
    })),
    costs: {
      policyCost: true,
      chargesPercentage: true,
      tpaFeesType: true,
      tpaFeesAmount: true,
      tpaFeesPercentage: true,
      tpaFeesApplicableOn: true,
    },
    bankersDetails:
      _lineExternalCode === '12'
        ? bankersDetails.map(() => ({
            branchName: true,
            isMain: true,
            isAgency: true,
            numberOfEmployees: true,
            underwritingYear: true,
            infidelity: true,
            burglary: true,
            holdUp: true,
            inSafe: true,
            inTransit: true,
            forgedChecks: true,
            counterfeitCurrencies: true,
            clause6: true,
            falsification: true,
          }))
        : undefined,
    marineDetails:
      _lineExternalCode === '3'
        ? {
            matter: true,
            description: true,
            vesselName: true,
            vesselAge: true,
            from: true,
            to: true,
            via: true,
            survey: true,
            cargoContract: true,
            sumPerShipment: true,
            lcNumber: true,
            lcIssueDate: true,
            bank: true,
            claimsPayableBy: true,
            loading: true,
            remarks: true,
          }
        : undefined,
    marineHullDetails:
      _lineExternalCode === '33' || _lineExternalCode === '20'
        ? {
            vesselName: true,
            connectionPort: true,
            territorialWaters: true,
            usage: true,
            builder: true,
            constructionYear: true,
            registeryPort: true,
            length: true,
            weight: true,
            height: true,
            engineType: true,
            engineNumber: true,
            engineYearBuilt: true,
            engineHorsePower: true,
            engineMaxDesignedSpeed: true,
            engineFuel: true,
            remarks: true,
          }
        : undefined,
    riskDetails: ['2', '8', '23', '26'].includes(_lineExternalCode)
      ? riskDetails.map((r) => ({
          riskDescription: true,
          geoLocation: true,
          location: true,
          planCovers: r.planCovers.map(() => ({
            planCoverID: true,
            riskRate: true,
            sumInsured: true,
            premium: true,
          })),
        }))
      : undefined,
    descriptionOfRiskDetails: [
      '5',
      '9',
      '10',
      '13',
      '21',
      '35',
      '11',
      '15',
      '16',
      '25',
      '27',
      '29',
      '30',
      '31',
      '37',
      '38',
      '39',
      '42',
      '46',
    ].includes(_lineExternalCode)
      ? {
          descriptionOfRisk: true,
          geoLocation: true,
        }
      : undefined,
  };

  const isValid =
    Object.values(errors.costs).every((e) => isEmpty(e)) &&
    Object.values(errors.covers).every((c) =>
      Object.values(c).every((cv) => isEmpty(cv))
    ) &&
    Object.values(errors.policyPersons.payer).every((e) => isEmpty(e)) &&
    Object.values(errors.policyPersons.owner).every((e) => isEmpty(e)) &&
    Object.values(errors.policyPersons.insured).every((c) =>
      Object.values(c).every((cv) => isEmpty(cv))
    ) &&
    Object.values(errors.policyPersons.beneficiaries).every((c) =>
      Object.values(c).every((cv) => isEmpty(cv))
    ) &&
    Object.values(errors.bankersDetails || []).every((c) =>
      Object.values(c).every((cv) => isEmpty(cv))
    ) &&
    Object.values(errors.marineDetails || []).every((e) => isEmpty(e));

  return { errors, touched, isValid };
};
