import React, { useEffect, useCallback, useState, useRef } from 'react';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import { cloneDeep, isEmpty, isEqual } from 'lodash';
import clsx from 'clsx';
import { MAIN_ONE_THEME, mainFontFamilyMedium } from '../../../constants';
import { IAbstractRecord } from '../../../models';
import { IListingData, ListingDataItemType } from '../../../models/listing';
import { useAppSelector } from '../../../redux/hooks';
import {
  TableSortOrder,
  convertRecordsIntoEnhancedRows,
} from '../../../utils/table-utils';
import Separator from '../../common/Separator';
import {
  EnhancedTableHeader,
  EnhancedTableHeaderType,
} from '../../enhanced-table';
import EnhancedTableBody from '../../enhanced-table/EnhancedTableBody';
import EnhancedTableHead from '../../enhanced-table/EnhancedTableHead';
import { ITableRowSettings } from '../../updated-enhanced-table';
import { makeStyles } from 'tss-react/mui';
import { IEnhancedMenuItem, IListingTableFilter, IFilterModel } from '.';
import FormattingServices from '../../../services/formating-services';
import EnhancedTableToolbar from './ListingTableToolbar';
import { fetchWidgetMetaFromSession } from '../../../utils/session-storage-utils';
import { LabelDisplayedRowsArgs, TablePagination } from '@mui/material';

export interface IListingTableWithPaginationProps {
  name: string;
  headers: Record<string, EnhancedTableHeader>;
  data: IListingData;
  rowSettings?: ITableRowSettings;
  actions?: IEnhancedMenuItem[];
  inline?: boolean;
  inlineTitle?: string;
  pageContext?: string;
  dropdownActionsNames?: string[];
  defaultOrderByColumn?: string;
  filterableNames?: string[];
  splitCharacter?: string;
  orderByAscendingByDefault?: boolean;
  secondOrderByColumn?: string;
  secondOrderByAscendingByDefault?: boolean;
  disableSelection?: boolean;
  context?: IAbstractRecord;
  loader?: boolean;
  forceHideToolbar?: boolean;
  usePagination?: boolean;
  pageSizeOptions?: number[];
  onSelectionChange?: (selectedRows: string[]) => void;
  onPageChange: (
    page: number,
    searchKey?: string,
    rowsPerPage?: number,
    order?: string,
    orderBy?: string
  ) => Promise<void>;
}

const useStyles = makeStyles()(() => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    boxShadow: '0px 0px 6px #C7C7C7',
    margin: '0 auto',
  },
  toolbarPaper: {
    margin: '0 0 1em',
  },
  separator: {
    height: '2px !important',
    margin: '0 !important',
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  header: {
    margin: '0 0 1em',
    display: 'grid',
    gridTemplateAreas: `'title actions'`,
    gridGap: '1em',
  },
  title: {
    display: 'block',
    textAlign: 'left',
    fontSize: MAIN_ONE_THEME.typography.medium.med2.fontSize,
    fontFamily: mainFontFamilyMedium,
    letterSpacing: 0,
    color: MAIN_ONE_THEME.palette.primary1.main,
    fontWeight: 'normal',
    margin: '0 0 17.5px 0',
    gridArea: 'title',
    alignSelf: 'center',
    // textTransform: 'uppercase',
    width: '450px',
  },
  actionsContainer: {
    margin: '0 3px 0 auto',
  },
  action: {
    '& span': {
      margin: '0.5em auto 0',
    },
  },
  button: {
    fontSize: `${MAIN_ONE_THEME.typography.regular.reg2.fontSize}px!important`,
  },
  heightAndCenter: {
    height: '500px',
    justifyContent: 'center',
  },
}));

const ListingTableWithPagination: React.FC<
  IListingTableWithPaginationProps
> = ({
  name,
  headers,
  rowSettings,
  data,
  actions = [],
  inline,
  inlineTitle,
  pageContext,
  dropdownActionsNames = [],
  defaultOrderByColumn,
  orderByAscendingByDefault,
  secondOrderByColumn,
  secondOrderByAscendingByDefault,
  filterableNames,
  disableSelection,
  context,
  loader,
  onPageChange,
  pageSizeOptions,
}) => {
  const pageNumber = data.pageNumber - 1;
  const { classes } = useStyles();
  const tenant = useAppSelector((state) => state.tenant);
  const userInfo = useAppSelector((state) => state.user.info);
  const cdnUrl = tenant.cdnUrl;
  const dateFormat = 'MM/DD/YYYY';
  let filterDelay: NodeJS.Timeout;

  const currentFiltersAndOtherPayloadValues: IFilterModel =
    fetchWidgetMetaFromSession(userInfo, pageContext, name) || {};

  const orderPayloadObjectKey =
    (currentFiltersAndOtherPayloadValues.pagination &&
      Object.keys(currentFiltersAndOtherPayloadValues.pagination)[0]) ||
    '';

  const orderPayloadObject =
    (!isEmpty(orderPayloadObjectKey) &&
      currentFiltersAndOtherPayloadValues.pagination &&
      currentFiltersAndOtherPayloadValues.pagination[orderPayloadObjectKey] &&
      currentFiltersAndOtherPayloadValues.pagination[orderPayloadObjectKey]
        .OrderBy) ||
    {};

  // initiate the order & orderBy by the values of the session so that the useEffect does not trigger twice
  const [order, setOrder] = useState<TableSortOrder>(
    (orderPayloadObject &&
      Object.values(orderPayloadObject).length > 0 &&
      ((
        Object.values(orderPayloadObject)[0] as string
      ).toLowerCase() as TableSortOrder)) ||
      undefined
  );
  const [orderBy, setOrderBy] = useState<string>(
    Object.keys(orderPayloadObject)[0] || ''
  );
  const [selected, setSelected] = useState<string[]>([]);
  const [filtered, setFiltered] = useState(data.pagedItems);
  const [filterValue, setFilterValue] = useState('');
  const [filters, setFilters] = useState<Record<string, IListingTableFilter>>();
  const [forceClearSearchValueState, setForceClearSearchValueState] =
    useState<boolean>(false);
  const [namedFilters, setNamedFilters] = useState<IAbstractRecord>();
  const [selectedRecords, setSelectedRecords] = useState<
    ListingDataItemType<string>
  >({});
  const currentName = useRef<string>();

  const getFilterValues = useCallback(
    (filterName: string) => {
      const options: string[] = [];
      if (data.pagedItems.length === 0) {
        return [];
      }
      Object.values(data.pagedItems).forEach((item) => {
        if (!isEmpty(item[filterName]) && !options.includes(item[filterName])) {
          options.push(item[filterName]);
        }
      });
      return options;
    },
    [data.pagedItems]
  );

  useEffect(() => {
    if (filterableNames) {
      const newFilters: Record<string, IListingTableFilter> = {};
      filterableNames.forEach((key) => {
        let options = getFilterValues(key);
        if (
          headers &&
          headers[key] &&
          headers[key].type === EnhancedTableHeaderType.Date
        ) {
          options = options.map((option) =>
            FormattingServices.formatDate(option)
          );
        }
        if (options.length > 0) {
          newFilters[key] = {
            name: key,
            title: headers[key].title || key,
            options,
            selectedValues: [],
          };
        }
      });
      setFilters(newFilters);
    } else {
      setFilters(undefined);
    }

    setFiltered(data.pagedItems);
  }, [data.pagedItems, dateFormat, filterableNames, getFilterValues, headers]);

  // Watches data.pagedItems, headers and fiterValue, and recalculates when one changes
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    filterDelay = setTimeout(() => {
      void onPageChange(
        data.pageNumber,
        filterValue,
        data.pageSize,
        order,
        orderBy
      );
    }, 250);
    return () => {
      clearTimeout(filterDelay);
    };
  }, [filterValue]);

  useEffect(() => {
    if (currentName.current !== name && defaultOrderByColumn) {
      currentName.current = name;
      setOrder(orderByAscendingByDefault ? 'asc' : 'desc');
      setOrderBy(defaultOrderByColumn);
    }
  }, [
    defaultOrderByColumn,
    name,
    orderByAscendingByDefault,
    secondOrderByAscendingByDefault,
    secondOrderByColumn,
  ]);

  // compare namedFilters (last state i have before applying the new filters) with the new namedFilters (called current filters).
  // if they are not equal, that means the user has changed in filters, then remove the seach box content
  useEffect(() => {
    const currentFilters: IFilterModel = fetchWidgetMetaFromSession(userInfo);
    if (
      currentFilters != null &&
      currentFilters.namedFilters != null &&
      !isEqual(namedFilters, currentFilters.namedFilters)
    ) {
      setNamedFilters(currentFilters.namedFilters);
      // setSearchKeyState(undefined);
      setFilterValue('');
      setForceClearSearchValueState(true);
    } else {
      setForceClearSearchValueState(false);
    }
  }, [namedFilters, userInfo]);

  function handleRequestSort(
    event: React.MouseEvent<unknown>,
    property: string
  ) {
    const isDesc = orderBy === property && order === 'desc';
    const newOrder = isDesc ? 'asc' : 'desc';
    setOrder(newOrder);
    setOrderBy(property);
    void onPageChange(
      data.pageNumber,
      filterValue,
      data.pageSize,
      newOrder,
      property
    );
  }

  function handleSelectAllClick(rowName: string, checked: boolean) {
    if (checked) {
      // TODO: this will not be reached for now until we have BE Support
      setSelected(Object.keys(filtered));
      handleUpdateSelectedRecords(Object.keys(filtered));
      return;
    }
    setSelected([]);
    handleUpdateSelectedRecords([]);
  }

  function handleClick(rowName: string) {
    const selectedIndex = selected.indexOf(rowName);
    let newSelected: string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, rowName);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
    handleUpdateSelectedRecords(newSelected);
  }

  // eslint-disable-next-line no-shadow
  /*
  we need this function because when checking inside the EnhancedToolbar if the action is clickable, 
  we need to check all the maintained (selected) records 
  through all pages not only the filtered (not only the records showing on the current page)
  */
  function handleUpdateSelectedRecords(newSelected: string[]) {
    const selectedRecordsTemp = cloneDeep(selectedRecords);

    Object.values(newSelected).forEach((selectedRecordId) => {
      if (selectedRecordsTemp && filtered[selectedRecordId] !== undefined) {
        selectedRecordsTemp[selectedRecordId] = filtered[selectedRecordId];
      }
    });

    setSelectedRecords(selectedRecordsTemp);
  }

  const handleChange = (search: string) => {
    setFilterValue(search);
  };

  const isSelected = (key: string) => selected.indexOf(key) !== -1;

  const rowCount = data.totalCount || 0;
  const emptyRows = data.pageSize - Object.keys(filtered).length;

  const handleFilterChange = (
    filterName: string,
    field: string,
    value: boolean
  ) => {
    if (filters && filters[filterName]) {
      const row: Record<string, IListingTableFilter> = {};
      row[filterName] = cloneDeep(filters[filterName]);
      if (value) {
        row[filterName].selectedValues.push(field);
      } else {
        row[filterName].selectedValues = row[filterName].selectedValues.filter(
          (option) => option !== field
        );
      }
      setFilters({ ...filters, ...row });
    }
  };

  const handleFilterClear = () => {
    if (filters) {
      const newFilters = cloneDeep(filters);
      Object.values(newFilters).forEach((filter) => {
        // eslint-disable-next-line no-param-reassign
        filter.selectedValues = [];
      });
      setFilters(newFilters);
    }
  };

  const getRowsPerPageOptions = () => {
    return pageSizeOptions || [5, 10, 25];
  };

  const getLabelDisplayedRows = (pagination: LabelDisplayedRowsArgs) => {
    const { from, to, count } = pagination;
    return `${from}-${to} of ${count}`;
  };

  const getRows = () => {
    return convertRecordsIntoEnhancedRows(filtered);
  };

  const handlePageNumberChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ) => {
    void onPageChange(page + 1, filterValue, data.pageSize, order, orderBy);
  };

  function handleRowsPerPageChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void {
    const rowsNumber = Number(+event.target.value);
    void onPageChange(data.pageNumber, filterValue, rowsNumber, order, orderBy);
  }

  const renderToolbar = () => (
    <EnhancedTableToolbar
      dropdownActionsNames={dropdownActionsNames}
      selected={selected}
      actions={actions}
      data={selectedRecords}
      handleChange={handleChange}
      cdnUrl={cdnUrl}
      title={inlineTitle}
      onFilterClick={handleFilterChange}
      inlineFilters={filters}
      onFilterClear={handleFilterClear}
      context={context}
      forceClearSearchValue={forceClearSearchValueState}
    />
  );

  return (
    <div className={classes.root}>
      {!inline && (
        <Paper className={clsx(classes.paper, classes.toolbarPaper)}>
          {renderToolbar()}
        </Paper>
      )}
      <Paper className={classes.paper}>
        {inline && (
          <>
            {renderToolbar()}
            <Separator className={classes.separator} />
          </>
        )}
        <div className={classes.tableWrapper}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={Object.keys(filtered).length}
              headRows={headers}
              disableSelection={disableSelection}
              forceDisableSelectAll={Object.keys(selectedRecords).length === 0}
            />
            <EnhancedTableBody
              emptyRows={emptyRows}
              handleClick={handleClick}
              headers={headers}
              rowSettings={rowSettings}
              isSelected={isSelected}
              rows={getRows()}
              // currencySymbol={currencySymbol}
              dateFormat={dateFormat}
              // phoneNumberPattern={phoneNumberPattern}
              disableSelection={disableSelection}
              loader={loader}
            />
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={getRowsPerPageOptions()}
          component="div"
          count={rowCount}
          rowsPerPage={data.pageSize}
          page={pageNumber}
          backIconButtonProps={{
            'aria-label': 'Previous',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next',
          }}
          onPageChange={handlePageNumberChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          labelRowsPerPage=""
          labelDisplayedRows={getLabelDisplayedRows}
        />
      </Paper>
    </div>
  );
};

export default ListingTableWithPagination;
