import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getTreatyDetailsQuery } from './queries';
import Loader from '../../../../components/Loader';
import { toast } from 'react-toastify';
import ToastErrorMessage from '../../../../components/ToastErrorMessage';
import { DEFAULT_ERROR_TEXT } from '../../../../constants';
import StaticLayout from '../../../../page-layout/static-layout/StaticLayout';
import LeftPanel from './components/LeftPanel';
import Stepper from './components/Stepper';
import {
  ITreatyDetailsResponse,
  ITreatyLovs,
  ITreatyReinsurerResponse,
} from '.';
import TreatyDetailsWidget from './components/TreatyDetails/TreatyDetailsWidget';
import { toLookupsData } from './utils';
import { makeStyles } from 'tss-react/mui';
import ReinsurersWidget from './components/TreatyReinsurers/ReinsurersWidget';
import TreatyDocuments from './components/treaty-documents/TreatyDocuments';

const useStyles = makeStyles()(() => ({
  mainChildrenContainer: {
    marginTop: '18px',
    display: 'flex',
    alignItems: 'flex-start',
  },
  mainDetailsWidget: {
    width: '66%',
    display: 'flex',
    flexDirection: 'column',
    gap: '1em',
  },
  mainReinsurersWidget: {
    width: '33%',
  },
}));

const TreatyDetailsPage: React.FC = () => {
  const { classes } = useStyles();
  const { id: currentTreatyId } = useParams<{ id: string }>();
  const { refetch, data, error, loading } = useQuery(getTreatyDetailsQuery, {
    variables: {
      TreatyID: currentTreatyId,
    },
  });
  const [lovs, setLovs] = useState<ITreatyLovs>({
    currency: {},
    treatyType: {},
    treatySubType: {
      proportional: {},
      non_proportional: {},
    },
    category: {},
  });
  const [shouldSave, setShouldSave] = useState(false);

  useEffect(() => {
    if (data) {
      const lovs = toLookupsData(data);
      setLovs(lovs);
    }
  }, [data]);

  const renderMainChildren = (data: {
    GetTreatyDetails: ITreatyDetailsResponse[];
    GetTreatyReinsurers: ITreatyReinsurerResponse[];
  }) => {
    return (
      <>
        <Stepper
          isButtonDisabled={shouldSave}
          status={data?.GetTreatyDetails[0]?.reinsurance_Treaties_TreatyStatus}
        />
        <div className={classes.mainChildrenContainer}>
          <div className={classes.mainDetailsWidget}>
            <TreatyDetailsWidget
              currentTreatyId={currentTreatyId}
              data={data?.GetTreatyDetails[0]}
              lovs={lovs}
              shouldSave={shouldSave}
              setShouldSave={(v) => setShouldSave(v)}
            />
            <TreatyDocuments />
          </div>
          <div className={classes.mainReinsurersWidget}>
            <ReinsurersWidget
              isButtonDisabled={
                data?.GetTreatyDetails[0]?.reinsurance_Treaties_TreatyStatus?.toLowerCase() !==
                'new'
              }
              reinsurersList={data?.GetTreatyReinsurers}
              updatePage={async () => await refetch()}
            />
          </div>
        </div>
      </>
    );
  };

  if (error) {
    toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <StaticLayout
      name="Treaty Details"
      loading={loading}
      mainChildren={renderMainChildren(data?.Reinsurance?.queries)}
      leftChildren={
        <LeftPanel data={data?.Reinsurance?.queries?.GetTreatyDetails[0]} />
      }
    />
  );
};

export default TreatyDetailsPage;
