import {
  DynamicFormInputType,
  FormInputTypes,
} from '../../../../../../DynamicForm';
import { IAbstractRecord } from '../../../../../../models';

export const getInputs = (
  reinsurersList: IAbstractRecord
): Record<string, DynamicFormInputType> => ({
  reinsurer: {
    name: 'reinsurer',
    title: 'Additional Reinsurer*', // This is the default title
    dynamicTitle: ['Leader Reinsurer*'], // Dynamic title based on the index
    placeholder: 'Type to search for Reinsurer',
    type: FormInputTypes.chips,
    selectOptions: reinsurersList,
    value: '',
    error: '',
    required: true,
    multiple: false,
    disabled: false,
    hasPagination: false,
  },
  share: {
    name: 'share',
    title: 'Share*',
    placeholder: 'Enter Share',
    type: FormInputTypes.percentage,
    value: '',
    error: '',
    required: true,
  },
});
