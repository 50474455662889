import { isEmpty } from 'lodash';
import { IAbstractRecord } from '../../../../models';
import { IListingData } from '../../../../models/listing';
import {
  enumListAsRecordObject,
  lookupListAsRecordObject,
} from '../../../../utils/graph-utils';
import { ITreatiesResponse, ITreatiesTableData } from '.';
import { capitalizeFirstCharacter } from '../../../../utils/formatting-utils';
import { IActivityEntityBase } from '../../../../activities';

export function toLookupsData(data: IAbstractRecord) {
  const lovs: Record<string, Record<string, string>> = {
    treatyType: {},
    leaderReinsurer: {},
    currencyTitles: {},
    currencySymbols: {},
    status: {},
  };

  lovs.treatyType = enumListAsRecordObject(
    data?.Reinsurance_TreatyType?.enumValues
  );

  lovs.leaderReinsurer = lookupListAsRecordObject(
    data?.Reinsurance?.queries?.GetMainReinsurers,
    false,
    'mainReinsurerDetails_Id',
    'mainReinsurerDetails_FullName'
  );

  // generate two lovs for currency (one for title and one for symbol)
  data?.Core?.lookups?.currencies?.forEach(
    (element: { [key: string]: string }) => {
      lovs.currencyTitles[element?.Code] = element?.Title;
      lovs.currencySymbols[element?.Code] = element?.Symbol;
    }
  );

  lovs.status = enumListAsRecordObject(
    data?.Reinsurance_TreatyStatusList?.enumValues
  );

  return lovs;
}

export function mapToListingData(
  data: {
    items: ITreatiesResponse[];
    paging: { pageSize: number; pageNumber: number; totalCount: number };
  },
  treatyTypesLov: IAbstractRecord
): IListingData {
  if (isEmpty(data.items)) {
    return {
      pagedItems: {},
      pageSize: 10,
      pageNumber: 1,
      totalCount: 0,
    };
  }

  const list: Record<string, ITreatiesTableData> = {};

  data.items.forEach((item) => {
    list[item.reinsurance_Treaties_Id] = {
      treatyId: item.reinsurance_Treaties_Id,
      Reinsurance_Treaties_TreatyID: item.reinsurance_Treaties_TreatyID,
      Reinsurance_Treaties_TreatyName: item.reinsurance_Treaties_TreatyName,
      Reinsurance_Treaties_TreatyType:
        treatyTypesLov?.[item.reinsurance_Treaties_TreatyType],
      Reinsurance_Treaties_EffectiveDate:
        item.reinsurance_Treaties_EffectiveDate,
      Reinsurance_Treaties_ExpiryDate: item.reinsurance_Treaties_ExpiryDate,
      Reinsurance_Treaties_TreatyCurrency:
        item.reinsurance_Treaties_TreatyCurrency.Title,
      MainReinsurerDetails_FullName: item.mainReinsurerDetails_FullName,
      Reinsurance_Treaties_TreatyStatus: !isEmpty(
        item.reinsurance_Treaties_TreatyStatus
      )
        ? capitalizeFirstCharacter(
            item.reinsurance_Treaties_TreatyStatus.toLowerCase()
          )
        : null,
    };
  });

  return {
    pagedItems: list,
    pageSize: data?.paging?.pageSize,
    pageNumber: data?.paging?.pageNumber,
    totalCount: data?.paging?.totalCount,
  };
}

export function convertAuditTrailsToActivities(
  data: IAbstractRecord
): IActivityEntityBase[] {
  return data.Reinsurance.queries.GetTreatyAuditTrails.map(
    (auditTrail: Record<string, string>) => ({
      id: auditTrail.reinsurance_AuditTrail_Id,
      createdAt: new Date(auditTrail.reinsurance_AuditTrail_createdOn),
      htmlBody: auditTrail.reinsurance_AuditTrail_Message,
      iconUrl: extractIconUrl(auditTrail.reinsurance_AuditTrail_Message),
    })
  );
}

function extractIconUrl(html: string): string | undefined {
  const match = html.match(/background-image: url\('(.+?)'\)/);
  return match ? match[1] : undefined;
}
