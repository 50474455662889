import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { DynamicFormInputType, FormInputTypes } from '../DynamicForm';
import { ValidatorType } from '../DynamicForm/validators';
import { IAbstractRecord } from '../models';
import { isEmpty, isValidNumber } from './validationUtils';
import { isValidDate, removePlusFromMobileNumber } from './formatting-utils';

export function normaliseDynamicValues(
  allProperties: Record<string, DynamicFormInputType>,
  values: IAbstractRecord
): [IAbstractRecord, { file: File; name: string }[]] {
  const outputValues: IAbstractRecord = { ...values };
  const outputFiles: { file: File; name: string }[] = [];
  Object.values(allProperties).forEach((property) => {
    switch (property.type) {
      case FormInputTypes.text:
        outputValues[property.name] = outputValues[property.name]
          ? outputValues[property.name].trim()
          : '';
        break;
      case FormInputTypes.password:
        outputValues[property.name] = outputValues[property.name]
          ? outputValues[property.name].trim()
          : '';
        break;
      case FormInputTypes.imageFile: {
        if (outputValues[property.name]) {
          if (
            typeof outputValues[property.name] === 'string' ||
            outputValues[property.name] instanceof String
          ) {
            outputValues[property.name] = outputValues[property.name]
              ? outputValues[property.name].trim()
              : '';
          } else {
            const fileName = (outputValues[property.name] as File).name;
            outputFiles.push({
              file: outputValues[property.name],
              name: fileName,
            });
            delete outputValues[property.name];
          }
        }
        break;
      }
      default:
        break;
    }
  });
  return [outputValues, outputFiles];
}

export const validateDynamicForm = (
  value: any,
  input: DynamicFormInputType | undefined,
  values: Record<string, any>
): string | Record<string, string>[] => {
  if (input === undefined) {
    return '';
  }

  if (
    input.hidden ||
    (input.conditionalHidden && input.conditionalHidden(values))
  ) {
    return '';
  }

  if (
    (input.required ||
      (input.conditionalRequired && input.conditionalRequired(values))) &&
    isEmpty(value)
  ) {
    return 'Required';
  }

  if (
    value &&
    !(
      input.disabled ||
      (input.conditionalDisable && input.conditionalDisable(values))
    )
  ) {
    switch (input.type) {
      case FormInputTypes.text: {
        if (input.regex && !input.regex.test(value)) {
          return input.regexError || 'Invalid';
        } else if (
          input.minCharacters &&
          input.minCharacters > 0 &&
          value.length < input.minCharacters
        ) {
          return 'Min ' + input.minCharacters + ' characters';
        } else if (
          input.maxCharacters &&
          input.maxCharacters > 0 &&
          value.length > input.maxCharacters
        ) {
          return 'Max ' + input.maxCharacters + ' characters';
        } else if (input.multiline && input.isEditor && value.length > 1500) {
          return 'Max 1500 characters';
        } else if (!input.multiline && !input.isEditor && value.length > 100) {
          return 'Max 100 characters';
        } else if (input.multiline && !input.isEditor && value.length > 800) {
          return 'Max 800 characters';
        }
        break;
      }

      case FormInputTypes.password: {
        if (input.regex) {
          if (!input.regex.test(value)) {
            return input.regexError || 'Invalid';
          }
        }
        break;
      }

      case FormInputTypes.number: {
        if (input.isYear) {
          if (!isEmpty(input.minYear) && value < input.minYear) {
            return input.hasBetweenValidation
              ? `Value between ${input.minYear} and ${input.maxYear}`
              : `Please enter a number higher than ${input.minYear}`;
          } else if (!isEmpty(input.minYear) && value > input.maxYear) {
            return input.hasBetweenValidation
              ? `Value between ${input.minYear} and ${input.maxYear}`
              : `Please enter a number lower than ${input.maxYear}`;
          } else if (!isEmpty(input.regex) && !input.regex.test(`${value}`)) {
            return input.regexError || 'Invalid';
          }
        } else {
          if (!isEmpty(input.minNumber) && value < input.minNumber) {
            return input.hasBetweenValidation
              ? `Value between ${input.minNumber} and ${input.maxNumber}`
              : `Please enter a number higher than ${input.minNumber}`;
          } else if (!isEmpty(input.minNumber) && value > input.maxNumber) {
            return input.hasBetweenValidation
              ? `Value between ${input.minNumber} and ${input.maxNumber}`
              : `Please enter a number lower than ${input.maxNumber}`;
          } else if (!isEmpty(input.regex) && !input.regex.test(`${value}`)) {
            return input.regexError || 'Invalid';
          }
        }
        break;
      }

      case FormInputTypes.imageFile: {
        if (!isEmpty(value)) {
          if (
            isValidNumber(input.maxFileSizeInMB) &&
            input.maxFileSizeInMB > 0
          ) {
            const sizeMB = +((value as File).size / (1024 * 1024)).toFixed(2);
            if (sizeMB > input.maxFileSizeInMB) {
              return `Your file size is over limit. Max upload size ${
                input.maxFileSizeInMB * 1000
              } KB.`;
            }
          }
        }
        break;
      }

      case FormInputTypes.chips:
      case FormInputTypes.multiSelect: {
        if (
          input.minChoices &&
          input.minChoices > 0 &&
          input.maxChoices &&
          input.maxChoices > 0
        ) {
          if (
            value.length < input.minChoices ||
            value.length > input.maxChoices
          ) {
            return `Choose between ${input.minChoices} and ${input.maxChoices} choices`;
          }
        } else if (input.minChoices && input.minChoices > 0) {
          if (value.length < input.minChoices) {
            return `Please select only ${input.minChoices} or more choices`;
          }
        } else if (input.maxChoices && input.maxChoices > 0) {
          if (value.length > input.maxChoices) {
            return `Please select only ${input.maxChoices} or less choices`;
          }
        }
        break;
      }
      case FormInputTypes.phoneNumber: {
        value = removePlusFromMobileNumber(value);
        if (!isPossiblePhoneNumber(`+${value}`)) {
          return 'Invalid phone number';
        }
        break;
      }
      case FormInputTypes.date: {
        if (!isValidDate(value)) {
          return 'Invalid Date';
        }
        break;
      }
      case FormInputTypes.repeaterbox: {
        const result: Record<string, string>[] = [];

        value.forEach((item: Record<string, string>, index: number) => {
          result.push({});
          Object.keys(input.inputs).forEach((inputName) => {
            const inputError = validateDynamicForm(
              item[inputName],
              input.inputs[inputName],
              values
            );
            if (typeof inputError === 'string') {
              result[index][inputName] = inputError;
            }
          });
        });
        return result;
      }
    }

    let validatorError = '';
    if (input.validators) {
      input.validators.forEach((validator) => {
        if (!validatorError) {
          switch (validator.type) {
            case ValidatorType.Equal:
              if (!!value && value !== values[validator.compareTo]) {
                validatorError = validator.error;
              }
          }
        }
      });
    }

    if (!validatorError && input.customValidator) {
      return input.customValidator(values);
    }

    return validatorError;
  }

  return '';
};
