import { gql } from '@apollo/client';

export const userDetailsQuery = gql`
  query GetUserDetails {
    SalesforceManagement {
      queries {
        getCurrentUser {
          system_User_Id
          businessUser_Id
          businessUser_Code
          system_User_firstName
          system_User_lastName
          system_User_mobile
          system_User_email
          system_User_roles
          system_User_groups
          agent_AgentType
          agent_Id
          agent_AgencyID {
            id
          }
          agent_LinesOfBusiness {
            Code
          }
          agent_Products {
            Code
          }
          agent_EligiblePlans {
            Code
          }
          businessUser_AllowGroupPolicy
        }
      }
    }
    Insurance {
      lookups {
        linesOfBusiness {
          Code
          Title
          Active
        }
      }
    }
  }
`;

export const getAppVisibilitySettings = () => gql`
  mutation getAppVisibilitySettings {
    insurance {
      actions {
        getAppVisibilitySettings {
          IsPlanConfigurationEnabled
          IsSalesForceAppEnabled
        }
      }
    }
  }
`;
