import React, { useMemo } from 'react';
import { DEFAULT_ERROR_TEXT } from '../../constants';
import { IListingData } from '../../models/listing';
import { paymentTermsHeaders } from './content';
import { useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import ToastErrorMessage from '../../components/ToastErrorMessage';
import { mapToListingPaymentTermsData } from './utils';
import { getPolicyBillsThroughBusinessPartnerQuery } from './queries';
import ListingTable from '../../components/form-fields/table/ListingTable';

interface IPolicyPaymentTermsSchedule {
  businessPartnerId: string;
}

const BusinessPartnersPaymentTerms: React.FC<IPolicyPaymentTermsSchedule> = ({
  businessPartnerId,
}) => {
  const { data, error, loading } = useQuery(
    getPolicyBillsThroughBusinessPartnerQuery(),
    {
      variables: {
        businessPartnerId: businessPartnerId,
      },
    }
  );

  const tableData: IListingData = useMemo(() => {
    if (!data) {
      return { pagedItems: {}, pageSize: 5, pageNumber: 1, totalCount: 0 };
    }

    return mapToListingPaymentTermsData(data);
  }, [data]);

  if (error) {
    toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    return undefined;
  }

  return (
    <ListingTable
      inlineTitle="Payment Terms Schedule"
      name="paymentTermsSchedule"
      data={tableData}
      headers={paymentTermsHeaders}
      loader={loading}
      inline
      orderByAscendingByDefault
      disableSelection
      actions={[]}
    />
  );
};

export default BusinessPartnersPaymentTerms;
