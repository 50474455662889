/* eslint-disable prettier/prettier */
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { IInsuredData } from '../../form';
import NewChipsInput from '../../../../../../../components/enhanced-form/NewChipsInput';
import { getDefaultPersonListIfEmpty, getInsuredLov } from './utils';
import EnhancedInput from '../../../../../../../components/enhanced-form/EnhancedInput';
import { IListsType, ITabPanelProps } from '.';
import { Box } from '@mui/system';

const useStyles = makeStyles()(() => ({
  container: {
    width: '100%',
    backgroundColor: '#F9F9F9',
    border: '1px solid #E5E5E5',
    borderRadius: '4px',
    padding: '33px 23px 23px',
    marginBottom: '14px',
    position: 'relative',
  },
  fieldRow: {
    display: 'grid',
    gridTemplateColumns: `repeat(2, 49%)`,
    gap: '8px',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
  },
  label: {
    color: '#000000',
    margin: '0px!important',
    padding: '0px!important',
    marginBottom: '4px!important',
    fontSize: '14px !important',
    lineHeight: '16px !important',
    fontFamily: 'SourceSansPro-SemiBold !important',
  },
  labelHeader: {
    fontSize: '14px !important',
    lineHeight: '16px !important',
    fontFamily: 'SourceSansPro-SemiBold !important',
    marginBottom: '4px!important',
  },
  fullFieldRow: {
    gridColumn: '1 / -1',
    width: '100%',
  },
}));

interface InsuredRowProps extends ITabPanelProps {
  row: IInsuredData;
  rowIndex: number;
  insuredValues: IInsuredData[];
  insuredTouched: Record<string, boolean>[];
  insuredErrors: Record<string, string>[];
  onFieldUpdate: (
    field: string,
    value: string | string[],
    index: number
  ) => void;
  onFieldSearch: (field: string, value: string, index: number) => void;
  onFieldBlur: (field: string, index: number) => void;
  disabledForm: boolean;
  lists: IListsType;
  lovs: Record<string, Record<string, string>>;
  status: string;
  handleOpenDrawer: (type: string, index: number) => void;
  handleOpenAddressDrawer: (type: string, index: number) => void;
}

function TabPanel({ children, hidden, id, ...props }: ITabPanelProps) {
  return (
    <div role="tabpanel" hidden={hidden} id={id} {...props}>
      {!hidden && <Box sx={{ px: 0, pt: 0, py: 0 }}>{children}</Box>}
    </div>
  );
}

const InsuredRow: React.FC<InsuredRowProps> = ({
  hidden,
  row,
  rowIndex,
  insuredValues,
  insuredTouched,
  insuredErrors,
  status,
  onFieldUpdate,
  onFieldSearch,
  onFieldBlur,
  disabledForm,
  lists,
  lovs,
  handleOpenDrawer,
  handleOpenAddressDrawer,
}) => {
  const { classes } = useStyles();

  return (
    <TabPanel
      hidden={hidden}
      key={`insured-tabpanel-${rowIndex}`}
      aria-labelledby={`insured-tab-${rowIndex}`}
    >
      <div className={classes.container} key={rowIndex}>
        <div className={classes.fieldRow}>
          <NewChipsInput
            key="id"
            name="id"
            title="Insured*"
            placeholder="Insured"
            value={insuredValues[rowIndex].id}
            error={
              insuredTouched[rowIndex].id ? insuredErrors[rowIndex].id : ''
            }
            onChange={(v) => {
              const value = Array.isArray(v) ? v[0] : v;
              onFieldUpdate('id', value, rowIndex);
            }}
            onSearch={async (v) => {
              const value = Array.isArray(v) ? v[0] : v;
              onFieldSearch('id', value, rowIndex);
            }}
            clearValueOnSearch={false}
            disabled={disabledForm}
            items={getDefaultPersonListIfEmpty(
              lists?.id[rowIndex],
              insuredValues[rowIndex]
            )}
            required={true}
            multiple={false}
            material={true}
            supportLink={disabledForm ? '' : 'Add Person'}
            onLinkClick={(e) => {
              e.preventDefault();
              handleOpenDrawer('insured', rowIndex);
            }}
            customStyles={{
              labelStyles: classes.label,
            }}
          />
          {row.isPrincipal ? (
            <NewChipsInput
              key="address"
              name="address"
              title="Insured Address*"
              placeholder="Insured Address"
              value={insuredValues[rowIndex].address}
              clearValueOnSearch={false}
              error={
                insuredTouched[rowIndex].address
                  ? insuredErrors[rowIndex].address
                  : ''
              }
              onChange={(v) => onFieldUpdate('address', v, rowIndex)}
              disabled={disabledForm}
              items={lists.insuredAddresses[rowIndex]}
              required={true}
              multiple={false}
              material={true}
              supportLink={disabledForm ? '' : 'Add Address'}
              onLinkClick={(e) => {
                e.preventDefault();
                handleOpenAddressDrawer('insured', rowIndex);
              }}
              customStyles={{
                labelStyles: classes.label,
              }}
            />
          ) : (
            <NewChipsInput
              key="relation"
              name="relation"
              title="Relation*"
              placeholder="Relation"
              value={insuredValues[rowIndex].relation}
              clearValueOnSearch={false}
              error={
                insuredTouched[rowIndex].relation
                  ? insuredErrors[rowIndex].relation
                  : ''
              }
              onChange={(v) => onFieldUpdate('relation', v, rowIndex)}
              disabled={disabledForm}
              items={getInsuredLov(row, lovs, insuredValues)}
              required={true}
              multiple={false}
              material={true}
              customStyles={{
                labelStyles: classes.label,
              }}
            />
          )}
          <EnhancedInput
            key="cardNumber"
            name="cardNumber"
            title="Card Number"
            placeholder="Card Number"
            value={insuredValues[rowIndex].cardNumber}
            error={
              insuredTouched[rowIndex].cardNumber
                ? insuredErrors[rowIndex].cardNumber
                : ''
            }
            onBlur={() => onFieldBlur('cardNumber', rowIndex)}
            onChange={(v) =>
              onFieldUpdate('cardNumber', v.target.value, rowIndex)
            }
            disabled={
              ['CLOSED_WON', 'CLOSED_LOST'].includes(status) || disabledForm
            }
            type={''}
            material={true}
            customStyles={{
              labelStyles: classes.labelHeader,
            }}
          />

          <EnhancedInput
            key="exclusions"
            name="exclusions"
            title="Exclusions"
            placeholder="Exclusions"
            value={insuredValues[rowIndex].exclusions}
            error={
              insuredTouched[rowIndex].exclusions
                ? insuredErrors[rowIndex].exclusions
                : ''
            }
            onBlur={() => onFieldBlur('exclusions', rowIndex)}
            onChange={(v) =>
              onFieldUpdate('exclusions', v.target.value, rowIndex)
            }
            disabled={
              ['CLOSED_WON', 'CLOSED_LOST'].includes(status) || disabledForm
            }
            type={''}
            className={classes.fullFieldRow}
            material={true}
            customStyles={{
              labelStyles: classes.labelHeader,
            }}
          />

          <EnhancedInput
            key="remarks"
            name="remarks"
            title="Remarks"
            placeholder="Remarks"
            value={insuredValues[rowIndex].remarks}
            error={
              insuredTouched[rowIndex].remarks
                ? insuredErrors[rowIndex].remarks
                : ''
            }
            onBlur={() => onFieldBlur('remarks', rowIndex)}
            onChange={(v) => onFieldUpdate('remarks', v.target.value, rowIndex)}
            disabled={
              ['CLOSED_WON', 'CLOSED_LOST'].includes(status) || disabledForm
            }
            type={''}
            className={classes.fullFieldRow}
            material={true}
            customStyles={{
              labelStyles: classes.labelHeader,
            }}
          />
        </div>
      </div>
    </TabPanel>
  );
};

export default InsuredRow;
