import { gql } from '@apollo/client';

export const getReinsurers = gql`
  query ReinsurersList(
    $SelectedCompany: String!
    $SelectedReinsurerType: [String]
    $IsLocal: Boolean
    $Status: [String]
    $KeywordSearch: String
    $OrderByField: String
    $DescendingField: Boolean
    $pagination: PaginationInput!
  ) {
    Reinsurance {
      queries {
        ReinsurersList(
          SelectedCompany: $SelectedCompany
          SelectedReinsurerType: $SelectedReinsurerType
          IsLocal: $IsLocal
          Status: $Status
          KeywordSearch: $KeywordSearch
          OrderByField: $OrderByField
          DescendingField: $DescendingField
          pagination: $pagination
        ) {
          items {
            salesforceManagement_BusinessPartner_Id
            salesforceManagement_BusinessPartner_Code
            salesforceManagement_BusinessPartner_FullName
            salesforceManagement_BusinessPartner_Category {
              Code
              Title
            }
            salesforceManagement_BusinessPartner_RegisterNumber
            salesforceManagement_BusinessPartner_Account
            salesforceManagement_BusinessPartner_Status
            personDetails_Comments
          }
          paging {
            pageSize
            pageNumber
            totalCount
          }
        }
      }
    }
    SalesforceManagement {
      lookups {
        businessPartnerCategories {
          Title
          Code
          BusinessPartnerType
        }
      }
    }
    SalesforceManagement_BusinessPartnerStatuses: __type(
      name: "SalesforceManagement_BusinessPartnerStatuses"
    ) {
      name
      enumValues {
        name
      }
    }
  }
`;
