import { IPaymentTermGenericTableData } from '.';
import { IAbstractRecord } from '../../../../../models';
import { IListingData } from '../../../../../models/listing';
import { isEmpty } from '../../../../../utils/validationUtils';

export function mapToListingData(data: IAbstractRecord): IListingData {
  const bills = data.Accounting.queries.GetPolicyBills || [];
  const list: Record<string, IPaymentTermGenericTableData> = {};

  const outstandingPayable = bills.reduce(
    (total: number, bill: IAbstractRecord) => {
      return total + bill.payableBills_OutstandingBalance;
    },
    0
  );
  const payableAmountReceived = bills.reduce(
    (total: number, bill: IAbstractRecord) => {
      return total + bill.payablePayment_AmountReceived;
    },
    0
  );
  const taxOnCommission = bills.reduce(
    (total: number, bill: IAbstractRecord) => {
      return total + bill.payableBills_TaxOnCommission;
    },
    0
  );

  bills?.forEach((obj: IPaymentTermGenericTableData) => {
    list[obj.accounting_Bills_Id] = {
      id: obj.accounting_Bills_Id,
      accounting_Bills_Id: obj.accounting_Bills_Id,
      accounting_Bills_BillNumber: obj.accounting_Bills_BillNumber,
      accounting_Bills_DueDate: obj.accounting_Bills_DueDate,
      accounting_Bills_TotalPremium: obj.accounting_Bills_TotalPremium,
      receivablePayment_AmountReceived: obj.receivablePayment_AmountReceived,
      accounting_Bills_AmountDue: obj.accounting_Bills_AmountDue,
      accounting_Bills_OutstandingBalance:
        obj.accounting_Bills_OutstandingBalance,
      accounting_Bills_TotalCommission: obj.accounting_Bills_TotalCommission,
      payableBills_TaxOnCommission: isEmpty(obj.payableBills_TaxOnCommission)
        ? 0
        : taxOnCommission,
      payablePayment_AmountReceived: isEmpty(obj.payablePayment_AmountReceived)
        ? 0
        : payableAmountReceived,
      payableBills_OutstandingBalance: isEmpty(
        obj.payableBills_OutstandingBalance
      )
        ? 0
        : outstandingPayable,
      totalBalance:
        Number(obj.accounting_Bills_OutstandingBalance) - outstandingPayable,
      Currency: obj.accounting_Bills_Currency?.Symbol,
    };
  });

  return {
    pagedItems: list,
    pageSize: 5,
    pageNumber: 1,
    totalCount: Object.keys(list).length,
  };
}
