import { ITabsLayout, LayoutType } from '../../../../page-layout';

export const productionPolicyPage: ITabsLayout = {
  typeLogicalId: LayoutType.tabslayout,
  leftColumn: {
    backgroundColor: '#FFFFFF',
    collapsable: false,
    width: 2,
    collapsed: false,
  },
  mainColumn: {
    backgroundColor: '#F7F7F7',
    collapsable: false,
    width: 10,
    collapsed: false,
  },
  rightColumn: {
    backgroundColor: '#FFFFFF',
    collapsable: false,
    width: 0,
    collapsed: false,
  },
  tabs: [
    {
      name: 'summary',
      title: 'Summary',
      iconUrl: '/icons/file-summary-primary.svg',
      widgets: [{}],
    },
    {
      name: 'policyPersons',
      title: 'Policy Persons',
      iconUrl: '/icons/file-summary-primary.svg',
      widgets: [{}],
    },
    {
      name: 'policyDocuments',
      title: 'Documents',
      iconUrl: '/icons/folder-primary.svg',
      widgets: [{}],
    },
    {
      name: 'paymentTerms',
      title: 'Payment Terms',
      iconUrl: '/icons/file-summary-primary.svg',
      widgets: [{}],
    },
  ],
};

export const productionGroupPolicyPage: ITabsLayout = {
  typeLogicalId: LayoutType.tabslayout,
  leftColumn: {
    backgroundColor: '#FFFFFF',
    collapsable: false,
    width: 2,
    collapsed: false,
  },
  mainColumn: {
    backgroundColor: '#F7F7F7',
    collapsable: false,
    width: 10,
    collapsed: false,
  },
  rightColumn: {
    backgroundColor: '#FFFFFF',
    collapsable: false,
    width: 0,
    collapsed: false,
  },
  tabs: [
    {
      name: 'summary',
      title: 'Summary',
      iconUrl: '/icons/file-summary-primary.svg',
      widgets: [{}],
    },
    {
      name: 'policyPersons',
      title: 'Policy Persons',
      iconUrl: '/icons/file-summary-primary.svg',
      widgets: [{}],
    },
    {
      name: 'certificates',
      title: 'Certificates',
      iconUrl: '/icons/file-summary-primary.svg',
      widgets: [{}],
    },
    {
      name: 'policyDocuments',
      title: 'Documents',
      iconUrl: '/icons/folder-primary.svg',
      widgets: [{}],
    },
    {
      name: 'paymentTerms',
      title: 'Payment Terms',
      iconUrl: '/icons/file-summary-primary.svg',
      widgets: [{}],
    },
  ],
};
