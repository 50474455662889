import dayjs from 'dayjs';
import {
  FileUploadStatus,
  IFileCardProps,
} from '../../../../../../models/file';
import { getDefaultDate } from '../../../../../../utils/date-utils';
import { ITreatyDocumentResponse } from '.';

export const mapFileToDocumentWidgetFiles = (
  documentsData: ITreatyDocumentResponse[]
): IFileCardProps[] => {
  const docsList: IFileCardProps[] = [];

  if (documentsData && documentsData.length > 0) {
    documentsData.map((document) => {
      docsList.push({
        title: document?.reinsurance_TreatyDocuments_DocumentName,
        version: document?.reinsurance_TreatyDocuments_Version,
        status: FileUploadStatus.Uploaded,
        allowedFileTypes: [],
        entityViewId: 'Reinsurance-all',
        propertyId: 'Document',
        entityId:
          document?.reinsurance_TreatyDocuments_Document?.path?.split('/')[2],
        entityTypeId: 'Reinsurance-TreatyDocuments',
        hideReviewedLabel: true,
        createdOn:
          getDefaultDate(
            dayjs(
              new Date(document?.reinsurance_TreatyDocuments_createdOn)
            )?.toDate()
          )?.toDateString() || '',
        fileInfo: {
          contentType:
            document?.reinsurance_TreatyDocuments_Document?.contentType,
          fileName: document?.reinsurance_TreatyDocuments_Document?.fileName,
          id: document?.reinsurance_TreatyDocuments_Document?.id,
          length: document?.reinsurance_TreatyDocuments_Document?.length,
          location: document?.reinsurance_TreatyDocuments_Document?.location,
          path: document?.reinsurance_TreatyDocuments_Document?.path,
        },
      });
    });
  }
  return docsList;
};
