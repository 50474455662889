import { makeStyles } from 'tss-react/mui';
import React, { useCallback } from 'react';
import clsx from 'clsx';
import EnhancedInputsWrapper from './EnhancedInputsWrapper';
import { isEmpty, isValidNumber } from '../../utils/validationUtils';
import {
  formatWithCommas,
  trimTrailingZeros,
} from '../../utils/formatting-utils';
import { MAIN_ONE_THEME, contentFontFamilyRegular } from '../../constants';
import { IEnhancedFormInputBaseProps } from '.';

export interface ICurrencyFormFieldProps extends IEnhancedFormInputBaseProps {
  value: string | number;
  currencySymbol?: string;
  maxDecimalPrecision?: number;
  minValue?: number;
  maxValue?: number;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  classes?: {
    inputContainer?: string;
    input?: string;
    currencySymbol?: string;
  };
}

const useStyles = makeStyles()(() => ({
  inputContainer: {
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    margin: '0 auto',
    height: 35,
    width: '100%',
    fontFamily: contentFontFamilyRegular,
    backgroundColor: MAIN_ONE_THEME.palette.secondary4.main,
    border: `1px solid ${MAIN_ONE_THEME.palette.secondary3.main}`,
    borderRadius: '5px',
  },
  inputError: {
    borderColor: `${MAIN_ONE_THEME.palette.error.main} !important`,
  },
  inputDisabled: {
    cursor: 'not-allowed',
    color: 'rgba(0, 0, 0, 0.38)',
  },
  inlineContainer: {
    position: 'relative',
    fontFamily: contentFontFamilyRegular,
  },
  currencySymbol: {
    fontSize: MAIN_ONE_THEME.typography.regular.reg2.fontSize,
    fontFamily: contentFontFamilyRegular,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'fit-content',
    padding: '0 7px',
  },
  input: {
    fontFamily: contentFontFamilyRegular,
    fontSize: MAIN_ONE_THEME.typography.regular.reg2.fontSize,
    lineHeight: '15px',
    height: 35,
    width: '100%',
    padding: '0 15px',
    margin: '0',
    backgroundColor: 'transparent',
    border: 'none',
    borderRadius: '5px',
    '&:disabled': {
      cursor: 'not-allowed',
      color: 'rgba(0, 0, 0, 0.38)',
    },
    '&::placeholder': {
      fontStyle: 'italic',
      fontFamily: contentFontFamilyRegular,
      color: '#33333333',
      opacity: 1,
      fontSize: '14px',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  disabledCurrencySymbol: {
    color: 'rgba(0, 0, 0, 0.38)!important',
  },
}));

const CurrencyFormField: React.FC<ICurrencyFormFieldProps> = ({
  inputOnly,
  hideError,
  error,
  name,
  title,
  value,
  placeholder,
  disabled,
  maxDecimalPrecision = 3,
  currencySymbol,
  onChange,
  onFocus,
  onBlur,
  minValue,
  maxValue,
  classes = {},
}) => {
  const { classes: inputClasses } = useStyles();

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let newValue = event.currentTarget.value.replaceAll(',', '');

      // Check if the input has more than one decimal point
      const decimalCount = (newValue.match(/\./g) || []).length;
      if (decimalCount > 1) {
        return; // Ignore the input if there's more than one decimal
      }

      // Check if there are non-digit characters (except for one decimal point)
      if (!/^\d*\.?\d*$/.test(newValue)) {
        return; // Ignore input if it doesn't match the allowed pattern
      }

      if (isNaN(parseFloat(newValue))) {
        newValue = '';
      } else {
        if (newValue.includes('.')) {
          const [integerPart, fractionalPart] = newValue.split('.');
          if (fractionalPart.length > maxDecimalPrecision) {
            newValue = `${integerPart}.${fractionalPart.substring(0, maxDecimalPrecision)}`;
          }
        }
      }

      if (isValidNumber(minValue) && parseFloat(newValue) < minValue) {
        newValue = minValue.toString();
      } else if (isValidNumber(maxValue) && parseFloat(newValue) > maxValue) {
        newValue = maxValue.toString();
      }
      onChange({
        ...event,
        target: {
          ...event.target,
          value: newValue,
          name: event.target.name,
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [minValue, maxValue, onChange]
  );

  const limitNumber = useCallback(
    (targetValue: string) => {
      let newValue = targetValue.replaceAll(',', '');
      if (
        Number(maxDecimalPrecision) >= 0 &&
        isValidNumber(maxDecimalPrecision)
      ) {
        newValue = trimTrailingZeros(newValue, maxDecimalPrecision).toString();
      }
      return newValue;
    },
    [maxDecimalPrecision]
  );

  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      const newValue = parseFloat(limitNumber(event.target.value));
      // if (isValidNumber(minValue) && newValue < minValue) {
      //   newValue = minValue;
      // }
      // if (isValidNumber(maxValue) && newValue > maxValue) {
      //   return;
      // }
      onBlur({
        ...event,
        relatedTarget: null,
        target: {
          ...event.target,
          value: isNaN(newValue) ? '' : newValue.toString(),
          name: event.target.name,
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [limitNumber, minValue, maxValue, onBlur]
  );

  const renderInput = () => {
    return (
      <div
        className={clsx(inputClasses.inputContainer, classes?.inputContainer, {
          [inputClasses.inputError]: error,
          [inputClasses.inputDisabled]: disabled,
        })}
      >
        {currencySymbol && (
          <div
            className={clsx(
              inputClasses.currencySymbol,
              classes?.currencySymbol,
              {
                [inputClasses.disabledCurrencySymbol]: disabled,
              }
            )}
          >
            {currencySymbol}
          </div>
        )}
        <input
          className={clsx(inputClasses.input, classes.input)}
          title={disabled && !isEmpty(value) ? value?.toString() : title}
          id={name}
          name={name}
          aria-invalid={!!error}
          aria-describedby={`errorMsg-${name}`}
          value={formatWithCommas(value?.toString())}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={onFocus}
          type="text"
          disabled={disabled}
          placeholder={placeholder}
          min={minValue}
          max={maxValue}
        />
      </div>
    );
  };

  return inputOnly ? (
    <span className={inputClasses.inlineContainer}>{renderInput()}</span>
  ) : (
    <EnhancedInputsWrapper
      title={title}
      error={error}
      name={name}
      hideError={hideError}
    >
      {renderInput()}
    </EnhancedInputsWrapper>
  );
};

export default CurrencyFormField;
