import {
  EnhancedTableHeader,
  EnhancedTableHeaderType,
} from '../../../../components/enhanced-table';

export const headers: Record<string, EnhancedTableHeader> = {
  PolicyNumber: {
    name: 'PolicyNumber',
    title: 'Policy #',
    type: EnhancedTableHeaderType.Link,
    urlTemplate: '/production/policies/{Id}',
    openUrlInNewTab: false,
  },
  TotalPremium: {
    name: 'TotalPremium',
    title: 'Total Premium',
    type: EnhancedTableHeaderType.RoundedCurrency,
  },
  AmountReceived: {
    name: 'AmountReceived',
    title: 'Amount Received',
    type: EnhancedTableHeaderType.RoundedCurrency,
  },
  TotalCommission: {
    name: 'TotalCommission',
    title: 'Commission',
    type: EnhancedTableHeaderType.RoundedCurrency,
  },
  TaxOnCommission: {
    name: 'TaxOnCommission',
    title: 'Tax On Commission',
    type: EnhancedTableHeaderType.RoundedCurrency,
  },
  AmountPaid: {
    name: 'AmountPaid',
    title: 'Amount Paid',
    type: EnhancedTableHeaderType.RoundedCurrency,
  },
  OutstandingPayable: {
    name: 'OutstandingPayable',
    title: 'Total Balance',
    type: EnhancedTableHeaderType.RoundedCurrency,
  },
};

export const receivableBillsHeaders: Record<string, EnhancedTableHeader> = {
  BillNumber: {
    name: 'BillNumber',
    title: 'Bill #',
    type: EnhancedTableHeaderType.Text,
  },
  DueDate: {
    name: 'DueDate',
    title: 'Due Date',
    type: EnhancedTableHeaderType.Date,
  },
  NetPremium: {
    name: 'NetPremium',
    title: 'Net Premium',
    type: EnhancedTableHeaderType.RoundedCurrency,
  },
  TotalPremium: {
    name: 'TotalPremium',
    title: 'Total Premium',
    type: EnhancedTableHeaderType.RoundedCurrency,
  },
  AmountDue: {
    name: 'AmountDue',
    title: 'Amount Due',
    type: EnhancedTableHeaderType.RoundedCurrency,
  },
  AmountReceived: {
    name: 'AmountReceived',
    title: 'Amount Received',
    type: EnhancedTableHeaderType.RoundedCurrency,
  },
  OutstandingBalance: {
    name: 'OutstandingBalance',
    title: 'Outstanding Amount',
    type: EnhancedTableHeaderType.RoundedCurrency,
  },
  BillStatus: {
    name: 'BillStatus',
    title: 'Status',
    type: EnhancedTableHeaderType.Text,
  },
};

export const payableBillsHeaders: Record<string, EnhancedTableHeader> = {
  BillNumber: {
    name: 'BillNumber',
    title: 'Bill #',
    type: EnhancedTableHeaderType.Text,
  },
  DueDate: {
    name: 'DueDate',
    title: 'Due Date',
    type: EnhancedTableHeaderType.Date,
  },
  Discount: {
    name: 'Discount',
    title: 'Discount',
    type: EnhancedTableHeaderType.RoundedCurrency,
  },
  TotalCommission: {
    name: 'TotalCommission',
    title: 'Commission',
    type: EnhancedTableHeaderType.RoundedCurrency,
  },
  TaxOnCommission: {
    name: 'TaxOnCommission',
    title: 'Tax On Commission',
    type: EnhancedTableHeaderType.RoundedCurrency,
  },
  AmountReceived: {
    name: 'AmountReceived',
    title: 'Amount Paid',
    type: EnhancedTableHeaderType.RoundedCurrency,
  },
  OutstandingBalance: {
    name: 'OutstandingBalance',
    title: 'Outstanding Amount',
    type: EnhancedTableHeaderType.RoundedCurrency,
  },
  BillStatus: {
    name: 'BillStatus',
    title: 'Status',
    type: EnhancedTableHeaderType.Text,
  },
};
