import { isValidDate } from '../utils/date-utils';
import {
  dateToISOString,
  getDateWithoutTime,
  trimTrailingZeros,
} from '../utils/formatting-utils';
import { isEmpty, isValidNumber } from '../utils/validationUtils';

export interface ILocalizationConfig {
  Country: string;
  PhoneCountry: string;
  Currency: {
    countryCode: string;
    currency: string;
    addSpace?: boolean;
  };
  Date: {
    countryCode: string;
    dateStyle: string;
    timeStyle: string;
    options?: {
      day: string;
      month: string;
      year: string;
    };
  };
}

export default class FormattingServices {
  private static config: ILocalizationConfig = {
    Country: 'us',
    PhoneCountry: 'US',
    Currency: {
      countryCode: 'en-US',
      currency: 'USD',
    },
    Date: {
      countryCode: 'en-US',
      dateStyle: 'long',
      timeStyle: 'medium',
    },
  };

  public static async setConfig(config: ILocalizationConfig) {
    if (!this.config) {
      this.config = config;
    }
  }

  public static formatDate(value: string | Date) {
    const { dateStyle, options } = this.config.Date;

    if (isEmpty(value) || !isValidDate(value)) {
      return '';
    }

    let dateValue = '';
    if (typeof value === 'string') {
      dateValue = this.formatDateString(value);
    } else if (value instanceof Date) {
      dateValue = getDateWithoutTime(dateToISOString(value));
    }

    if (!dateValue) {
      return '';
    }

    const date = new Date(dateValue);

    date.setMinutes(date.getMinutes() + date.getTimezoneOffset());

    if (this.config.Date.options) {
      return new Intl.DateTimeFormat(
        this.config.Date.countryCode,
        options as any
      ).format(date);
    }

    return new Intl.DateTimeFormat(this.config.Date.countryCode, {
      dateStyle: dateStyle as any,
    }).format(date);
  }

  public static formatDateTime(value: string | Date) {
    const { dateStyle, options, timeStyle } = this.config.Date;

    if (isEmpty(value) || !isValidDate(value)) {
      return '';
    }

    const date = new Date(value);

    const newOptions = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
      ...options,
    };

    if (this.config.Date.options) {
      return new Intl.DateTimeFormat(
        this.config.Date.countryCode,
        newOptions as any
      ).format(date);
    }

    return new Intl.DateTimeFormat(this.config.Date.countryCode, {
      dateStyle: dateStyle as any,
      timeStyle: timeStyle as any,
    }).format(date);
  }

  public static formatDateDependingOnTime(value: string | Date) {
    let ensuredValue = value;

    // Temp pending support from BE
    if (typeof value === 'string') {
      ensuredValue = this.ensureDatetimeZone(value);
    }

    const { dateStyle, options } = this.config.Date;

    if (isEmpty(ensuredValue) || !isValidDate(ensuredValue)) {
      return '';
    }

    const date = new Date(ensuredValue);

    if (this.config.Date.options) {
      return new Intl.DateTimeFormat(
        this.config.Date.countryCode,
        options as any
      ).format(date);
    }

    return new Intl.DateTimeFormat(this.config.Date.countryCode, {
      dateStyle: dateStyle as any,
    }).format(date);
  }

  public static formatCustomCurrency(
    numberValue: number,
    customCurrencySymbol: string = this.config.Currency.currency,
    decimals = 2,
    shouldTrimTrailingZeros = false,
    countryCode: string = this.config.Currency.countryCode
  ): string {
    let formattedNumber = new Intl.NumberFormat(countryCode, {
      style: 'decimal', // Use 'decimal' since 'currency' expects a valid currency code
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    }).format(numberValue);

    if (shouldTrimTrailingZeros) {
      formattedNumber =
        trimTrailingZeros(formattedNumber, decimals, true).toString() || '0';
    }
    // Manually append the custom currency symbol
    return `${customCurrencySymbol} ${formattedNumber}`;
  }

  public static formatCurrencyNumber(
    value: any,
    decimals = 2,
    currency: string = this.config.Currency.currency,
    countryCode: string = this.config.Currency.countryCode
  ) {
    if (isEmpty(value) || !isValidNumber(value)) {
      return '';
    }

    const numberValue = Number(value);
    const result = new Intl.NumberFormat(countryCode, {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    }).format(numberValue);

    return result;
  }

  public static formatCurrencyNumberMillion(
    value: any,
    decimals = 2,
    currencySymbol: string
  ) {
    const { countryCode, currency } = this.config.Currency;

    if (isEmpty(value) || !isValidNumber(value)) {
      return '';
    }

    let numberValue = Number(value);
    numberValue /= 1000000;

    let result = new Intl.NumberFormat(countryCode, {
      style: 'currency',
      currency: currency,
      currencyDisplay: 'symbol',
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    }).format(numberValue);

    result = result.replace(currencySymbol, 'M' + currencySymbol);

    return result;
  }

  private static formatDateString(dateString: string): string {
    // Check if the dateString is an ISO format or ISO-like format
    if (
      /^\d{4}-\d{2}-\d{2}(T|\s)\d{2}:\d{2}:\d{2}(?:\.\d+)?(?:Z|[+-]\d{2}:\d{2})?$/.test(
        dateString
      )
    ) {
      // Extract the date part and return
      return dateString.slice(0, 10);
    }
    // Check if the dateString is in the mm/dd/yyyy format
    else if (/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString)) {
      const [month, day, year] = dateString
        .split('/')
        .map((part) => parseInt(part, 10));
      return `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
    }
    // If neither format matches, return null or throw an error as you see fit

    return dateString;
  }

  private static ensureDatetimeZone(dateString: string): string {
    // Check if it's ISO or ISO-like format and doesn't have a timezone indicator at the end
    if (
      /^\d{4}-\d{2}-\d{2}(T|\s)\d{2}:\d{2}:\d{2}(?:\.\d+)?(?![Zz]|[+-]\d{2}:\d{2})$/.test(
        dateString
      )
    ) {
      return dateString + 'Z';
    }
    return dateString;
  }
}
