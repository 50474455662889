import { IPlan, initialValues } from '.';
import { quote } from '../../components/custom/QuotesCards';
import { isValidDate } from '../../utils/date-utils';
import {
  enumListAsRecordObject,
  lookupListAsRecordObject,
} from '../../utils/graph-utils';
import {
  isEmpty,
  isValidEmail,
  validatePhoneNumber,
} from '../../utils/validationUtils';
import { steps } from './content';

export function entityToApplicationDetails(data: any): any {
  const applicationInfo =
    data?.Insurance?.entities?.application?.views?.Insurance_all?.properties;
  const motorDetails =
    data?.Insurance?.entities?.application?.views?.Insurance_all?.properties
      ?.MotorDetailsID?.views?.Insurance_all?.properties;
  const customerDetails =
    data?.Insurance?.entities?.application?.views?.Insurance_all?.properties
      ?.ApplicationContactDetailsID?.views?.Insurance_all?.properties;
  const planDetails: Record<string, string | number>[] = [];
  data?.Insurance?.entities?.application?.views?.Insurance_all?.properties?.MotorDetailsID?.queries?.insurance?.plans?.forEach(
    (element: IPlan) => {
      planDetails.push({
        planId: element?.input_Id,
        name: element?.quote_Name,
        product: element?.input_Product?.Code,
        towingService: element?.input_TowingService?.Id,
        vignetteCode: element?.input_VignetteCode,
        vignette: element?.input_Vignette,
        coverageType: element?.input_CoverageType,
        carValue: element?.input_CarValue,
        includeAgencyRepair: element?.input_IncludeAgencyRepair,
      });
    }
  );

  return {
    applicationName: applicationInfo?.ApplicationName,
    effectiveDate: applicationInfo?.EffectiveDate,
    expiryDate: applicationInfo?.ExpiryDate,
    isRenewal: applicationInfo?.IsRenewal || initialValues.isRenewal,
    renewalNumber: applicationInfo?.RenewalNumber || '',
    plateCode: motorDetails?.PlateCode?.Id || '',
    plateNumber: motorDetails?.PlateNumber || '',
    yearOfMake: isEmpty(motorDetails?.YearOfMake)
      ? ''
      : motorDetails?.YearOfMake,
    motor: motorDetails?.Motor || '',
    weight: motorDetails?.Weight || '',
    engineSize: motorDetails?.EngineSize || '',
    seats: isEmpty(motorDetails?.Seats) ? '' : motorDetails?.Seats,
    chassis: motorDetails?.Chassis || '',
    horsePower: isEmpty(motorDetails?.HorsePower)
      ? ''
      : motorDetails?.HorsePower,
    usageType: motorDetails?.UsageType?.Id || '',
    bodyType: motorDetails?.BodyType?.Id || '',
    engineType: motorDetails?.EngineType?.Id || '',
    brand: motorDetails?.Brand?.Id || '',
    model: motorDetails?.Model?.Id || '',
    status: applicationInfo?.Status || '',
    statusReason: applicationInfo?.StatusReason || '',
    lineOfBusiness: applicationInfo?.LineOfBusiness?.Title || '',
    agencyName:
      applicationInfo?.AssignedAgencyID?.views?.Insurance_all?.properties
        ?.AgencyName || '',
    agentName:
      applicationInfo?.AssignedAgentID?.views?.Insurance_all?.properties
        ?.FirstName +
      ' ' +
      applicationInfo?.AssignedAgentID?.views?.Insurance_all?.properties
        ?.LastName,
    customerName:
      applicationInfo?.ApplicationContactDetailsID?.views?.Insurance_all
        ?.properties?.FullName,
    createdBy: `${data?.Insurance?.entities?.application?.queries?.insurance?.relatedUsers[0]?.createdBy_firstName} ${data?.Insurance?.entities?.application?.queries?.insurance?.relatedUsers[0]?.createdBy_lastName}`,
    createdOn: applicationInfo?.createdOn,
    modifiedBy: `${data?.Insurance?.entities?.application?.queries?.insurance?.relatedUsers[0]?.modifiedBy_firstName} ${data?.Insurance?.entities?.application?.queries?.insurance?.relatedUsers[0]?.modifiedBy_lastName}`,
    modifiedOn: applicationInfo?.modifiedOn,
    nameOnLicenseEn: motorDetails?.NameOnLicenseEn || '',
    nameOnLicenseAr: motorDetails?.NameOnLicenseAr || '',
    email: customerDetails?.Email || '',
    mobileNumber: customerDetails?.MobileNumber || '',
    country: customerDetails?.Address?.Country?.Id || initialValues.country,
    city: customerDetails?.Address?.City || '',
    street: customerDetails?.Address?.Street || '',
    building: customerDetails?.Address?.Building || '',
    planDetails: planDetails || '',
    title: customerDetails?.Title?.Id || initialValues.title,
    fullName: customerDetails?.FullName || '',
    firstName: customerDetails?.FirstName || '',
    middleName: customerDetails?.MiddleName || '',
    lastName: customerDetails?.LastName || '',
    phoneType: customerDetails?.PhoneType || initialValues.phoneType,
  };
}

export function extractLovs(data: any): Record<string, Record<string, string>> {
  const lovs: Record<string, Record<string, string>> = {
    products: {},
    towingServices: {},
    coverageTypes: {},
    countries: {},
    plateCodes: {},
    usageTypes: {},
    bodyTypes: {},
    engineTypes: {},
    brands: {},
    models: {},
    renewalStatus: {},
    phoneTypes: {},
    agencyRepairs: {},
  };
  lovs['products'] = lookupListAsRecordObject(
    data?.Insurance?.lookups?.products
  );
  lovs['towingServices'] = lookupListAsRecordObject(
    data?.Insurance?.lookups?.towingServices
  );
  lovs['coverageTypes'] = enumListAsRecordObject(
    data.Insurance_CoverageTypes.enumValues
  );
  lovs['plateCodes'] = lookupListAsRecordObject(
    data?.Insurance?.lookups?.plateCodes
  );
  lovs['usageTypes'] = lookupListAsRecordObject(
    data?.Insurance?.lookups?.usageTypes
  );
  lovs['bodyTypes'] = lookupListAsRecordObject(
    data?.Insurance?.lookups?.bodyTypes
  );
  lovs['engineTypes'] = lookupListAsRecordObject(
    data?.Insurance?.lookups?.engineTypes
  );
  lovs['brands'] = lookupListAsRecordObject(data?.Insurance?.lookups?.brands);

  lovs['countries'] = lookupListAsRecordObject(
    data?.Insurance?.lookups?.countries
  );

  lovs['renewalStatus'] = enumListAsRecordObject(
    data.Insurance_RenewalStatus.enumValues
  );

  lovs['titles'] = lookupListAsRecordObject(data?.Insurance?.lookups?.titles);

  lovs['phoneTypes'] = enumListAsRecordObject(
    data?.Insurance_PhoneType?.enumValues
  );

  lovs['agencyRepairs'] = enumListAsRecordObject(
    data?.Insurance_AgencyRepairs?.enumValues
  );

  return lovs;
}

export function graphqlEntityToQuoteDetails(
  data: any,
  index: number,
  currencySymbol: string,
  lob: string
): quote {
  return {
    lineOfBusiness: lob,
    id: data?.Insurance?.entities?.application?.views?.Insurance_all?.properties
      ?.MotorDetailsID?.queries?.insurance?.plans[index]?.quote_Id,
    title:
      data?.Insurance?.entities?.application?.views?.Insurance_all?.properties
        ?.MotorDetailsID?.queries?.insurance?.plans[index]?.quote_Name,
    status:
      data?.Insurance?.entities?.application?.views?.Insurance_all?.properties
        ?.MotorDetailsID?.queries?.insurance?.plans[index]?.quote_Status,
    statusReason:
      data?.Insurance?.entities?.application?.views?.Insurance_all?.properties
        ?.MotorDetailsID?.queries?.insurance?.plans[index]?.quote_StatusReason,
    createdOn:
      data?.Insurance?.entities?.application?.views?.Insurance_all?.properties
        ?.createdOn,
    modifiedOn:
      data?.Insurance?.entities?.application?.views?.Insurance_all?.properties
        ?.modifiedOn,
    hasCoverage:
      !isEmpty(
        data?.Insurance?.entities?.application?.views?.Insurance_all?.properties
          ?.MotorDetailsID
      ) &&
      data?.Insurance?.entities?.application?.views?.Insurance_all?.properties
        ?.LineOfBusiness?.Title == 'Motor',
    coverageAmount:
      data?.Insurance?.entities?.application?.views?.Insurance_all?.properties
        ?.MotorDetailsID?.queries?.insurance?.plans[index]
        ?.motorquote_SumInsured,
    coverageAmountCurrencySymbol: currencySymbol,
    premiumAmount:
      data?.Insurance?.entities?.application?.views?.Insurance_all?.properties
        ?.MotorDetailsID?.queries?.insurance?.plans[index]
        ?.quote_TotalBasePremium,
    premiumAmountCurrencySymbol: currencySymbol,
    carValue:
      data?.Insurance?.entities?.application?.views?.Insurance_all?.properties
        ?.MotorDetailsID?.queries?.insurance?.plans[index]?.input_CarValue,
    carValueCurrencySymbol: currencySymbol,
    vignetteCode:
      data?.Insurance?.entities?.application?.views?.Insurance_all?.properties
        ?.MotorDetailsID?.queries?.insurance?.plans[index]?.input_VignetteCode,
    vignette:
      data?.Insurance?.entities?.application?.views?.Insurance_all?.properties
        ?.MotorDetailsID?.queries?.insurance?.plans[index]?.input_Vignette,
    towingServiceTitle:
      data?.Insurance?.entities?.application?.views?.Insurance_all?.properties
        ?.MotorDetailsID?.queries?.insurance?.plans[index]?.input_TowingService
        ?.Title,
    policyId:
      data?.Insurance?.entities?.application?.views?.Insurance_all?.properties
        ?.MotorDetailsID?.queries?.insurance?.plans[index]?.policy_Id,
    fullPolicyNumber:
      data?.Insurance?.entities?.application?.views?.Insurance_all?.properties
        ?.MotorDetailsID?.queries?.insurance?.plans[index]
        ?.policy_FullPolicyNumber,
    disabled:
      data?.Insurance?.entities?.application?.views?.Insurance_all?.properties
        ?.MotorDetailsID?.queries?.insurance?.plans[index]?.quote_Status ==
      'REFER_TO_UW_DEPARTMENT',
    product: {
      Code: data?.Insurance?.entities?.application?.views?.Insurance_all
        ?.properties?.MotorDetailsID?.queries?.insurance?.plans[index]
        ?.input_Product?.Code,
      Title:
        data?.Insurance?.entities?.application?.views?.Insurance_all?.properties
          ?.MotorDetailsID?.queries?.insurance?.plans[index]?.input_Product
          ?.Title,
    },
  };
}

export function filterBodyTypeLOVs(
  usageType = '',
  bodyType: Record<string, string>
) {
  let desiredKeys: string[] = [];

  if (bodyType && usageType) {
    switch (usageType) {
      case 'Private':
        desiredKeys = [
          '4x4',
          'BUS',
          'MINI_BUS',
          'MINIVAN',
          'MOTORCYCLE',
          'SCHOOL_BUS',
          'SEDAN',
          'STATION',
          'SUV',
          'VAN',
        ];
        break;

      case 'Public':
        desiredKeys = ['TAXI'];
        break;

      case 'Commercial':
        desiredKeys = desiredKeys = [
          'BUS',
          'CRANE',
          'F_TANKER',
          'MICROBUS',
          'MINI_BUS',
          'MINIVAN',
          'MOTORCYCLE',
          'PICKUP',
          'SCHOOL_BUS',
          'TOWING',
          'TRUCK',
          'VAN',
        ];
        break;
    }

    return Object.fromEntries(
      Object.entries(bodyType).filter(([key]) => desiredKeys.includes(key))
    );
  }

  return {};
}

export function filterTowingServiceLOVs(
  bodyType: string,
  towingServices: Record<string, string>
) {
  let desiredKeys: string[] = [];

  const limitiedAndUnlimitedTowingGroup = ['4x4', 'SEDAN', 'STATION', 'SUV'];
  const LimitedTowing50km3TonsGroup = ['MINIVAN'];
  const LimitedTowing50kmGroup = ['TAXI'];

  if (towingServices) {
    if (limitiedAndUnlimitedTowingGroup.includes(bodyType)) {
      desiredKeys = [...desiredKeys, 'LimitedTowing', 'UnlimitedTowing'];
    } else if (LimitedTowing50kmGroup.includes(bodyType)) {
      desiredKeys = [...desiredKeys, 'LimitedTowing50km'];
    } else if (LimitedTowing50km3TonsGroup.includes(bodyType)) {
      desiredKeys = [...desiredKeys, 'LimitedTowing50km3Tons'];
    } else {
      desiredKeys = [...desiredKeys, 'NA'];
    }

    return Object.fromEntries(
      Object.entries(towingServices).filter(([key]) =>
        desiredKeys.includes(key)
      )
    );
  }

  return towingServices;
}

//method need to return true to avoid phone number validation in case phone number is empty
export const checkPhoneNumber = (phoneNumber: string) => {
  //need to check that we are not receiving the country code also
  if (!isEmpty(phoneNumber) && phoneNumber.length > 4) {
    return validatePhoneNumber(phoneNumber);
  }

  return false;
};

export const checkQuoteName = (
  quoteName: string | number | NonNullable<unknown>
) => {
  if (!isEmpty(quoteName) || quoteName !== '' || quoteName !== null) {
    return true;
  } else {
    return 'Required';
  }
};

export const checkEmail = (email: string) => {
  if (!isEmpty(email)) {
    return isValidEmail(email);
  }

  return true;
};

export const getApplicationStatus = (status: string) => {
  switch (status) {
    case 'NEW':
      return steps[0];
    case 'QUOTE_PREPARATION':
      return steps[1];
    case 'QUOTE_CONFIRMATION':
      return steps[2];
    case 'CLOSED':
      return steps[3];
    default:
      return steps[0];
  }
};

export const getApplicationProgress = (status: string) => {
  switch (status) {
    case 'NEW':
      return 25;
    case 'QUOTE_PREPARATION':
      return 50;
    case 'QUOTE_CONFIRMATION':
      return 57;
    case 'CLOSED':
      return 100;
    default:
      return 0;
  }
};

export const checkDate = (date: string) => {
  return isValidDate(date);
};

export const isVisiblePlanDetailsField = (
  product: string,
  planDetailsFieldName: string
): boolean => {
  let isVisible = false;
  product = product?.toString()?.toLowerCase();
  planDetailsFieldName = planDetailsFieldName.toString().toLowerCase();
  if (product == 'allrisk') {
    isVisible =
      planDetailsFieldName == 'coveragetype' ||
      planDetailsFieldName == 'carvalue' ||
      planDetailsFieldName == 'includeagencyrepair';
  } else if (product == 'bodilyinjury') {
    isVisible = planDetailsFieldName == 'vignette';
  } else if (product == 'materialdamage') {
    isVisible = planDetailsFieldName == 'towingservice';
  } else if (product == 'totalloss') {
    isVisible = planDetailsFieldName == 'carvalue';
  }

  return isVisible;
};

export function filterUsageTypeLOVs(
  plateCode = '',
  usageType: Record<string, string>
) {
  let desiredKeys = ['Public'];

  if (usageType) {
    if (plateCode != 'M' && plateCode != 'P') {
      desiredKeys = [...desiredKeys, 'Private'];
    }

    if (plateCode == 'M') {
      desiredKeys = ['Commercial', 'Private'];
    }

    if (plateCode != 'P') {
      desiredKeys = [...desiredKeys, 'Commercial'];
    }

    return Object.fromEntries(
      Object.entries(usageType).filter(([key]) => desiredKeys.includes(key))
    );
  }

  return {};
}

export function filterProductsByAgentProductsLOVS(
  mainProducts: Record<string, string>,
  agentProducts: string[]
) {
  if (agentProducts) {
    return Object.fromEntries(
      Object.entries(mainProducts).filter(([key]) =>
        agentProducts.includes(key)
      )
    );
  }

  return {};
}

export function adjustAgencyRepair(
  carValue: number,
  agencyRepair: Record<string, string>
) {
  let desiredKeys: string[] = ['YES'];

  if (carValue && carValue <= 100000) {
    desiredKeys = [...desiredKeys, 'NO'];
  }

  return Object.fromEntries(
    Object.entries(agencyRepair).filter(([key]) => desiredKeys.includes(key))
  );
}
export function canShowIncludeAgencyRepairField(
  coverageType: string,
  yearOfMake: number,
  usageType: string
) {
  return (
    usageType == 'Private' &&
    yearOfMake >= 2021 &&
    yearOfMake <= 2024 &&
    coverageType == 'LUX_PLAN'
  );
}
