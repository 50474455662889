import React, { useEffect, useState, useMemo } from 'react';
import GenericDrawer from '../../components/common/generic-drawer/GenericDrawer';
import { toast } from 'react-toastify';
import { EnhancedButtonStatus } from '../../components/common/EnhancedButton';
import ToastErrorMessage from '../../components/ToastErrorMessage';
import { getInputs, initialValues } from './content';
import { useLazyQuery, useMutation } from '@apollo/client';
import Loader from '../../components/Loader';
import { isEmpty } from 'lodash';
import { DEFAULT_ERROR_TEXT } from '../../constants';
import { ITravelPlanRatesDrawerInfo, ITravelPlanRatesDrawerProps } from '.';
import {
  createPlanSpecificTravel,
  getTravelPlanRatesEnums,
  getPlanSpecificTravelInfo,
  updatePlanSpecificTravel,
  fetchedPolicyCoverList,
} from './queries';
import {
  LookupToList,
  graphqlToTravelPlanRatesInfo,
  extractPolicyCovers,
} from './utils';
import { getError } from '../../utils/graph-utils';
import { normaliseDynamicValues } from '../../utils/dynamic-utils';
import ToastSuccessMessage from '../../components/ToastSuccessMessage';
import DynamicForm from '../../DynamicForm/DynamicForm';

const TravelPlanRatesDrawer: React.FC<ITravelPlanRatesDrawerProps> = ({
  open,
  onSuccess,
  onClose,
  planId,
  planCurrency,
  travelPlanRateId,
  lineId,
}) => {
  const [booted, setBooted] = useState<boolean>(false);
  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();
  const [values, setValues] = useState<ITravelPlanRatesDrawerInfo>();
  const [lists, setLists] = useState<Record<string, Record<string, string>>>({
    currencies: {},
    travelDestinations: {},
    gender: {},
    policyCover: {},
  });

  const [travelPlanRatesResultQuery] = useLazyQuery(getTravelPlanRatesEnums());
  const [travelPlanRatesInfoQuery] = useLazyQuery(getPlanSpecificTravelInfo());
  const [travelPlanRatesAction] = useMutation(
    travelPlanRateId ? updatePlanSpecificTravel() : createPlanSpecificTravel()
  );

  const [fetchedPolicyCoverListLazy] = useLazyQuery(fetchedPolicyCoverList());

  const loadLovList = async () => {
    const data = await travelPlanRatesResultQuery();
    const newTravelPlanRatesEnums = LookupToList(data.data);
    const result: Record<string, Record<string, string>> = {
      currencies: newTravelPlanRatesEnums['currencies'],
      travelDestinations: newTravelPlanRatesEnums['travelDestinations'],
      gender: newTravelPlanRatesEnums['gender'],
      policyCover: {},
    };

    return result;
  };

  const getEntityInfo = async () => {
    if (travelPlanRateId) {
      const apiResult = await travelPlanRatesInfoQuery({
        variables: { id: travelPlanRateId },
      });
      if (apiResult.data) {
        const travelPlanRateEntity = graphqlToTravelPlanRatesInfo(
          apiResult.data
        );
        return travelPlanRateEntity;
      }
    }

    return null;
  };

  const getPolicyCoverList = async (inputValue: string) => {
    if (inputValue && inputValue.length >= 3) {
      const data = await fetchedPolicyCoverListLazy({
        variables: {
          searchKeyword: inputValue,
          selectedLineIds: [lineId],
        },
      });

      if (data.data) {
        const extractedPolicyCovers = extractPolicyCovers(data.data);
        return extractedPolicyCovers;
      }
    }

    return null;
  };

  const initialize = async () => {
    try {
      const [travelPlanRatesEntity, lovData] = await Promise.all([
        getEntityInfo(),
        loadLovList(),
      ]);

      let newValues = { ...initialValues };

      if (travelPlanRateId && travelPlanRatesEntity) {
        // If policyCover exists, add it to the list of options
        if (
          travelPlanRatesEntity.policyCover &&
          travelPlanRatesEntity.policyCoverTitle
        ) {
          lovData.policyCover[travelPlanRatesEntity.policyCover] =
            travelPlanRatesEntity.policyCoverTitle;
        }

        newValues = {
          ...newValues,
          planCurrency: planCurrency,
          ageFrom: travelPlanRatesEntity.ageFrom,
          ageTo: travelPlanRatesEntity.ageTo,
          gender: travelPlanRatesEntity.gender,
          travelDurationFrom: travelPlanRatesEntity.travelDurationFrom,
          travelDurationTo: travelPlanRatesEntity.travelDurationTo,
          travelDestination: travelPlanRatesEntity.travelDestination,
          nbOfAdherent: travelPlanRatesEntity.nbOfAdherent,
          premium: travelPlanRatesEntity.premium,
          policyCover: travelPlanRatesEntity.policyCover,
        };
      }

      setValues(newValues);
      setLists(lovData);
      setBooted(true);
    } catch (err) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  const onCustomChange = async (
    fieldName: string,
    value: any,
    allValues: ITravelPlanRatesDrawerInfo
  ) => {
    setValues(allValues);
  };

  // Added onSearch function to handle dynamic fetching of policy covers
  const onSearch = async (fieldName: string, value: string) => {
    const newLovs = { ...lists };
    if (fieldName === 'policyCover') {
      newLovs.policyCover =
        (await getPolicyCoverList(value)) || lists.policyCover;
    }
    setLists(newLovs);
  };

  const submitForm = async (formValues: ITravelPlanRatesDrawerInfo) => {
    const [data] = normaliseDynamicValues(
      getInputs(formValues, lists, planCurrency),
      formValues
    );

    setFormDisabled(true);
    setSubmitButtonState('loading');

    try {
      const variablesMutation = {
        planSpecificTravelInputs: {
          planID: planId,
          ageFrom: Number(data?.ageFrom),
          ageTo: Number(data?.ageTo),
          gender: data?.gender,
          travelDurationFrom: Number(data?.travelDurationFrom),
          travelDurationTo: Number(data?.travelDurationTo),
          travelDestination: data?.travelDestination,
          nbOfAdherent: Number(data?.nbOfAdherent),
          premium: Number(data?.premium),
          policyCoverID: data?.policyCover || '',
        },
      };

      const res = await travelPlanRatesAction({
        variables: travelPlanRateId
          ? { ...variablesMutation, entityId: travelPlanRateId }
          : variablesMutation,
        errorPolicy: 'all',
      });

      if (isEmpty(res.errors)) {
        toast.success(
          <ToastSuccessMessage>
            {travelPlanRateId
              ? 'Plan Rate successfully updated.'
              : 'Plan Rate successfully added.'}
          </ToastSuccessMessage>
        );
        setTimeout(() => {
          setSubmitButtonState('success');
          setFormDisabled(false);
          onSuccess();
          onClose();
        }, 500);
      } else {
        setSubmitButtonState(undefined);
        toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
        setFormDisabled(false);
      }
    } catch (err) {
      setSubmitButtonState(undefined);
      toast.error(<ToastErrorMessage>{getError(err)}</ToastErrorMessage>);
      setFormDisabled(false);
    }
  };

  const inputForm = useMemo(() => {
    return getInputs(values || initialValues, lists, planCurrency);
  }, [values, lists]);

  return (
    <GenericDrawer
      title={travelPlanRateId ? 'Modify Plan Rate' : 'Add Plan Rate'}
      onClose={onClose}
      isOpen={open}
    >
      {!booted ? (
        <Loader />
      ) : (
        <DynamicForm
          inputs={inputForm}
          onSubmit={submitForm}
          buttonText="Submit"
          submitButtonState={submitButtonState}
          isSubmitButtonDisabled={!!submitButtonState}
          disableForm={formDisabled}
          title="Information"
          hasDoprdownSpecificBehavior={true}
          onChange={onCustomChange}
          onSearch={onSearch}
        />
      )}
    </GenericDrawer>
  );
};

export default TravelPlanRatesDrawer;
