import { gql } from '@apollo/client';

export function getTravelPlanRatesEnums() {
  return gql`
    query getTravelPlanRatesEnums {
      Insurance_Genders: __type(name: "Insurance_Genders") {
        name
        enumValues {
          name
        }
      }
      Core {
        lookups {
          currencies {
            Id
            Title
          }
          travelDestinations {
            Id
            Title
          }
        }
      }
    }
  `;
}

export function getPlanSpecificTravelInfo() {
  return gql`
    query getPlanSpecificTravelInfo($id: String!) {
      PlanConfigManagement {
        entities {
          planSpecificTravel(id: $id) {
            id
            views {
              PlanConfigManagement_all {
                properties {
                  Id
                  PlanID {
                    id
                  }
                  PolicyCoverID {
                    id
                    views {
                      PlanConfigManagement_all {
                        properties {
                          Name
                          ExternalCode
                        }
                      }
                    }
                  }
                  AgeFrom
                  AgeTo
                  Gender
                  TravelDurationFrom
                  TravelDurationTo
                  TravelDestination {
                    Id
                    Title
                  }
                  NbOfAdherent
                  Premium
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function createPlanSpecificTravel() {
  return gql`
    mutation createPlanSpecificTravel(
      $planSpecificTravelInputs: PlanConfigManagement_PlanSpecificTravelInputs!
    ) {
      planConfigManagement {
        actions {
          createPlanSpecificTravel(
            planSpecificTravelInputs: $planSpecificTravelInputs
          ) {
            id
          }
        }
      }
    }
  `;
}

export function updatePlanSpecificTravel() {
  return gql`
    mutation updatePlanSpecificTravel(
      $entityId: String!
      $planSpecificTravelInputs: PlanConfigManagement_PlanSpecificTravelInputs!
    ) {
      planConfigManagement {
        entities {
          planSpecificTravel {
            planConfigManagement {
              updatePlanSpecificTravel(
                entityId: $entityId
                planSpecificTravelInputs: $planSpecificTravelInputs
              ) {
                id
              }
            }
          }
        }
      }
    }
  `;
}

export function fetchedPolicyCoverList() {
  return gql`
    query fetchedPolicyCoverList(
      $selectedLineIds: [String]
      $searchKeyword: String!
    ) {
      PlanConfigManagement {
        queries {
          allPolicyCoverList(
            selectedLineIds: $selectedLineIds
            searchKeyword: $searchKeyword
          ) {
            planConfigManagement_PolicyCover_Id
            planConfigManagement_PolicyCover_createdOn
            planConfigManagement_PolicyCover_ExternalCode
            planConfigManagement_PolicyCover_Name
            planConfigManagement_PolicyCover_NameArabic
            planConfigManagement_PolicyCover_NameFrench
            planConfigManagement_PolicyCover_LineID {
              id
            }
          }
        }
      }
    }
  `;
}
