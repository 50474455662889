import React, { useEffect, useMemo, useState } from 'react';
import GenericDrawer from '../../components/common/generic-drawer/GenericDrawer';
import { toast } from 'react-toastify';
import { EnhancedButtonStatus } from '../../components/common/EnhancedButton';
import ToastErrorMessage from '../../components/ToastErrorMessage';
import { getInputs } from './content';
import { useLazyQuery, useMutation } from '@apollo/client';
import Loader from '../../components/Loader';
import { isEmpty } from 'lodash';
import {
  DEFAULT_ERROR_TEXT,
  SEND_TO_BACKEND_DATE_FORMAT,
} from '../../constants';
import { IProviderDrawerProps } from '.';
import {
  getListForm,
  createProvider,
  fetchedPersons,
  updateProvider,
} from './queries';
import { extractPersons, toLookups } from './utils';
import { getError } from '../../utils/graph-utils';
import { normaliseDynamicValues } from '../../utils/dynamic-utils';
import ToastSuccessMessage from '../../components/ToastSuccessMessage';
import DynamicForm from '../../DynamicForm/DynamicForm';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import dayjs from 'dayjs';
import _ from 'lodash';

const useStyles = makeStyles()(() => ({
  isLocalStyle: {
    marginBottom: '17px',
  },
}));

const ProviderDrawer: React.FC<IProviderDrawerProps> = ({
  open,
  onSuccess,
  onClose,
  providerDetailsInfo,
  providerId,
}) => {
  const { classes } = useStyles();
  classes;
  const navigate = useNavigate();
  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();

  const [booted, setBooted] = useState<boolean>(false);
  // const [inputsForm, setInputsForm] =
  //   useState<Record<string, DynamicFormInputType>>(inputs);
  const [values, setValues] = useState<Record<string, any>>();

  const [lovs, setLovs] = useState<Record<string, Record<string, string>>>({});

  const [providersDetailsListResults] = useLazyQuery(getListForm());

  const [fetchedPersonsLazy] = useLazyQuery(fetchedPersons());

  const [providerAction] = useMutation(
    providerId ? updateProvider() : createProvider()
  );

  const handlePersonInput = async (
    inputValue: any
  ): Promise<Record<string, string>> => {
    if (inputValue !== null) {
      if (inputValue.length >= 3) {
        const result = await fetchedPersonsLazy({
          variables: {
            searchKeyword: inputValue,
          },
        });
        return extractPersons(result.data);
      }
    }

    return {};
  };

  const getLovs = async () => {
    const data = await providersDetailsListResults();
    const lookups = toLookups(data.data);
    const result: Record<string, Record<string, string>> = {
      providerStatus: lookups['providerStatus'],
      type: lookups['type'],
      person: {},
    };

    return result;
  };

  const initialize = async () => {
    try {
      const newLovs = await getLovs();

      setLovs(newLovs);
      setValues(providerDetailsInfo);
      setBooted(true);
    } catch (err) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  const submitForm = async (values: Record<string, any>) => {
    const [data] = normaliseDynamicValues(
      getInputs((values as any) || undefined, lovs, providerId),
      values
    );

    setFormDisabled(true);
    setSubmitButtonState('loading');

    try {
      let variablesMutation = {};

      if (!providerId) {
        variablesMutation = {
          personId: data.person,
          type: data.type,
          isCompany: data.isCompany ? data.isCompany : false,
          isLocal: data.isLocal ? data.isLocal : false,
          effectiveDate: dayjs(new Date(data.effectiveDate)).format(
            SEND_TO_BACKEND_DATE_FORMAT
          ),
          expiryDate: data.expiryDate
            ? dayjs(new Date(data.expiryDate)).format(
                SEND_TO_BACKEND_DATE_FORMAT
              )
            : null,
          status: data.providerStatus,
        };
      } else {
        variablesMutation = {
          isCompany: data.isCompany ? data.isCompany : false,
          isLocal: data.isLocal ? data.isLocal : false,
          effectiveDate: dayjs(new Date(data.effectiveDate)).format(
            SEND_TO_BACKEND_DATE_FORMAT
          ),
        };
      }

      const res = await providerAction({
        variables: providerId
          ? {
              ...variablesMutation,
              entityId: providerId,
            }
          : variablesMutation,
        errorPolicy: 'all',
      });

      if (isEmpty(res.errors)) {
        toast.success(
          <ToastSuccessMessage>
            {providerId
              ? 'Provider successfully updated.'
              : 'Provider successfully created.'}
          </ToastSuccessMessage>
        );

        setTimeout(() => {
          setSubmitButtonState('success');
          onSuccess();
          onClose();
          setFormDisabled(false);
          if (!providerId) {
            const newProviderId =
              res.data.configuration.actions.createProvider.id;
            navigate(`/configuration/providers/` + newProviderId);
          }
        }, 500);
      } else {
        setFormDisabled(false);
        setSubmitButtonState(undefined);
        toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
      }
    } catch {
      setFormDisabled(false);
      setSubmitButtonState(undefined);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      //
    }
  };

  //#region useEffect
  useEffect(() => {
    initialize();
  }, []);

  const inputsForm = useMemo(() => {
    const result = getInputs((values as any) || undefined, lovs, providerId);
    return result;
  }, [values, lovs]);

  const onSearch = async (fieldName: string, value: string) => {
    const newLovs = _.cloneDeep(lovs);
    if (fieldName === 'person') {
      newLovs.person = await handlePersonInput(value);
    }
    setLovs(newLovs);
  };

  const onCustomValidate = (fieldName: string, values: Record<string, any>) => {
    if (fieldName === 'effectiveDate' || fieldName === 'expiryDate') {
      const { effectiveDate, expiryDate } = values;
      if (
        expiryDate &&
        effectiveDate &&
        dayjs(expiryDate).isValid() &&
        dayjs(effectiveDate).isValid() &&
        dayjs(expiryDate).isBefore(dayjs(effectiveDate))
      ) {
        return 'Expiry Date shall be equal or greater than Effective Date';
      }
      return '';
    }

    return '';
  };

  return (
    <GenericDrawer
      title={providerId ? 'Modify Provider Details' : 'New Provider'}
      onClose={() => onClose()}
      isOpen={open}
    >
      {!booted ? (
        <Loader />
      ) : (
        <>
          <DynamicForm
            inputs={inputsForm}
            onSubmit={(values) => submitForm(values)}
            buttonText={'Submit'}
            submitButtonState={submitButtonState}
            // isSubmitButtonDisabled={submitButtonDisabled}
            disableForm={formDisabled}
            title="Information"
            hasDoprdownSpecificBehavior={true}
            onSearch={onSearch}
            onChange={(
              fieldName: string,
              value: string | string[],
              allValues,
              errors,
              touched
            ) => {
              setValues(allValues);
              if (fieldName === 'effectiveDate' || fieldName === 'expiryDate') {
                const expiryDateError = onCustomValidate(
                  'expiryDate',
                  allValues
                );
                errors.expiryDate = expiryDateError;
                touched.effectiveDate = true;
                touched.expiryDate = true;
              }
            }}
          />
        </>
      )}
    </GenericDrawer>
  );
};

export default ProviderDrawer;
