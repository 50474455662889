import {
  convertToFixedNumber,
  numberToPercentage,
} from '../../../../../utils/helper-utils';
import { IProposalDetailsSummary } from './index2';

export interface IPayerData {
  payerId: string;
  fullName?: string;
  payerAddress: string;
  ownerIsDifferent: boolean;
  addressDetails?: string;
}

export interface IOwnerData {
  ownerId?: string;
  fullName?: string;
  ownerAddress?: string;
  addressDetails?: string;
}

export interface IInsuredData {
  id: string;
  order: string;
  fullName?: string;
  address?: string;
  addressDetails?: string;
}

export interface IBeneficiaryData {
  id: string;
  address: string;
  percentage: number;
  fullName?: string;
  addressDetails?: string;
}

export interface IPolicyPersons {
  payer: IPayerData;
  owner?: IOwnerData;
  insured: IInsuredData[];
  beneficiaries: IBeneficiaryData[];
}

export interface IProposalPageFormData {
  policyPersons: {
    insured: IInsuredData[];
  };
  covers: IProposalCover[];
  costs: IProposalCostCharges;
}

export interface IProposalCover {
  isMain: boolean;
  isMandatory: boolean;
  isEditable: boolean;
  policyCover: string;
  coverPremiumType: string;
  coverPremiumPercentage?: number;
  coverPremiumValue?: number;
  currency: string;
  coverSumInsured?: number;
  sumInsuredIsAdditive: boolean;
  excessOnClaimType: string;
  excessOnClaimAmount?: number;
  excessOnClaimPercentage?: number;
  excessOnClaimDays?: number;
  name?: string;
  clauseID: string;
  coverEffectiveFrom: string;
  coverEffectiveTo: string;
}

export interface IProposalCostCharges {
  policyCost: number;
  chargesPercentage: number;
  tpaFeesType: string;
  tpaFeesAmount?: number;
  tpaFeesPercentage?: number;
  tpaFeesApplicableOn: string;
}

export interface IProposalPageFormState {
  values: IProposalPageFormData;
  errors: {
    policyPersons: {
      insured: Record<string, string>[];
    };
    covers: Record<string, string>[];
    costs: Record<string, string>;
  };
  touched: {
    policyPersons: {
      insured: Record<string, boolean>[];
    };
    covers: Record<string, boolean>[];
    costs: Record<string, boolean>;
  };
}

export const getProposalPageFormState = (
  summary?: IProposalDetailsSummary,
  covers: IProposalCover[] = [],
  policyPersons?: IPolicyPersons
): IProposalPageFormState => ({
  values: {
    policyPersons,
    covers: covers,
    costs: {
      policyCost: convertToFixedNumber(summary?.PricingOption.PolicyCost),
      chargesPercentage: numberToPercentage(
        summary?.PricingOption.ChargesPercentage
      ),
      tpaFeesType: summary?.PricingOption.TPAFeesType,
      tpaFeesAmount: convertToFixedNumber(summary?.PricingOption.TPAFeesAmount),
      tpaFeesPercentage: numberToPercentage(
        summary?.PricingOption.TPAFeesPercentage
      ),
      tpaFeesApplicableOn: summary?.PricingOption.TPAFeesApplicableOn,
    },
  },
  errors: {
    policyPersons: {
      insured:
        [policyPersons?.insured || []]?.length > 0
          ? policyPersons?.insured.map((v) => ({
              id: '',
              order: '',
              address: '',
            }))
          : [
              {
                id: '',
                order: '',
                address: '',
              },
            ],
    },
    covers: covers.map((c) => ({
      isMain: '',
      isMandatory: '',
      coverPremiumType: '',
      coverPremiumPercentage: '',
      coverPremiumValue: '',
      currency: '',
      coverSumInsured: '',
      sumInsuredIsAdditive: '',
      excessOnClaimType: '',
      excessOnClaimAmount: '',
      excessOnClaimPercentage: '',
      excessOnClaimDays: '',
      coverEffectiveFrom: '',
      coverEffectiveTo: '',
    })),
    costs: {},
  },
  touched: {
    policyPersons: {
      insured:
        [policyPersons?.insured || []]?.length > 0
          ? policyPersons?.insured.map((v) => ({
              id: false,
              order: false,
              address: false,
            }))
          : [
              {
                id: false,
                order: false,
                address: false,
              },
            ],
    },
    covers: covers.map(() => ({
      isMain: false,
      isMandatory: false,
      coverPremiumType: false,
      coverPremiumPercentage: false,
      coverPremiumValue: false,
      currency: false,
      coverSumInsured: false,
      sumInsuredIsAdditive: false,
      excessOnClaimType: false,
      excessOnClaimAmount: false,
      excessOnClaimPercentage: false,
      excessOnClaimDays: false,
      coverEffectiveFrom: false,
      coverEffectiveTo: false,
    })),
    costs: {},
  },
});
