import React, { useEffect, useState } from 'react';
import EnhancedStepper from '../../components/common/EnhancedStepper';
import EntityInfoWidget from '../../components/widgets/entity-info/EntityInfoWidget';
import {
  entityView,
  businessUserTabs,
  businessPartnersPlansHeaders,
  filterSectionsContent,
} from './content';
import { makeStyles } from 'tss-react/mui';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  activateAssignedBPPlan,
  activateBusinessUser,
  deactivateAssignedBPPlan,
  deactivateBusinessUser,
  getAssignedBPLineListWithoutPagination,
  getAssignedPlanListWithoutPagination,
  getBusinessPartnersPlansList,
} from './queries';
import { useNavigate, useParams } from 'react-router-dom';
import { IEntityInfoView } from '../../components/widgets/entity-info/EntityInfoFields';
import Loader from '../../components/Loader';
import { toast } from 'react-toastify';
import ToastErrorMessage from '../../components/ToastErrorMessage';

import { ISalesforceBusinessUserDetails, initialValues } from '.';

import {
  DEFAULT_ERROR_TEXT,
  MAIN_ONE_THEME,
  contentFontFamilyBold,
} from '../../constants';

import dayjs from 'dayjs';
import TabsLayout from '../../page-layout/tabs-layout/TabsLayout';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import EnhancedButton, {
  EnhancedButtonStatus,
} from '../../components/EnhancedButton';
import ToastSuccessMessage from '../../components/ToastSuccessMessage';
import { getError } from '../../utils/graph-utils';
import { IConfirmation } from '../../redux/confirmation/types';
import { getBusinessUserDetailsInfo } from './queries';
import { OpenConfirmationAction } from '../../redux/confirmation/actions';
import {
  entityToBusinessUserDetails,
  extractAssignedBP,
  // extractDistinctLines,
  // extractDistinctPlans,
  getBusinessUserStatus,
  mapToBusinessPartnersPlansListingData,
} from './utils';
import { isEmpty } from '../../utils/validationUtils';
import { EnhancedDisplayType } from '../../components/enhanced-form/EnhancedDisplay';
import DynamicWidget from '../../components/widgets/dynamic-display/DynamicWidget';
import ShowForUser from '../../components/user/ShowForUser';
import { IDynamicDiplayView } from '../../components/widgets/dynamic-display';
import {
  capitalizeFirstCharacter,
  capitalizeFirstLetter,
} from '../../utils/formatting-utils';
import SalesForceBusinessUserDrawer from '../salesforce-business-users-drawer/SalesForceBusinessUserDrawer';
import WidgetSection from '../../components/common/WidgetSection';
import EnhancedTable from '../../components/enhanced-table/EnhancedTable';
import { IListingData } from '../../models/listing';
import {
  IEnhancedTableMenuItem,
  IEnhanceTableHeaderClickable,
} from '../../components/enhanced-table';
import SalesforceAssignedBPPlanDrawer from '../salesforce-assign-business-partner-plan/SalesforceAssignedBPPlanDrawer';
// import { useForm } from "react-hook-form";
import SalesForceBusinessUserAuthoritiesDrawer from '../salesforce-business-users-authorities-drawer/SalesForceBusinessUserAuthoritiesDrawer';
import { ITableOrder, TableSortOrder } from '../../utils/table-utils';
import _ from 'lodash';
import { IListingFilterWidgetSection } from '../../components/widgets/custom-listing-filter';
import { getFilter, setFilter } from '../../utils/filter-utils';
import { IAbstractRecord } from '../../models';
import InlineFilterWidget from '../../components/widgets/custom-listing-filter/InlineFilterWidget';

const useStyles = makeStyles()(() => ({
  stepper: {
    backgroundColor: 'transparent',
    maxWidth: '90%',
    marginBottom: '20px',
    '& .MuiStepLabel-root .Mui-active, .MuiStepLabel-root .Mui-completed': {
      color: MAIN_ONE_THEME.palette.primary5.main,
    },

    '@media only screen and (min-width: 600px)': {
      maxWidth: '1300px',
    },
  },
  section: {
    display: 'grid',
    'grid-template-columns': '32% 32% 32%',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  sectionFullRow: {
    display: 'grid',
    'grid-template-columns': '100%',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  row: {
    flexWrap: 'wrap',
    margin: '10px',
    alignItems: 'center',
    display: 'flex',
    gap: '10px',
    '& label': {
      flexGrow: '1',
      flexBasis: '0',
      minWidth: '0',
      maxWidth: '25%',
    },
  },
  thickSeperator: {
    height: 10,
    margin: '10px 0 10px 0',
    'grid-column-start': '1',
    'grid-column-end': '4',
  },
  field: {
    width: '100%',
    marginRight: '10px !important',
    backgroundColor: 'white',
  },
  arField: {
    width: '100%',
    marginRight: '10px !important',
    direction: 'rtl',
  },
  header: {
    fontFamily: contentFontFamilyBold,
    fontSize: MAIN_ONE_THEME.typography.regular.reg3.fontSize,
    color: MAIN_ONE_THEME.palette.primary2.main,
    '&:hover': {
      color: MAIN_ONE_THEME.palette.primary1.main,
    },
  },
  content: {
    padding: '0',
  },
  tabPanelStyle: {
    padding: 'unset',
    marginTop: '20px',
  },
  dirtyChip: {
    margin: 0,
    backgroundColor: MAIN_ONE_THEME.palette.primary2.main,
    '& span': {
      color: '#ffffff',
    },
  },
  actionButton: {
    display: 'inline-block',
    marginRight: '5px',
  },
  container: {
    display: 'flex',
    justifyContent: 'space-evenly',
    marginTop: '0px',
  },
}));

const SalesforceBusinessUserDetailsPage: React.FC = () => {
  const { classes } = useStyles();
  const params = useParams();
  const navigate = useNavigate();

  const tenant = useAppSelector((state) => state.tenant);
  const user = useAppSelector((state) => state.user);

  const [booted, setBooted] = useState<boolean>(false);
  const [keywordSearch, setKeywordSearch] = useState('');
  let setSearchTimeout: NodeJS.Timeout;

  const [tableOrders, setTableOrders] = useState<Record<string, ITableOrder>>({
    plan: {
      orderBy: '',
      orderDirection: 'asc',
    },
  });

  const FILTER_SESSION_KEY = 'businessUsersDetailsFilter';

  const [filterSections, setFilterSections] =
    useState<IListingFilterWidgetSection[]>();

  const initialfilterValues =
    Object.keys(getFilter(FILTER_SESSION_KEY)).length > 0
      ? getFilter(FILTER_SESSION_KEY)
      : {
          bp: [],
          line: [],
          plan: [],
        };

  const [filterValues, setFilterValues] =
    useState<IAbstractRecord>(initialfilterValues);

  const [activateButtonState, setActivateButtonState] =
    useState<EnhancedButtonStatus>();
  const [deactivateButtonState, setDeactivateButtonState] =
    useState<EnhancedButtonStatus>();

  const [updatedLeftSideInfo, setUpdatedLeftSideInfo] =
    useState<IEntityInfoView>(entityView);

  const [loadingState, setLoadingState] = useState<boolean>(true);
  const [businessUserInfoDrawerOpen, setBusinessUserInfoDrawerOpen] =
    useState<boolean>(false);
  const [businessPartnersPlansDrawerOpen, setBusinessPartnersPlansDrawerOpen] =
    useState<boolean>(false);
  const [
    businessUserAuthoritiesDrawerOpen,
    setBusinessUserAuthoritiesDrawerOpen,
  ] = useState<boolean>(false);

  const [businessUserDetailsInfo, setBusinessUserDetailsInfo] =
    useState<ISalesforceBusinessUserDetails>(initialValues);
  const [
    businessUserInformationViewConfig,
    setBusinessUserInformationViewConfig,
  ] = useState<IDynamicDiplayView>();
  const [
    businessUserAuthoritiesViewConfig,
    setBusinessUserAuthoritiesViewConfig,
  ] = useState<IDynamicDiplayView>();

  const [status, setStatus] = useState<string>();
  const [businessUserType, setBusinessUserType] = useState<string>();

  const [businessUserStatus, setBusinessUserStatus] = useState<string>('');
  const [selectedAssignedBPPlanIds, setSelectedAssignedBPPlanIds] = useState<
    string[]
  >([]);

  const [selectedAssignedBPPlanStatus, setSelectedAssignedBPPlanStatus] =
    useState<string[]>([]);
  const [
    selectedAssignedBPPlanBusinessPartnerLineStatus,
    setSelectedAssignedBPPlanBusinessPartnerLineStatus,
  ] = useState<string[]>([]);
  const [
    selectedAssignedBPPlanBusinessPartnerPlanStatus,
    setSelectedAssignedBPPlanBusinessPartnerPlanStatus,
  ] = useState<string[]>([]);

  const dispatch = useAppDispatch();
  const entityId = params.id;

  // const [selectedBPFilter, setSelectedBPFilter] = useState<string>(null);
  // const [transformedExtractedLinesObject, setTransformedExtractedLinesObject] =
  //   useState<Record<string, Record<string, string>>>(null);
  // const [transformedExtractedPlansObject, setTransformedExtractedPlansObject] =
  //   useState<Record<string, Record<string, string>>>(null);
  // const [selectedLineFilter, setSelectedLineFilter] = useState<string>(null);
  // const [selectedPlanFilter, setSelectedPlanFilter] = useState<string>(null);

  const [getAssignedBPLineListWithoutPaginationLazy] = useLazyQuery(
    getAssignedBPLineListWithoutPagination()
  );

  const [getAssignedPlanListWithoutPaginationLazy] = useLazyQuery(
    getAssignedPlanListWithoutPagination()
  );

  const [activateAssignedBPPlanAction] = useMutation(activateAssignedBPPlan(), {
    variables: {
      businessUserID: entityId,
      selectedAssignedBPPlanIds: selectedAssignedBPPlanIds,
    },
    errorPolicy: 'all',
    refetchQueries: [getBusinessPartnersPlansList()],
    // onCompleted: () => {
    //   setBusinessPartnerStatus("");
    // },
  });
  const [deactivateAssignedBPPlanAction] = useMutation(
    deactivateAssignedBPPlan(),
    {
      variables: {
        businessUserID: entityId,
        selectedAssignedBPPlanIds: selectedAssignedBPPlanIds,
      },
      errorPolicy: 'all',
      refetchQueries: [getBusinessPartnersPlansList()],
      // onCompleted: () => {
      //   setBusinessPartnerStatus("");
      // },
    }
  );

  // const [getBusinessUserDetailsLazy, entityResult] = useLazyQuery(
  //   getBusinessUserDetailsInfo(),
  //   {
  //     variables: { id: entityId },
  //     errorPolicy: "all",
  //     fetchPolicy: "no-cache",
  //   }
  // );

  const [activateAction] = useMutation(activateBusinessUser(), {
    variables: {
      entityId: entityId,
    },
    errorPolicy: 'all',
    refetchQueries: [getBusinessUserDetailsInfo()],
    onCompleted: () => {
      setBusinessUserStatus('');
    },
  });
  const [deactivateAction] = useMutation(deactivateBusinessUser(), {
    variables: {
      entityId: entityId,
    },
    errorPolicy: 'all',
    refetchQueries: [getBusinessUserDetailsInfo()],
    onCompleted: () => {
      setBusinessUserStatus('');
    },
  });

  const [selectedBusinessPartnersPlansItems, setBusinessPartnersPlansItems] =
    useState<number>(0);
  const [
    _BusinessPartnersPlansActionClicked,
    _setBusinessPartnersPlansActionClicked,
  ] = useState(null);
  // const [
  //   businessPartnersPlansTableLoadingState,
  //   setBusinessPartnersPlansTableLoadingState,
  // ] = useState<boolean>(false);
  const [businessPartnersPlansTableData, setBusinessPartnersPlansTableData] =
    useState<IListingData<any>>({
      pagedItems: {},
      pageSize: 10,
      pageNumber: 0,
      totalCount: 0,
    });

  const isAdmin = user.userRoles.includes('Insurance-Admin');
  let businessPartnersPlansListingActions: IEnhancedTableMenuItem[] = [];

  const assignedBPPlanStatus_AllActive = selectedAssignedBPPlanStatus.every(
    (status) => status === 'Active'
  );
  const assignedBPPlanStatus_SomeInactive = selectedAssignedBPPlanStatus.some(
    (status) => status === 'Inactive'
  );
  const assignedBPPlanStatus_SomeActive = selectedAssignedBPPlanStatus.some(
    (status) => status === 'Active'
  );
  const assignedBPPlanBusinessPartnerLineStatus_AllActive =
    selectedAssignedBPPlanBusinessPartnerLineStatus.every(
      (status) => status === 'ACTIVE'
    );
  const assignedBPPlanBusinessPartnerPlanStatus_AllActive =
    selectedAssignedBPPlanBusinessPartnerPlanStatus.every(
      (status) => status === 'ACTIVE'
    );

  businessPartnersPlansListingActions = [
    {
      title: 'Assign Business Partner and Plan',
      onClick: () => {
        setBusinessPartnersPlansDrawerOpen(true);
      },
      isEntity: false,
      isBulk: false,
      iconUrl: tenant.cdnUrl + '/icons/add-primary-red.svg',
      hidden: !isAdmin,
      disabled: businessUserStatus === 'inactive',
    },
    {
      title: 'Activate Plan(s)',
      onClick: () => undefined,
      isEntity: false,
      isBulk: false,
      iconUrl: tenant.cdnUrl + '/icons/edit-secondary.svg',
      hidden: !isAdmin,
      disabled:
        selectedBusinessPartnersPlansItems === 0
          ? true
          : selectedBusinessPartnersPlansItems > 1
            ? !(
                (selectedBusinessPartnersPlansItems > 1 &&
                  businessUserStatus === 'active') ||
                (businessUserStatus === 'new' &&
                  assignedBPPlanStatus_AllActive === false &&
                  assignedBPPlanStatus_SomeActive === false &&
                  assignedBPPlanBusinessPartnerLineStatus_AllActive === true &&
                  assignedBPPlanBusinessPartnerPlanStatus_AllActive === true)
              )
            : !(
                (selectedBusinessPartnersPlansItems > 0 &&
                  businessUserStatus === 'active') ||
                (businessUserStatus === 'new' &&
                  assignedBPPlanStatus_AllActive === false &&
                  assignedBPPlanBusinessPartnerLineStatus_AllActive === true &&
                  assignedBPPlanBusinessPartnerPlanStatus_AllActive === true)
              ),
    },
    {
      title: 'Deactivate Plan(s)',
      onClick: () => undefined,
      isEntity: false,
      isBulk: false,
      iconUrl: tenant.cdnUrl + '/icons/edit-secondary.svg',
      hidden: !isAdmin,
      disabled:
        selectedBusinessPartnersPlansItems === 0
          ? true
          : selectedBusinessPartnersPlansItems > 1
            ? !(
                (selectedBusinessPartnersPlansItems > 1 &&
                  businessUserStatus === 'active') ||
                (businessUserStatus === 'new' &&
                  assignedBPPlanStatus_AllActive === true &&
                  assignedBPPlanStatus_SomeInactive === false &&
                  assignedBPPlanBusinessPartnerLineStatus_AllActive === true &&
                  assignedBPPlanBusinessPartnerPlanStatus_AllActive === true)
              )
            : !(
                (selectedBusinessPartnersPlansItems > 0 &&
                  businessUserStatus === 'active') ||
                (businessUserStatus === 'new' &&
                  assignedBPPlanStatus_AllActive === true &&
                  assignedBPPlanBusinessPartnerLineStatus_AllActive === true &&
                  assignedBPPlanBusinessPartnerPlanStatus_AllActive === true)
              ),
    },
  ];
  businessPartnersPlansListingActions[1].onClick = () => {
    const confirmation: IConfirmation = {
      open: true,
      title: 'Activate',
      message: `Are you sure you want to activate the selected assigned plan(s)?`,
      callback: async () => {
        activateAssignedBPPlanAction()
          .then((result) => {
            if (isEmpty(result.errors)) {
              toast.success(
                <ToastSuccessMessage>
                  {'Assigned plan(s) successfully activated.'}
                </ToastSuccessMessage>
              );
            } else {
              toast.error(
                <ToastErrorMessage>{getError(result)}</ToastErrorMessage>
              );
            }
          })
          .finally(() => undefined);
      },
      submitButtonText: 'Submit',
      cancelButtonText: 'Cancel',
    };
    dispatch(OpenConfirmationAction(confirmation));
  };
  businessPartnersPlansListingActions[2].onClick = () => {
    const confirmation: IConfirmation = {
      open: true,
      title: 'Deactivate',
      message: `Are you sure you want to deactivate the selected assigned plan(s)?`,
      callback: async () => {
        deactivateAssignedBPPlanAction()
          .then((result) => {
            if (isEmpty(result.errors)) {
              toast.success(
                <ToastSuccessMessage>
                  {'Assigned plan(s) successfully deactivated.'}
                </ToastSuccessMessage>
              );
            } else {
              toast.error(
                <ToastErrorMessage>{getError(result)}</ToastErrorMessage>
              );
            }
          })
          .finally(() => undefined);
      },
      submitButtonText: 'Submit',
      cancelButtonText: 'Cancel',
    };
    dispatch(OpenConfirmationAction(confirmation));
  };

  const [entityResultQuery] = useLazyQuery(getBusinessUserDetailsInfo());

  const loadEntityData = async () => {
    const result = await entityResultQuery({
      variables: { id: entityId },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });

    return result;
  };

  const [businessPartnersPlansListLazy] = useLazyQuery(
    getBusinessPartnersPlansList()
  );

  const loadBusinessPartnersPlansList = async (
    currentPage = 0,
    pageSize = businessPartnersPlansTableData.pageSize,
    orders: Record<string, ITableOrder> = tableOrders,
    searchKeyword = keywordSearch,
    filterV = filterValues
  ) => {
    const result = await businessPartnersPlansListLazy({
      variables: {
        currentPage: currentPage + 1,
        currentPageSize: pageSize,
        selectedBusinessUserID: entityId,
        selectedBusinessPartnerID:
          filterV?.bp && filterV?.bp?.length > 0 ? filterV?.bp : [],
        selectedLineID:
          filterV?.line && filterV?.line?.length > 0 ? filterV?.line : [],
        selectedPlanID:
          filterV?.plan && filterV?.plan?.length > 0 ? filterV?.plan : [],
        Attribute: capitalizeFirstCharacter(
          orders?.plan?.orderBy || 'BusinessPartner_Code'
        ),
        Descending: orders?.plan?.orderDirection == 'asc' ? false : true,
        keywordSearch: searchKeyword || null,
      },
      errorPolicy: 'all',
    });

    return result;
  };

  const initialize = async () => {
    const [entityData, planData] = await Promise.all([
      loadEntityData(),
      loadBusinessPartnersPlansList(0, 10),
    ]);

    await handleMainEntityResult(entityData.data);

    const mappedPlanList = await mapToBusinessPartnersPlansListingData(
      planData.data
    );

    let savedFilters = getFilter(FILTER_SESSION_KEY);
    if (isEmpty(savedFilters)) {
      savedFilters = _.cloneDeep(filterValues);
    }
    setFilterValues(savedFilters);

    const [assignedBP, assignedPlanList] = await Promise.all([
      getAssignedBPLineListWithoutPaginationLazy({
        fetchPolicy: 'no-cache',
        variables: { selectedBusinessUserID: entityId },
      }),
      getAssignedPlanListWithoutPaginationLazy({
        fetchPolicy: 'no-cache',
        variables: {
          selectedBusinessUserID: entityId,
          selectedLineID: filterValues?.line,
        },
      }),
    ]);

    const lovs = extractAssignedBP(assignedBP.data, assignedPlanList.data);

    if (!Object.keys(lovs.line).length) {
      lovs.line = filterValues?.line;
    }

    if (!Object.keys(lovs.plan).length) {
      lovs.plan = filterValues?.plan;
    }

    if (!Object.keys(lovs.bp).length) {
      lovs.bp = filterValues?.bp;
    }

    const newFilterSections = filterSectionsContent(lovs, savedFilters);
    setFilterSections(newFilterSections);

    setBusinessPartnersPlansTableData({ ...mappedPlanList, pageNumber: 0 });

    setBooted(true);
    setLoadingState(false);
  };

  useEffect(() => {
    initialize();
  }, []);

  const handleSearchChange = (search: string) => {
    setKeywordSearch(search);
  };

  const delaySearch = (val: string) => {
    clearTimeout(setSearchTimeout);
    setSearchTimeout = setTimeout(() => {
      handleSearchChange(val);
      handleBPPlanPageChange(
        0,
        businessPartnersPlansTableData.pageSize,
        tableOrders,
        val
      );
    }, 500);
  };

  function handleSortChange(orderBy: string, order: string) {
    const newOrder = _.cloneDeep(tableOrders);

    newOrder.plan.orderBy = orderBy;
    newOrder.plan.orderDirection = order as any;
    handleBPPlanPageChange(
      businessPartnersPlansTableData.pageNumber,
      businessPartnersPlansTableData.pageSize,
      newOrder
    );

    setTableOrders(newOrder);
  }

  async function handleBPPlanPageChange(
    page: number,
    pageSize: number,
    orders: Record<string, ITableOrder> = tableOrders,
    searchKeyword: string = keywordSearch,
    filterV = filterValues
  ) {
    if (!loadingState) {
      setLoadingState(true);
      const result = await loadBusinessPartnersPlansList(
        page,
        pageSize,
        orders,
        searchKeyword,
        filterV
      );
      const mappedSubList = await mapToBusinessPartnersPlansListingData(
        result.data
      );
      setBusinessPartnersPlansTableData({
        ...mappedSubList,
        pageNumber: page,
        pageSize: pageSize,
      });

      setLoadingState(false);
    }
  }

  // const {
  //   control,
  //   formState: { errors },
  // } = useForm<ISalesforceBusinessUserDetails>({
  //   defaultValues: initialValues,
  //   shouldUnregister: true,
  // });

  (
    businessPartnersPlansHeaders.person_FullName as IEnhanceTableHeaderClickable
  ).callback = (payload: any) => {
    navigate(
      '/salesforce/business-partners/' +
        payload.columns.businessPartner_PersonID_ID
    );
  };
  (
    businessPartnersPlansHeaders.line_Name as IEnhanceTableHeaderClickable
  ).callback = (payload: any) => {
    navigate(
      '/plan/lines/' +
        payload.columns.salesforceManagement_AssignedBPPlan_LineID_ID
    );
  };
  (
    businessPartnersPlansHeaders.plan_Name as IEnhanceTableHeaderClickable
  ).callback = (payload: any) => {
    navigate(
      '/plan/plans/' +
        payload.columns.salesforceManagement_AssignedBPPlan_PlanID_ID
    );
  };

  const handleMainEntityResult = (data: any) => {
    try {
      // if (!entityResult.loading) {
      //   setLoadingState(false);
      // }

      if (data) {
        if (isEmpty(businessUserStatus)) {
          setBusinessUserStatus(
            data?.SalesforceManagement?.entities?.businessUser?.views?.SalesforceManagement_all?.properties?.Status.toLowerCase()
          );
        }

        if (isEmpty(businessUserType)) {
          setBusinessUserType(
            data?.SalesforceManagement?.entities?.businessUser?.views?.SalesforceManagement_all?.properties?.Type?.Title.toLowerCase()
          );
        }

        const businessUserDetails = entityToBusinessUserDetails(data);

        //get application section properties
        const applicationProperties = entityView.sections[0];
        applicationProperties.properties.name = {
          multiline: false,
          name: 'name',
          title: 'Full Name',
          value: businessUserDetails.name,
          type: EnhancedDisplayType.RelativeLink,
          tooltip: false,
          url: '/salesforce/persons/' + businessUserDetails.personId,
        };
        applicationProperties.properties.businessUserCode.value =
          businessUserDetails.businessUserCode;
        applicationProperties.properties.dateOfBirth.value =
          businessUserDetails.dateOfBirth
            ? dayjs(new Date(businessUserDetails.dateOfBirth)).format(
                'DD/MM/YYYY'
              )
            : null;
        applicationProperties.properties.email.value =
          businessUserDetails.email;
        applicationProperties.properties.phoneType.value =
          businessUserDetails.phoneType;
        applicationProperties.properties.phoneNumber.value =
          businessUserDetails.phoneNumber;
        applicationProperties.properties.taxable.value =
          businessUserDetails.taxable;

        //get the system propeties section
        const systemProperties = entityView.sections[1];
        systemProperties.properties.createdBy.value =
          businessUserDetails.createdBy;
        systemProperties.properties.createdOn.value = dayjs(
          new Date(businessUserDetails.createdOn)
        ).format('DD/MM/YYYY');
        systemProperties.properties.modifiedBy.value =
          businessUserDetails.modifiedBy;
        systemProperties.properties.modifiedOn.value = dayjs(
          new Date(businessUserDetails.modifiedOn)
        ).format('DD/MM/YYYY');

        const newLeftEntityView = {
          ...entityView,
          sections: [applicationProperties, systemProperties],
        };



        const dynamicView: IDynamicDiplayView = {
          name: 'Business User Information',
          sections: [
            {
              name: 'businessUserDetails',
              title: 'Business User Details',
              showActionButtons: true,
              properties: {
                type: {
                  multiline: false,
                  value: capitalizeFirstLetter(businessUserDetails.typeTitle),
                  name: 'type',
                  title: 'Type',
                },
                account: {
                  multiline: false,
                  value: businessUserDetails.account,
                  name: 'account',
                  title: 'Account',
                },
                access: {
                  multiline: false,
                  value: businessUserDetails.accessTitle,
                  name: 'access',
                  title: 'Access',
                },
              },
            },
          ],
        };

        const authoritiesView: IDynamicDiplayView = {
          name: 'Business User Authorities',
          sections: [
            {
              name: 'authoritiesDetails',
              title: 'Authorities',
              showActionButtons: true,
              properties: {
                editableLineCharges: {
                  multiline: false,
                  value: businessUserDetails.editableLineChargesTitle,
                  name: 'editableLineCharges',
                  title: 'Editable Line Charges',
                },
                allowPolicyEndDateEdits: {
                  multiline: false,
                  value: businessUserDetails.allowPolicyEndDateEdits
                    ? 'Yes'
                    : 'No',
                  name: 'allowPolicyEndDateEdits',
                  title: 'Allow Policy End Date Edits',
                },
                allowPlanCoverEdits: {
                  multiline: false,
                  value: businessUserDetails.allowPlanCoverEdits ? 'Yes' : 'No',
                  name: 'allowPlanCoverEdits',
                  title: 'Allow Plan Cover Details Edits',
                },
                allowClauseEdits: {
                  multiline: false,
                  value: businessUserDetails.allowClauseEdits ? 'Yes' : 'No',
                  name: 'allowClauseEdits',
                  title: 'Allow Clause Edits',
                },
                allowGroupPolicy: {
                  multiline: false,
                  value: businessUserDetails.allowGroupPolicy ? 'Yes' : 'No',
                  name: 'allowGroupPolicy',
                  title: 'Allow Group Policy',
                },
              },
            },
          ],
        };
        setBusinessUserAuthoritiesViewConfig(authoritiesView);
        setBusinessUserDetailsInfo(businessUserDetails);
        setUpdatedLeftSideInfo(newLeftEntityView);
        setBusinessUserInformationViewConfig(dynamicView);

        const newStatus =
          data?.SalesforceManagement?.entities?.businessUser?.views?.SalesforceManagement_all?.properties?.Status.toLowerCase();
        setStatus(newStatus);
      }
    } catch (error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  // useEffect(() => {
  //   getBusinessUserDetailsLazy();
  //   businessPartnersPlansListLazy();
  // }, []);

  // useEffect(() => {
  //   if (businessPartnersPlansListResult?.data) {
  //     const businessPartnersPlanTableData =
  //       mapToBusinessPartnersPlansListingData(
  //         businessPartnersPlansListResult?.data
  //       );

  //     const extractedLines = extractDistinctLines(
  //       businessPartnersPlansListResult?.data
  //     );
  //     const extractedLinesPagedItemsObject = extractedLines.pagedItems;
  //     const extractedLinesPagedItemsArray = Object.keys(
  //       extractedLinesPagedItemsObject
  //     ).map((key) => extractedLinesPagedItemsObject[key]);
  //     setTransformedExtractedLinesObject(
  //       extractedLinesPagedItemsArray.reduce((acc, item) => {
  //         acc[item.salesforceManagement_AssignedBPPlan_LineID_ID] =
  //           item.salesforceManagement_AssignedBPPlan_LineID_Name;
  //         return acc;
  //       }, {})
  //     );

  //     const extractedPlans = extractDistinctPlans(
  //       businessPartnersPlansListResult?.data
  //     );
  //     const extractedPlansPagedItemsObject = extractedPlans.pagedItems;
  //     const extractedPlansPagedItemsArray = Object.keys(
  //       extractedPlansPagedItemsObject
  //     ).map((key) => extractedPlansPagedItemsObject[key]);
  //     setTransformedExtractedPlansObject(
  //       extractedPlansPagedItemsArray.reduce((acc, item) => {
  //         acc[item.salesforceManagement_AssignedBPPlan_PlanID_ID] =
  //           item.salesforceManagement_AssignedBPPlan_PlanID_Name;
  //         return acc;
  //       }, {})
  //     );

  //     setBusinessPartnersPlansTableData({
  //       ...businessPartnersPlanTableData,
  //       pageNumber: 0,
  //     });
  //   }
  //   setLoadingState(false);
  // }, [
  //   businessPartnersPlansListResult?.loading,
  //   businessPartnersPlansListResult?.error,
  //   businessPartnersPlansListResult?.data,
  // ]);

  const renderMainChildren = () => {
    const isBusinessUserActive = status === 'active';
    const isBusinessUserNew = status === 'new';
    const steps = isBusinessUserNew
      ? ['New', 'Active']
      : ['New', isBusinessUserActive ? 'Active' : 'Inactive'];

    return (
      <>
        <div style={{ marginTop: '20px' }}>
          <EnhancedStepper
            activeStep={getBusinessUserStatus(steps, status)}
            steps={steps}
            className={classes.stepper}
            showStepperButton
            buttonTitle={isBusinessUserActive ? 'Deactivate' : 'Activate'}
            buttonState={
              isBusinessUserActive ? activateButtonState : deactivateButtonState
            }
            buttonOnClick={() => {
              if (isBusinessUserActive) {
                const confirmation: IConfirmation = {
                  open: true,
                  title: 'Deactivate',
                  message: `Are you sure you want to deactivate this business user?`,
                  callback: async () => {
                    setDeactivateButtonState('loading');
                    deactivateAction()
                      .then((result) => {
                        if (isEmpty(result.errors)) {
                          setDeactivateButtonState('success');
                          toast.success(
                            <ToastSuccessMessage>
                              {'Business user successfully deactivated.'}
                            </ToastSuccessMessage>
                          );
                        } else {
                          setDeactivateButtonState(undefined);
                          toast.error(
                            <ToastErrorMessage>
                              {getError(result)}
                            </ToastErrorMessage>
                          );
                        }
                      })
                      .finally(() => {
                        setTimeout(() => {
                          setDeactivateButtonState(undefined);
                        }, 1000);
                      });
                  },
                  submitButtonText: 'Yes',
                  cancelButtonText: 'No',
                };
                dispatch(OpenConfirmationAction(confirmation));
              } else {
                const confirmation: IConfirmation = {
                  open: true,
                  title: 'Activate',
                  message: `Are you sure you want to activate this business user?`,
                  callback: async () => {
                    setActivateButtonState('loading');
                    activateAction()
                      .then((result) => {
                        if (isEmpty(result.errors)) {
                          setActivateButtonState('success');
                          toast.success(
                            <ToastSuccessMessage>
                              {'Business User successfully activated.'}
                            </ToastSuccessMessage>
                          );
                        } else {
                          setActivateButtonState(undefined);
                          toast.error(
                            <ToastErrorMessage>
                              {getError(result)}
                            </ToastErrorMessage>
                          );
                        }
                      })
                      .finally(() => {
                        setTimeout(() => {
                          setActivateButtonState(undefined);
                        }, 1000);
                      });
                  },
                  submitButtonText: 'Yes',
                  cancelButtonText: 'No',
                };
                dispatch(OpenConfirmationAction(confirmation));
              }
            }}
          />
        </div>
      </>
    );
  };

  const renderLeftSection = () => {
    return (
      <>
        <EntityInfoWidget
          background="rgb(193, 0, 0)"
          classification={{ classifications: {}, value: '' }}
          progress={100}
          supportsClassification={true}
          supportsProgressBar={true}
          title={businessUserDetailsInfo.name}
          view={updatedLeftSideInfo}
        ></EntityInfoWidget>
      </>
    );
  };

  const onFilterUpdate = async (v: Record<string, any>) => {
    const newFilters = _.cloneDeep(v);
    setFilter(newFilters, FILTER_SESSION_KEY);
    setFilterValues(newFilters);
    handleBPPlanPageChange(
      0,
      businessPartnersPlansTableData.pageSize,
      tableOrders,
      keywordSearch,
      newFilters
    );
  };

  const renderFilter = () => {
    return (
      filterSections && (
        <InlineFilterWidget
          name={''}
          filters={filterSections}
          onApplyFilter={(v) => {
            onFilterUpdate(v);
          }}
        ></InlineFilterWidget>
      )
    );
  };

  //#region tabs
  const tabs = () => {
    businessUserTabs.tabs[0].widgets[0].children = (
      <>
        {businessUserInformationViewConfig && (
          <>
            <DynamicWidget
              hasFourFields={true}
              view={businessUserInformationViewConfig}
              actions={
                <ShowForUser allowedRoles={['Insurance-Admin']}>
                  {status != 'inactive' && (
                    <div>
                      <EnhancedButton
                        type="button"
                        backgroundColor={MAIN_ONE_THEME.palette.primary1.main}
                        color="#FFF"
                        onClick={() => setBusinessUserInfoDrawerOpen(true)}
                        className={classes.actionButton}
                      >
                        Modify
                      </EnhancedButton>
                    </div>
                  )}
                </ShowForUser>
              }
            />
          </>
        )}
        {businessUserAuthoritiesViewConfig && (
          <div
            style={{
              marginTop: '20px',
              padding: '-23px 0 0 0 !important',
            }}
          >
            <DynamicWidget
              hasFourFields={true}
              view={businessUserAuthoritiesViewConfig}
              actions={
                <ShowForUser allowedRoles={['Insurance-Admin']}>
                  {status != 'inactive' && (
                    <div>
                      <EnhancedButton
                        type="button"
                        backgroundColor={MAIN_ONE_THEME.palette.primary1.main}
                        color="#FFF"
                        onClick={() =>
                          setBusinessUserAuthoritiesDrawerOpen(true)
                        }
                        className={classes.actionButton}
                      >
                        Modify
                      </EnhancedButton>
                    </div>
                  )}
                </ShowForUser>
              }
            />
          </div>
        )}
      </>
    );

    businessUserTabs.tabs[1].widgets[0].children = (
      <>
        {
          <>
            <WidgetSection style={{ margin: '-23px 0 0 0' }}>
              <form>
                <div style={{ marginTop: '20px', padding: '0' }}>
                  <EnhancedTable
                    title={'Business Partners and Plans'}
                    inlineTitle={'Business Partners and Plans'}
                    lineNameController={
                      <>
                        <div className={classes.container}>
                          {renderFilter()}
                        </div>
                      </>
                    }
                    name="count"
                    entityName={'AssignedBPPlan'}
                    entityIdColumnName={
                      'salesforceManagement_AssignedBPPlan_Id'
                    }
                    showCellFullData={true}
                    isTitlePascalCase={true}
                    orderByAscendingByDefault
                    defaultOrderByColumn="name"
                    inline={true}
                    data={businessPartnersPlansTableData}
                    headers={businessPartnersPlansHeaders}
                    handlePageChange={(page: number) =>
                      handleBPPlanPageChange(
                        page,
                        businessPartnersPlansTableData.pageSize
                      )
                    }
                    handleRowsPerPageChange={(pageSize: number) =>
                      handleBPPlanPageChange(0, pageSize)
                    }
                    handleSort={(orderBy: string, order: string) =>
                      handleSortChange(orderBy, order)
                    }
                    handleSearchChange={delaySearch}
                    currentPage={businessPartnersPlansTableData.pageNumber}
                    hideToolbar={false}
                    usePagination
                    secondaryActions={true}
                    specificDesign={true}
                    disableSelection={false}
                    actions={businessPartnersPlansListingActions}
                    //loader={businessPartnersPlansTableLoadingState}
                    showTablePagination={true}
                    onSelectionChange={(newDetailedSelection) => {
                      setBusinessPartnersPlansItems(
                        newDetailedSelection.length
                      );
                      if (newDetailedSelection.length > 0) {
                        const itemIds = [];

                        const assignedBPPlanStatus = [];
                        const assignedBPPlanBusinessPartnerLineStatus = [];
                        const assignedBPPlanBusinessPartnerPlanStatus = [];

                        for (const item of newDetailedSelection) {
                          itemIds.push(item.id);
                          assignedBPPlanStatus.push(
                            item.salesforceManagement_AssignedBPPlan_Status
                          );
                          assignedBPPlanBusinessPartnerLineStatus.push(
                            item.salesforceManagement_AssignedBPPlan_BusinessPartnerLineID_Status
                          );
                          assignedBPPlanBusinessPartnerPlanStatus.push(
                            item.salesforceManagement_AssignedBPPlan_BusinessPartnerPlanID_Status
                          );
                        }
                        setSelectedAssignedBPPlanIds(itemIds);
                        setSelectedAssignedBPPlanStatus(assignedBPPlanStatus);
                        setSelectedAssignedBPPlanBusinessPartnerLineStatus(
                          assignedBPPlanBusinessPartnerLineStatus
                        );
                        setSelectedAssignedBPPlanBusinessPartnerPlanStatus(
                          assignedBPPlanBusinessPartnerPlanStatus
                        );
                      } else {
                        setSelectedAssignedBPPlanIds([]);
                        setSelectedAssignedBPPlanStatus([]);
                        setSelectedAssignedBPPlanBusinessPartnerLineStatus([]);
                        setSelectedAssignedBPPlanBusinessPartnerPlanStatus([]);
                      }
                    }}
                    ordering={tableOrders.plan.orderDirection as TableSortOrder}
                    orderingBy={tableOrders.plan.orderBy}
                  />
                  {businessPartnersPlansDrawerOpen && (
                    <SalesforceAssignedBPPlanDrawer
                      open={businessPartnersPlansDrawerOpen}
                      onClose={() => setBusinessPartnersPlansDrawerOpen(false)}
                      businessUserId={entityId}
                      onSuccess={() => {
                        handleBPPlanPageChange(0, 10);
                      }}
                    />
                  )}
                </div>
              </form>
            </WidgetSection>
          </>
        }
      </>
    );

    return businessUserTabs;
  };
  //#endregion tabs

  return !booted ? (
    <Loader />
  ) : (
    <>
      <div>
        {businessUserInfoDrawerOpen && (
          <SalesForceBusinessUserDrawer
            open={businessUserInfoDrawerOpen}
            onClose={() => setBusinessUserInfoDrawerOpen(false)}
            onSuccess={() => {
              // handleBPPlanPageChange(0, 10);
              setBusinessUserInfoDrawerOpen(false);
              initialize();
            }}
            businessUserId={params.id}
            salesForceBusinessUserDetailsInfo={businessUserDetailsInfo as any}
          />
        )}
        {businessUserAuthoritiesDrawerOpen && (
          <SalesForceBusinessUserAuthoritiesDrawer
            open={businessUserAuthoritiesDrawerOpen}
            onClose={() => setBusinessUserAuthoritiesDrawerOpen(false)}
            onSuccess={() => {
              setBusinessUserInfoDrawerOpen(false);
              initialize();
            }}
            businessUserId={params.id}
            authoritiesDetailsInfo={businessUserDetailsInfo as any}
          />
        )}
      </div>
      <TabsLayout
        name="policyPageDetails"
        layout={tabs()}
        theme={tenant.theme}
        leftChildren={renderLeftSection()}
        mainChildren={renderMainChildren()}
        cdnUrl={tenant.cdnUrl}
        userInfo={user['info']}
        tabPanelClassName={classes.tabPanelStyle}
        firstTabAsActiveTab={true}
      />
    </>
  );
};

export default SalesforceBusinessUserDetailsPage;
