import { gql } from '@apollo/client';

export function getProposalExpatDetails() {
  return gql`
    query getProposalGenericDetails($id: String!) {
      Production {
        entities {
          proposal(id: $id) {
            id
            views {
              Production_all {
                properties {
                  Id
                  ProposalName
                  ClauseText
                  InternalCode
                  PolicyEffectiveDate
                  PolicyExpiryDate
                  modifiedOn
                  createdOn
                  PolicyCurrency {
                    Code
                    Title
                    Symbol
                  }
                  Status
                  StatusReason
                  DescriptionOfRisk
                  GeoLocation {
                    Id
                    Code
                    Title
                  }
                  MarineDetailsID {
                    id
                    views {
                      Production_all {
                        properties {
                          Id
                          Matter {
                            Id
                            Title
                            Code
                          }
                          Description
                          VesselName
                          VesselAge
                          DepartureFrom
                          DestinationTo
                          TransportationVia
                          Survey
                          CargoContract
                          LCNumber
                          LCIssueDate
                          Bank
                          ClaimsPayableBy
                          Loading
                          Remarks
                          SumPerShipment
                        }
                      }
                    }
                  }
                  MarineHullDetailsID {
                    id
                    views {
                      Production_all {
                        properties {
                          VesselName
                          ConnectionPort
                          TerritorialWaters
                          Usage
                          Builder
                          ConstructionYear
                          RegisteryPort
                          Length
                          Weight
                          Height
                          EngineType
                          EngineNumber
                          EngineYearBuilt
                          EngineHorsePower
                          EngineMaxDesignedSpeed
                          EngineFuel
                          Remarks
                        }
                      }
                    }
                  }
                  PrimaryBPID {
                    id
                    views {
                      SalesforceManagement_all {
                        properties {
                          Code
                          RelatedCompany {
                            Code
                            Title
                          }
                          PersonID {
                            views {
                              SalesforceManagement_all {
                                properties {
                                  FullName
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  LineID {
                    id
                    views {
                      PlanConfigManagement_all {
                        properties {
                          Name
                          ExternalCode
                        }
                      }
                    }
                  }
                  SublineID {
                    id
                    views {
                      PlanConfigManagement_all {
                        properties {
                          Name
                          ExternalCode
                        }
                      }
                    }
                  }
                  PlanID {
                    id
                    views {
                      PlanConfigManagement_all {
                        properties {
                          Name
                          ExternalCode
                          ClauseEditable
                          MaxNbInsured
                          MaxNbBeneficiary
                          AcalCategory {
                            Id
                            Code
                            Title
                          }
                          Scratching
                          SOSService
                          ReplacementCarApplied
                          ReplacementCarValue
                          ReplacementCarDays
                        }
                      }
                    }
                  }
                  BusinessUserID {
                    views {
                      SalesforceManagement_all {
                        properties {
                          Code
                          AllowPlanCoverEdits
                          AllowClauseEdits
                          EditableLineChargesIDs {
                            id
                            views {
                              PlanConfigManagement_all {
                                properties {
                                  Id
                                }
                              }
                            }
                          }
                          PersonID {
                            views {
                              SalesforceManagement_all {
                                properties {
                                  FullName
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            queries {
              production {
                relatedUsers {
                  createdBy_firstName
                  createdBy_lastName
                  modifiedBy_firstName
                  modifiedBy_lastName
                }
              }
              production {
                pricingOption {
                  policy_PolicyNumber
                  policy_Id
                  pricingOption_PolicyCost
                  pricingOption_ChargesPercentage
                  pricingOption_TPAFeesType
                  pricingOption_TPAFeesAmount
                  pricingOption_TPAFeesPercentage
                  pricingOption_TPAFeesApplicableOn
                  pricingOption_BasicPremium
                  pricingOption_PlanToPolicyRate
                  pricingOption_NetPremium
                  pricingOption_GrossPremium
                  pricingOption_TaxOnCommissionAmount
                  pricingOption_ReinsuranceTaxAmount
                  pricingOption_MunicipalityTaxAmount
                  pricingOption_TotalPremium
                  pricingOption_ChargesAmount
                  pricingOption_TPAFeesTotalAmount
                  pricingOption_FixedStampAmount
                  pricingOption_ProportionalStampAmount
                  pricingOption_Status
                  pricingOption_createdOn
                  pricingOption_SumInsured
                  pricingOption_PricingOptionCurrency {
                    Symbol
                  }
                }
              }
            }
          }
        }
        queries {
          getPolicyPlanCovers(selectedProposalID: $id) {
            production_PolicyPlanCover_ProposalID {
              id
              views {
                Production_all {
                  properties {
                    PolicyCurrency {
                      Code
                      Title
                    }
                  }
                }
              }
            }
            policyCover_Id
            policyCover_Name
            production_PolicyPlanCover_Id
            production_PolicyPlanCover_CoverEffectiveFrom
            production_PolicyPlanCover_CoverEffectiveTo
            production_PolicyPlanCover_CoverPremiumPercentage
            production_PolicyPlanCover_CoverPremiumValue
            production_PolicyPlanCover_ExcessOnClaimType
            production_PolicyPlanCover_ExcessOnClaimAmount
            production_PolicyPlanCover_ExcessOnClaimPercentage
            production_PolicyPlanCover_ExcessOnClaimDays
            production_PolicyPlanCover_ClauseID {
              id
            }
            production_PolicyPlanCover_CoverPremium
            production_PolicyPlanCover_TotalExcessOnClaim
            production_PolicyPlanCover_CoverSumInsuredBasic
            production_PolicyPlanCover_CoverPremiumBasic
            production_PolicyPlanCover_CoverSumInsuredArabic
            production_PolicyPlanCover_CoverPremiumArabic
            planCover_Id
            planCover_PolicyCoverID {
              id
            }
            production_PolicyPlanCover_IsMain
            production_PolicyPlanCover_IsEditable
            production_PolicyPlanCover_CoverSumInsured
            production_PolicyPlanCover_SumInsuredIsAdditive
            production_PolicyPlanCover_IsMandatory
            production_PolicyPlanCover_CoverPremiumType
          }
          getPolicyBankersBlanketBondDetails(selectedProposalID: $id) {
            production_PolicyBBBond_Order
            production_PolicyBBBond_BranchName
            production_PolicyBBBond_IsMain
            production_PolicyBBBond_IsAgency
            production_PolicyBBBond_NumberOfEmployees
            production_PolicyBBBond_UnderwritingYear
            production_PolicyBBBond_Infidelity
            production_PolicyBBBond_Burglary
            production_PolicyBBBond_HoldUp
            production_PolicyBBBond_InSafe
            production_PolicyBBBond_InTransit
            production_PolicyBBBond_ForgedCheckes
            production_PolicyBBBond_CounterFeitCurrencies
            production_PolicyBBBond_Clause6
            production_PolicyBBBond_Falsification
          }
          getPolicyPropertyDetails(selectedProposalID: $id) {
            production_PolicyProperty_Id
            production_PolicyProperty_Order
            production_PolicyProperty_Id
            production_PolicyProperty_PlanCoverPropertyDetails {
              RiskDescription
              Location
              GeoLocation {
                Id
                Code
                Title
              }
              PlanCoverID
              RiskRate
              SumInsured
              Premium
            }
          }
          getPolicyBusienssPartnerDetails(selectedProposalID: $id) {
            production_PolicyBusinessPartner_Id
            production_PolicyBusinessPartner_BusinessPartnerID {
              id
              views {
                SalesforceManagement_all {
                  properties {
                    PersonID {
                      id
                      views {
                        SalesforceManagement_all {
                          properties {
                            FullName
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            production_PolicyBusinessPartner_PlanCommissionType
            production_PolicyBusinessPartner_PlanCommissionRate
            production_PolicyBusinessPartner_CommissionPercentage
            production_PolicyBusinessPartner_PlanCommissionAmount
            production_PolicyBusinessPartner_IsPrimary
            production_PolicyBusinessPartner_ApplicableOn {
              Code
              createdOn
              Status
            }

            production_PolicyBusinessPartner_PolicyCommissionAmount
          }
          GetPolicyPerson(proposalID: $id) {
            production_PolicyPerson_Id
            production_PolicyPerson_PersonID {
              id
            }
            production_PolicyPerson_Percentage
            production_PolicyPerson_PersonCode
            production_PolicyPerson_FullName
            production_PolicyPerson_Type
            production_PolicyPerson_OwnerIsDifferent
            production_PolicyPerson_LimitOfCover
            production_PolicyPerson_PersonAddressID {
              id
              views {
                SalesforceManagement_all {
                  properties {
                    IsPrimary
                    Address {
                      Country {
                        Id
                        Title
                      }
                      City
                      Street
                      Building
                    }
                  }
                }
              }
            }
            production_PolicyPerson_Address {
              Building
              Street
              City
              Country {
                Id
                Title
              }
              AddressDetails
            }
            production_PolicyPerson_OrderNumber
          }
        }
      }
      Core {
        lookups {
          currencies {
            Id
            Code
            Title
          }
          colors {
            Id
            Code
            Title
          }
          medicalClasses {
            Id
            Code
            Title
          }
          matters {
            Id
            Code
            Title
          }
          geoLocations {
            Id
            Code
            Title
          }
        }
      }
    }
  `;
}

export function fetchedPersons() {
  return gql`
    query fetchedPersons($searchKeyword: String) {
      SalesforceManagement {
        queries {
          AllActivePersons(searchKeyword: $searchKeyword) {
            salesforceManagement_Person_Id
            salesforceManagement_Person_FullName
            salesforceManagement_Person_PersonCode
          }
        }
      }
    }
  `;
}

export function fetchedPersonAddressList() {
  return gql`
    query fetchedPersonAddressList($selectedPersonID: String) {
      SalesforceManagement {
        queries {
          getPersonAddressListWithoutPagination(
            selectedPersonID: $selectedPersonID
          ) {
            salesforceManagement_PersonAddress_Id
            salesforceManagement_PersonAddress_AddressType
            salesforceManagement_PersonAddress_Address {
              Country {
                Title
              }
              City
              Street
              Building
            }
            salesforceManagement_PersonAddress_IsPrimary
          }
        }
      }
    }
  `;
}

export function getNonSelectedPlanCover() {
  return gql`
    query getNonSelectedPlanCover(
      $selectedPlanID: String!
      $selectedPlanCoverIDs: [String]!
    ) {
      PlanConfigManagement {
        queries {
          getNonSelectedPlanCover(
            selectedPlanID: $selectedPlanID
            selectedPlanCoverIDs: $selectedPlanCoverIDs
          ) {
            policyCover_Id
            planConfigManagement_PlanCover_Id
            planConfigManagement_PlanCover_IsMain
            planConfigManagement_PlanCover_IsMandatory
            planConfigManagement_PlanCover_IsEditable
            planConfigManagement_PlanCover_CoverPremiumType
            planConfigManagement_PlanCover_ExcessOnClaimAmount
            planConfigManagement_PlanCover_ExcessOnClaimPercentage
            planConfigManagement_PlanCover_CoverPremiumValue
            planConfigManagement_PlanCover_CoverPremiumPercentage
            planConfigManagement_PlanCover_CoverSumInsured
            planConfigManagement_PlanCover_SumInsuredIsAdditive
            planConfigManagement_PlanCover_ExcessOnClaimType
            planConfigManagement_PlanCover_ExcessOnClaimAmount
            planConfigManagement_PlanCover_ExcessOnClaimPercentage
            planConfigManagement_PlanCover_ExcessOnClaimDays

            planConfigManagement_PlanCover_ClauseID {
              id
            }

            planConfigManagement_PlanCover_PlanID {
              views {
                PlanConfigManagement_all {
                  properties {
                    PlanCurrency {
                      Code
                      Title
                    }
                  }
                }
              }
            }

            planConfigManagement_PlanCover_PolicyCoverID {
              id
              views {
                PlanConfigManagement_all {
                  properties {
                    ExternalCode
                    Name
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function validateMotorVignette() {
  return gql`
    mutation validateMotorVignette(
      $entityId: String!
      $effectiveDate: Date!
      $vignetteCode: String!
      $vignette: Long!
      $businessPartnerID: String!
    ) {
      production {
        entities {
          proposal {
            production {
              validateVignette(
                businessPartnerID: $businessPartnerID
                entityId: $entityId
                effectiveDate: $effectiveDate
                vignetteCode: $vignetteCode
                vignette: $vignette
              )
            }
          }
        }
      }
    }
  `;
}

export function saveProposalDraftGeneric() {
  return gql`
    mutation saveProposalDraftGeneric(
      $entityId: String!
      $policyPersonInput: [Production_policyPersonInputs!]!
      $policyPlanCoverInput: [Production_policyPlanCoverInputs!]!
      $costAndChargeInput: Production_CostAndChargesInputs!
      $bankerInputs: [Production_PolicyBankersBlanketBondInputs]
      $marineInput: Production_PolicyMarineInputs
      $marineHullInput: Production_PolicyMarineHullInputs
      $descriptionOfRisk: String
      $geoLocation: String
      $policyProperty: [Production_PolicyPropertyInput]
    ) {
      production {
        entities {
          proposal {
            production {
              saveProposalDraftGeneric(
                entityId: $entityId
                policyPersonInput: $policyPersonInput
                policyPlanCoverInput: $policyPlanCoverInput
                costAndChargeInput: $costAndChargeInput
                policyBankersBlanketBondInput: $bankerInputs
                policyMarineInput: $marineInput
                policyMarineHullInput: $marineHullInput
                descriptionOfRisk: $descriptionOfRisk
                geoLocation: $geoLocation
                policyPropertyInput: $policyProperty
              ) {
                id
              }
            }
          }
        }
      }
    }
  `;
}

export function calculateAndSaveGeneric() {
  return gql`
    mutation calculateAndSaveGeneric(
      $entityId: String!
      $planId: String!
      $policyCurrency: String!
      $policyEffectiveDate: Date!
      $policyExpiryDate: Date!
      $policyPersonInput: [Production_policyPersonInputs!]!
      $policyPlanCoverInput: [Production_policyPlanCoverInputs!]!
      $costAndChargeInput: Production_CostAndChargesInputs!
      $bankerInputs: [Production_PolicyBankersBlanketBondInputs]
      $marineInput: Production_PolicyMarineInputs
      $marineHullInput: Production_PolicyMarineHullInputs
      $descriptionOfRisk: String
      $geoLocation: String
      $policyProperty: [Production_PolicyPropertyInput]
    ) {
      production {
        entities {
          proposal {
            production {
              calculateAndSaveGeneric(
                entityId: $entityId
                planID: $planId
                policyCurrency: $policyCurrency
                policyEffectiveDate: $policyEffectiveDate
                policyExpiryDate: $policyExpiryDate
                policyPersonInput: $policyPersonInput
                policyPlanCoverInput: $policyPlanCoverInput
                costAndChargeInput: $costAndChargeInput
                policyBankersBlanketBondInput: $bankerInputs
                policyMarineInput: $marineInput
                policyMarineHullInput: $marineHullInput
                descriptionOfRisk: $descriptionOfRisk
                geoLocation: $geoLocation
                policyPropertyInput: $policyProperty
              ) {
                id
              }
            }
          }
        }
      }
    }
  `;
}

export function getExpatClauseList() {
  return gql`
    query getExpatClauseListForProposal(
      $currentPage: Int!
      $currentPageSize: Int!
      $selectedLineIds: [String]
    ) {
      PlanConfigManagement {
        queries {
          getClauseList(
            selectedLineIds: $selectedLineIds
            pagination: { pageNumber: $currentPage, pageSize: $currentPageSize }
          ) {
            paging {
              pageSize
              pageNumber
              totalCount
            }
            items {
              planConfigManagement_Clause_Id
              planConfigManagement_Clause_createdOn
              planConfigManagement_Clause_modifiedOn
              planConfigManagement_Clause_ExternalCode
              planConfigManagement_Clause_Name
              planConfigManagement_Clause_Description

              planConfigManagement_Clause_LineID {
                id
                views {
                  PlanConfigManagement_all {
                    properties {
                      Name
                      Status
                    }
                  }
                }
              }
              planConfigManagement_Clause_Status
            }
          }
        }
      }
    }
  `;
}

export function issuePolicyExpat() {
  return gql`
    mutation issuePolicyExpat($entityId: String!, $clauseText: String!) {
      production {
        entities {
          proposal {
            production {
              issuePolicyExpat(entityId: $entityId, clauseText: $clauseText) {
                EntityTypeId
                EntityId
                EntityViewId
                PropertyId
                File {
                  fileName
                  id
                }
              }
            }
          }
        }
      }
    }
  `;
}
export function downloadExpatDocument() {
  return gql`
    mutation downloadExpatDocument($entityId: String!) {
      production {
        entities {
          proposal {
            production {
              downloadPolicySampleExpat(entityId: $entityId) {
                EntityTypeId
                EntityId
                EntityViewId
                PropertyId
                File {
                  id
                  fileName
                }
              }
            }
          }
        }
      }
    }
  `;
}
