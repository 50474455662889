import React from 'react';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';
import _ from 'lodash';
import {
  IAddVoucherLOVs,
  ITransactionTableData,
  IVoucherPopupValues,
} from './types';
import {
  KeyOf,
  updateFormField,
  ValueOf,
} from '../../../../../../utils/helper-utils';
import {
  contentFontFamilyBold,
  MAIN_ONE_THEME,
} from '../../../../../../constants';
import Separator from '../../../../../../components/common/Separator';
import CurrencyFormField from '../../../../../../components/form-fields/CurrencyFormField';
import TextInputFormField from '../../../../../../components/form-fields/TextInputFormField';
import SelectFormField from '../../../../../../components/form-fields/SelectFormField';

const useStyles = makeStyles()(() => ({
  container: {
    boxShadow: '0px 0px 10px #DFE3EBC9',
    border: '1px solid #E8E8E8',
    marginBottom: '20px',
    marginTop: '20px',
  },
  root: {
    padding: '5px 20px',
    minHeight: '20px',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '4.0fr 3.0fr 3.0fr 3.0fr 0.5fr',
    gridColumnGap: '18px',
    padding: '2px 5px 2px 15px',
  },
  gridItem: {
    display: 'grid',
    gridTemplateColumns: '6fr 6fr',
    alignItems: 'center',
    gridColumnGap: '18px',
  },
  titlesContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '4.5px',
    paddingBottom: '4.5px',
    lineHeight: '21px',
  },
  titles: {
    paddingTop: '15px',
    paddingBottom: '13px',
    lineHeight: '21px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  firstTitles: {
    display: 'flex',
    justifyContent: 'start',
  },
  span: {
    fontSize: '15px',
    fontFamily: 'SourceSansPro-bold',
  },
  accountCurrencyTitle: {
    backgroundColor: '#FCF5F5',
    color: '#C00020',
  },
  accountCurrencyTitles: {
    color: '#C00020',
  },
  systemCurrencyBaseTitle: {
    backgroundColor: '#F4F8EF',
    color: '#525A49',
  },
  systemCurrencyBaseTitles: {
    color: '#525A49',
  },
  systemCurrencySecondaryTitle: {
    backgroundColor: '#ECF7F5',
    color: '#586E6A',
  },
  systemCurrencySecondaryTitles: {
    color: '#586E6A',
  },
  title: {
    flexGrow: 1,
    flexShrink: 1,
    fontFamily: contentFontFamilyBold,
    fontSize: MAIN_ONE_THEME.typography.regular.reg4.fontSize,
    color: MAIN_ONE_THEME.palette.primary1.main,
    alignSelf: 'center',
    width: '25px',
  },
  addBtn: {
    backgroundColor: 'transparent',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    padding: '0',
    margin: '21px auto 25px',
    display: 'block',
  },
  removeBtn: {
    backgroundColor: 'transparent',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    display: 'block',
    padding: 0,
    margin: 'auto',
    height: '24px',
  },
  evenRow: {
    height: '38px',
    backgroundColor: '#F9F9F9',
  },
  oddRow: {
    height: '38px',
  },
  currencySymbol: {
    color: '#C00020',
  },
  inputContainer: {
    height: '30px',
    '& .MuiInputBase-root': {
      minHeight: '30px !important',
    },
  },
}));

interface IAccountCurrencyProps {
  title?: string;
  lovs: IAddVoucherLOVs;
  values: IVoucherPopupValues;
  setValues: (state: IVoucherPopupValues) => void;
  systemCurrency: string;
  systemSecondaryCurrency: string;
  disabled?: boolean;
}

const AccountCurrency: React.FC<IAccountCurrencyProps> = ({
  title,
  lovs,
  values,
  setValues,
  systemCurrency,
  systemSecondaryCurrency,
}) => {
  const { classes } = useStyles();

  const onFieldUpdate = (
    fieldName: KeyOf<ITransactionTableData>,
    value: ValueOf<ITransactionTableData>,
    rowIndex: number
  ) => {
    const newValues = _.cloneDeep(values);
    newValues.transactions[rowIndex] = updateFormField(
      newValues.transactions[rowIndex],
      fieldName,
      value
    );

    setValues(newValues);
  };

  const renderRow = (row: ITransactionTableData, index: number) => {
    const rowIndex = values.transactions.indexOf(row);
    const accountName = row.accountNameDisabled
      ? row.accountId + ' ' + row.accountName
      : row?.accountName;
    const accountId = row.accountNameDisabled
      ? row?.accountName
      : row.accountId;
    const accountDebit = row?.accountDebit;
    const accountCredit = row?.accountCredit;
    const accountLovs = row.accountNameDisabled
      ? { [accountId]: accountName }
      : lovs.accounts[rowIndex];

    return (
      <div
        className={clsx(
          'container',
          classes.grid,
          rowIndex % 2 === 0 ? classes.evenRow : classes.oddRow
        )}
        key={index}
      >
        <div className={clsx('item', classes.gridItem)}>
          <SelectFormField
            key="accountName"
            name="accountName"
            title={''}
            value={accountId}
            inputOnly
            hideError
            onChange={(v: string) => {
              onFieldUpdate('accountId', v, rowIndex);
            }}
            disabled={row.accountNameDisabled}
            selectOptions={accountLovs}
            classes={{
              input: classes.inputContainer,
            }}
          />
          <TextInputFormField
            key="description"
            name="description"
            title=""
            inputOnly
            hideError
            value={row?.description || ''}
            onChange={(v) =>
              onFieldUpdate('description', v.target.value, rowIndex)
            }
            disabled={row.descriptionDisabled}
            classes={{
              input: classes.inputContainer,
            }}
          />
        </div>
        <div className={clsx('item', classes.gridItem)}>
          <CurrencyFormField
            key="accountDebit"
            name="accountDebit"
            title=""
            inputOnly
            hideError
            value={accountDebit}
            onChange={() => {}}
            disabled
            currencySymbol={
              lovs.currenciesSymbols[row.transactionCurrency] ||
              values.systemCurrencies.primary.Symbol
            }
            classes={{
              currencySymbol: classes.currencySymbol,
              inputContainer: classes.inputContainer,
            }}
          />
          <CurrencyFormField
            key="accountCredit"
            name="accountCredit"
            title=""
            inputOnly
            hideError
            value={accountCredit}
            onChange={() => {}}
            disabled
            currencySymbol={
              lovs.currenciesSymbols[row.transactionCurrency] ||
              values.systemCurrencies.primary.Symbol
            }
            classes={{
              inputContainer: classes.inputContainer,
            }}
          />
        </div>
        <div className={clsx('item', classes.gridItem)}>
          <CurrencyFormField
            key="systemDebit"
            name="systemDebit"
            title=""
            inputOnly
            hideError
            value={row?.systemDebit}
            onChange={() => {}}
            disabled
            currencySymbol={systemCurrency}
            classes={{
              inputContainer: classes.inputContainer,
            }}
          />
          <CurrencyFormField
            key="systemCredit"
            name="systemCredit"
            title=""
            inputOnly
            hideError
            value={row?.systemCredit}
            onChange={() => {}}
            disabled
            currencySymbol={systemCurrency}
            classes={{
              inputContainer: classes.inputContainer,
            }}
          />
        </div>
        <div className={clsx('item', classes.gridItem)}>
          <CurrencyFormField
            key="systemSecondaryDebit"
            name="systemSecondaryDebit"
            title=""
            inputOnly
            hideError
            value={row?.systemSecondaryDebit}
            onChange={() => {}}
            disabled
            currencySymbol={systemSecondaryCurrency}
            classes={{
              inputContainer: classes.inputContainer,
            }}
          />
          <CurrencyFormField
            key="systemSecondaryCredit"
            name="systemSecondaryCredit"
            title=""
            inputOnly
            hideError
            value={row?.systemSecondaryCredit}
            onChange={() => {}}
            disabled
            currencySymbol={systemSecondaryCurrency}
            classes={{
              inputContainer: classes.inputContainer,
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <div className={classes.container}>
      <div className={classes.root}>
        {title && <div className={classes.title}>{title}</div>}
      </div>
      <Separator style={{ height: 1, marginTop: 2 }} />
      <div className={clsx('container', classes.grid)}>
        <div className="item"></div>
        <div className="item">
          <div
            className={clsx(
              classes.titlesContainer,
              classes.accountCurrencyTitle
            )}
          >
            <span className={classes.span}>Account Currency</span>
          </div>
        </div>
        <div className="item">
          <div
            className={clsx(
              classes.titlesContainer,
              classes.systemCurrencyBaseTitle
            )}
          >
            <span className={classes.span}>System Currency (Basic)</span>
          </div>
        </div>
        <div className="item">
          <div
            className={clsx(
              classes.titlesContainer,
              classes.systemCurrencySecondaryTitle
            )}
          >
            <span className={classes.span}>System Currency (Secondary)</span>
          </div>
        </div>
        <div className="item"></div>
      </div>
      <div className={clsx('container', classes.grid)}>
        <div className={clsx('item', classes.gridItem)}>
          <div className={clsx(classes.titles, classes.firstTitles)}>
            <span className={classes.span}>Account*</span>
          </div>
          <div className={clsx(classes.titles, classes.firstTitles)}>
            <span className={classes.span}>Description*</span>
          </div>
        </div>
        <div className={clsx('item', classes.gridItem)}>
          <div className={clsx(classes.titles, classes.accountCurrencyTitles)}>
            <span className={classes.span}>Debit</span>
          </div>
          <div className={clsx(classes.titles, classes.accountCurrencyTitles)}>
            <span className={classes.span}>Credit</span>
          </div>
        </div>
        <div className={clsx('item', classes.gridItem)}>
          <div
            className={clsx(classes.titles, classes.systemCurrencyBaseTitles)}
          >
            <span className={classes.span}>Debit</span>
          </div>
          <div
            className={clsx(classes.titles, classes.systemCurrencyBaseTitles)}
          >
            <span className={classes.span}>Credit</span>
          </div>
        </div>
        <div className={clsx('item', classes.gridItem)}>
          <div
            className={clsx(
              classes.titles,
              classes.systemCurrencySecondaryTitles
            )}
          >
            <span className={classes.span}>Debit</span>
          </div>
          <div
            className={clsx(
              classes.titles,
              classes.systemCurrencySecondaryTitles
            )}
          >
            <span className={classes.span}>Credit</span>
          </div>
        </div>
        <div className="item"></div>
      </div>
      {values.transactions.map((v, index) => renderRow(v, index))}
    </div>
  );
};

export default AccountCurrency;
