import React, { useEffect, useMemo, useState } from 'react';
import { IListingData } from '../../../../../../models/listing';
import { PVPageHeaders } from './content';
import { IEnhanceTableHeaderClickable } from '../../../../../../components/enhanced-table';
import { useLazyQuery } from '@apollo/client';
import { getPayablePaymentsOfBrokersQuery } from './queries';
import { useParams } from 'react-router-dom';
import { mapToPageListingData } from './utils';
import { toast } from 'react-toastify';
import ToastErrorMessage from '../../../../../../components/ToastErrorMessage';
import { DEFAULT_ERROR_TEXT } from '../../../../../../constants';
import { IVoucherPopup } from '../shared/types';
import PaymentVoucherPopupShell from './add-payment-vouchers-popup/PaymentVoucherPopupShell';
import { lookupListAsRecordObject } from '../../../../../../utils/graph-utils';
import { IEnhancedMenuItem } from '../../../../../../components/form-fields/table';
import { IAbstractRecord } from '../../../../../../models';
import ListingTable from '../../../../../../components/form-fields/table/ListingTable';

const PaymentVouchersTable: React.FC<{
  paymentMethods: Record<string, string>;
}> = ({ paymentMethods }) => {
  const businessPartnerId = useParams().id;
  const [booted, setBooted] = useState<boolean>(false);
  const [voucherPopup, setVoucherPopup] = useState<IVoucherPopup>({
    open: false,
    isEdit: false,
    currentPaymentId: null,
  });
  const [tableData, setTableData] = useState<IListingData>({
    pagedItems: {},
    pageNumber: 1,
    pageSize: 5,
    totalCount: 0,
  });

  const tableActions: IEnhancedMenuItem[] = useMemo(() => {
    return [
      {
        name: 'addpaymentvoucher',
        title: '+ Add Payment Voucher',
        onClick: () => {
          setVoucherPopup({
            open: true,
            isEdit: false,
            currentPaymentId: null,
          });
        },
      },
    ];
  }, []);

  const [getPayablePaymentsOfBrokers] = useLazyQuery(
    getPayablePaymentsOfBrokersQuery()
  );

  const initialize = async () => {
    try {
      setBooted(false);

      const res = await getPayablePaymentsOfBrokers({
        variables: {
          businessPartnerID: businessPartnerId,
        },
      });

      const items = res.data.Accounting.queries.GetPayablesPaymentsOfBroker;

      const currencies = lookupListAsRecordObject(
        res.data.Core.lookups.currencies,
        false,
        'Id',
        'Symbol'
      );
      const data = mapToPageListingData(items, paymentMethods, currencies);

      setTableData({
        pagedItems: data,
        pageNumber: 1,
        pageSize: 5,
        totalCount: items?.length,
      });
      setBooted(true);
    } catch (error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethods]);

  (PVPageHeaders.paymentDate as IEnhanceTableHeaderClickable).callback = (
    payload: IAbstractRecord
  ) => {
    setVoucherPopup({
      open: true,
      isEdit: true,
      currentPaymentId: payload.columns.paymentId,
    });
  };

  return (
    <>
      <ListingTable
        name={'Payment Vouchers'}
        inlineTitle="Payment Vouchers"
        headers={PVPageHeaders}
        data={tableData}
        loader={booted}
        actions={tableActions}
        disableSelection
        inline
      />
      {voucherPopup.open && (
        <PaymentVoucherPopupShell
          open={voucherPopup.open}
          onSuccess={initialize}
          onClose={() =>
            setVoucherPopup({
              ...voucherPopup,
              open: false,
            })
          }
          isEdit={voucherPopup.isEdit}
          currentPaymentId={voucherPopup.currentPaymentId}
        />
      )}
    </>
  );
};

export default PaymentVouchersTable;
