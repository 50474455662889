import { IReinsurersResponse, IReinsurersTableData } from '.';
import { IAbstractRecord } from '../../../../models';
import { IListingData } from '../../../../models/listing';
import {
  enumListAsRecordObject,
  lookupListAsRecordObject,
} from '../../../../utils/graph-utils';

export function toLookupsData(data: IAbstractRecord) {
  const lovs: Record<string, Record<string, string>> = {
    reinsurerType: {},
    isLocal: {},
    status: {},
  };

  const filteredBusinessPartnerCategories =
    data?.SalesforceManagement?.lookups?.businessPartnerCategories.filter(
      (item: Record<any, any>) => item.BusinessPartnerType === 'Reinsurer'
    );

  lovs.reinsurerType = lookupListAsRecordObject(
    filteredBusinessPartnerCategories,
    false,
    'Code',
    'Title'
  );

  lovs.isLocal = {
    true: 'Yes',
    false: 'No',
  };

  lovs.status = enumListAsRecordObject(
    data?.SalesforceManagement_BusinessPartnerStatuses?.enumValues
  );

  return lovs;
}

export function mapToListingData(
  data: {
    items: IReinsurersResponse[];
    paging: { pageSize: number; pageNumber: number; totalCount: number };
  },
  lovs: Record<string, Record<string, string>>
): IListingData {
  const list: Record<string, IReinsurersTableData> = {};

  data.items.forEach((item) => {
    list[item.salesforceManagement_BusinessPartner_Id] = {
      SalesforceManagement_BusinessPartner_Id:
        item.salesforceManagement_BusinessPartner_Id,
      SalesforceManagement_BusinessPartner_Code:
        item.salesforceManagement_BusinessPartner_Code,
      SalesforceManagement_BusinessPartner_FullName:
        item.salesforceManagement_BusinessPartner_FullName,
      SalesforceManagement_BusinessPartner_Category:
        item.salesforceManagement_BusinessPartner_Category?.Title,
      SalesforceManagement_BusinessPartner_RegisterNumber:
        item.salesforceManagement_BusinessPartner_RegisterNumber,
      SalesforceManagement_BusinessPartner_Account:
        item.salesforceManagement_BusinessPartner_Account,
      PersonDetails_Comments: item.personDetails_Comments,
      SalesforceManagement_BusinessPartner_Status:
        lovs.status[item.salesforceManagement_BusinessPartner_Status],
    };
  });

  return {
    pagedItems: list,
    pageSize: data.paging.pageSize,
    pageNumber: data.paging.pageNumber,
    totalCount: data.paging.totalCount,
  };
}
