/* eslint-disable @typescript-eslint/no-explicit-any */
import { IListingData } from '../../../../models/listing';

export function mapToMarinePolicyListData(data: any): IListingData {
  const list: Record<string, any> = {};
  const items = data.Production.queries.getPolicyList.items;

  items.forEach((item: any) => {
    const policyId = item.production_Policy_Id;

    // Certificate Number
    const certificateNumber = item.production_Policy_PolicyNumber || '';

    // Subline
    const sublineExternalCode =
      item.production_Policy_SublineID?.views?.PlanConfigManagement_all
        ?.properties?.ExternalCode || '';
    const sublineName =
      item.production_Policy_SublineID?.views?.PlanConfigManagement_all
        ?.properties?.Name || '';
    const subline = `${sublineExternalCode} - ${sublineName}`;
    // Plan
    const planExternalCode =
      item.production_Policy_PlanID?.views?.PlanConfigManagement_all?.properties
        ?.ExternalCode || '';
    const planName =
      item.production_Policy_PlanID?.views?.PlanConfigManagement_all?.properties
        ?.Name || '';
    const plan = `${planExternalCode} - ${planName}`;
    const matter = item.policyMarine_Matter?.Title || '';
    const vesselName = item.policyMarine_VesselName || '';
    const vesselAge = item.policyMarine_VesselAge || '';

    const departureFrom = item.policyMarine_DepartureFrom || '';
    const destinationTo = item.policyMarine_DestinationTo || '';

    // Transportation Via
    const transportationVia = item.policyMarine_TransportationVia || '';

    // LC Details
    const lcNumber = item.policyMarine_LCNumber || '';
    const lcIssueDate = item.policyMarine_LCIssueDate || '';

    // Loading
    const loading = item.policyMarine_Loading || '';

    // Financials
    const sumPerShipment = item.policyMarine_SumPerShipment || 0;
    const sumInsured = item.pricingOption_SumInsured || 0;
    const netPremium = item.pricingOption_NetPremium || 0;

    const policyCostAmount = item.pricingOption_PolicyCostAmount || 0;
    const chargesAmount = item.pricingOption_ChargesAmount || 0;
    const tpaFeesTotalAmount = item.pricingOption_TPAFeesTotalAmount || 0;

    const costAndCharges =
      policyCostAmount + chargesAmount + tpaFeesTotalAmount;

    const fixedStampAmount = item.pricingOption_FixedStampAmount || 0;
    const proportionalStampAmount =
      item.pricingOption_ProportionalStampAmount || 0;
    const municipalityTaxAmount = item.pricingOption_MunicipalityTaxAmount || 0;

    const taxes =
      fixedStampAmount + proportionalStampAmount + municipalityTaxAmount;

    const totalPremium = item.pricingOption_TotalPremium || 0;

    // Effective Date and Status
    const effectiveDate = item.production_Policy_PolicyEffectiveDate || '';
    const status = item.production_Policy_Status || '';

    // Currency Symbol
    const currencySymbol =
      item.pricingOption_PricingOptionCurrency?.Symbol || '';

    list[policyId] = {
      certificateNumber,
      subline,
      plan,
      matter,
      vesselName,
      vesselAge,
      departureFrom,
      destinationTo,
      transportationVia,
      lcNumber,
      lcIssueDate,
      loading,
      sumPerShipment,
      sumInsured,
      netPremium,
      costAndCharges,
      taxes,
      totalPremium,
      effectiveDate,
      status,
      currencySymbol,
    };
  });

  return {
    pagedItems: list,
    pageSize: data.Production.queries.getPolicyList.paging.pageSize,
    pageNumber: data.Production.queries.getPolicyList.paging.pageNumber,
    totalCount: data.Production.queries.getPolicyList.paging.totalCount,
  };
}
