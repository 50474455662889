import { ITravelPlanRatesDrawerInfo } from '.';
import {
  enumListAsRecordObject,
  lookupListAsRecordObject,
} from '../../utils/graph-utils';

export function LookupToList(data: any) {
  const travelPlanRatesEnums: Record<string, Record<string, string>> = {};

  travelPlanRatesEnums['currencies'] = lookupListAsRecordObject(
    data?.Core?.lookups?.currencies
  );

  travelPlanRatesEnums['travelDestinations'] = lookupListAsRecordObject(
    data?.Core?.lookups?.travelDestinations
  );

  travelPlanRatesEnums['gender'] = enumListAsRecordObject(
    data?.Insurance_Genders?.enumValues as {
      __typename: string;
      name: string;
    }[]
  );

  return travelPlanRatesEnums;
}

export function graphqlToTravelPlanRatesInfo(
  data: any
): ITravelPlanRatesDrawerInfo {
  const travelPlanRates =
    data?.PlanConfigManagement?.entities?.planSpecificTravel?.views
      ?.PlanConfigManagement_all?.properties;

  const PolicyCoverData =
    travelPlanRates?.PolicyCoverID?.views?.PlanConfigManagement_all?.properties;

  return {
    planCurrency: '',
    ageFrom: travelPlanRates?.AgeFrom,
    ageTo: travelPlanRates?.AgeTo,
    gender: travelPlanRates?.Gender,
    travelDurationFrom: travelPlanRates?.TravelDurationFrom,
    travelDurationTo: travelPlanRates?.TravelDurationTo,
    travelDestination: travelPlanRates?.TravelDestination?.Id,
    nbOfAdherent: travelPlanRates?.NbOfAdherent,
    premium: travelPlanRates?.Premium,
    policyCover: travelPlanRates?.PolicyCoverID?.id,
    policyCoverTitle: `${PolicyCoverData?.ExternalCode} - ${PolicyCoverData?.Name}`,
  };
}

export function extractPolicyCovers(data: any) {
  const policyCoverList: Record<string, string> = {};

  data.PlanConfigManagement.queries.allPolicyCoverList.forEach(
    (element: {
      planConfigManagement_PolicyCover_Id: string;
      planConfigManagement_PolicyCover_Name: string;
      planConfigManagement_PolicyCover_ExternalCode: string;
    }) => {
      policyCoverList[element.planConfigManagement_PolicyCover_Id] =
        `${element.planConfigManagement_PolicyCover_ExternalCode} - ${element.planConfigManagement_PolicyCover_Name}`;
    }
  );

  return policyCoverList;
}
