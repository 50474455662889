import { gql } from '@apollo/client';

export const getTreatyLayerAndCategoryQuery = gql`
  query getTreatyLayerAndCategory(
    $TreatyID: String!
    $SublineCategory: String
  ) {
    Reinsurance {
      queries {
        GetTreatyLayerAndCategory(
          TreatyID: $TreatyID
          SublineCategory: $SublineCategory
        ) {
          # Layer
          reinsurance_TreatyLayer_Id
          reinsurance_TreatyLayer_TreatyLayerOrder
          reinsurance_TreatyLayer_TreatySubType {
            Code
            Title
          }
          reinsurance_TreatyLayer_QuotaSharePercentage
          reinsurance_TreatyLayer_PaymentDate
          reinsurance_TreatyLayer_PaymentAmount
          reinsurance_TreatyLayer_TotalTreatyPremium
          treatyLayerCategory_TotalExpectedPremium
          treatyLayerCategory_PremiumRate

          # category
          treatyLayerCategory_Id
          treatyLayerCategory_Category
          treatyLayerCategory_MinimumLayerLimit
          treatyLayerCategory_MaximumLayerLimit
          treatyLayerCategory_CommissionRate
          treatyLayerCategory_RetentionLimit
          treatyLayerCategory_PriorityLimit
          treatyLayerCategory_LiabilityLimit
          treatyLayerCategory_AggregateLimit
        }
      }
    }
  }
`;

export const saveTreatyMutation = gql`
  mutation saveTreaty(
    $TreatyName: String!
    $TreatyType: String!
    $Currency: String!
    $UnderwritingLimit: Decimal!
    $ClaimLimit: Decimal!
    $SubstandardLimit: Decimal!
    $EffecitiveDate: Date!
    $ExpiryDate: Date!
    $CurrentTreatyID: String!
    $LayerDetails: [Reinsurance_LayerDetails!]!
  ) {
    reinsurance {
      actions {
        saveTreaty(
          TreatyName: $TreatyName
          TreatyType: $TreatyType
          Currency: $Currency
          UnderwritingLimit: $UnderwritingLimit
          ClaimLimit: $ClaimLimit
          SubstandardLimit: $SubstandardLimit
          EffecitiveDate: $EffecitiveDate
          ExpiryDate: $ExpiryDate
          CurrentTreatyID: $CurrentTreatyID
          LayerDetails: $LayerDetails
        )
      }
    }
  }
`;
